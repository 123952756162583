import type { RawCellContent } from "hyperformula";
import { AutoRendered, autoRenderedTextRow } from "src/classes/AutoRendered";
import { Form1040 } from "src/interfaces/TaxFormData/Form1040";
import {
  createForm1040Rendered,
  Form1040RowHeadings,
} from "src/classes/RenderedDocuments/Form1040/Form1040Rendered";

export interface Form1040WithTabNames {
  form: Form1040;
  k1DistributionTotal: number | undefined;
  scheduleETabName: string | null;
  scheduleCTabName: string | null;
}

export class Form1040Grouped extends AutoRendered<Form1040WithTabNames[]> {
  constructor(
    data: Form1040WithTabNames[],
    public enableLoanCalculator: boolean = false,
  ) {
    super(data);
  }

  private getColumnLetter(offset: number): string {
    return String.fromCharCode("A".charCodeAt(0) + offset);
  }

  asColumns(): RawCellContent[][] {
    const startingRow = 1;
    const data = this.underlying.sort((a, b) => parseInt(a.form.year) - parseInt(b.form.year));
    const header = autoRenderedTextRow(["Year", ...data.map((r) => r.form.year)]);
    const body: RawCellContent[][] = [];

    data.forEach((formWithTabNames, i) => {
      const startingColumn = this.getColumnLetter(i + 1);
      const { form, k1DistributionTotal, scheduleETabName, scheduleCTabName } = formWithTabNames;
      const singleRendered = createForm1040Rendered(
        form,
        startingColumn,
        startingRow,
        k1DistributionTotal,
        scheduleETabName,
        scheduleCTabName,
        this.enableLoanCalculator,
      );
      const formColumns = singleRendered.asColumns();
      formColumns.forEach((row, rowIndex) => {
        if (!body[rowIndex]) {
          body[rowIndex] = [row[0]];
        }
        body[rowIndex].push(row[1]);
      });
    });

    return [header, ...body];
  }

  get highlightedRowLabels(): string[] {
    return [
      "Year",
      Form1040RowHeadings.Subtotal,
      Form1040RowHeadings.CashFlowAvailableForDebtService,
      Form1040RowHeadings.TotalDebtService,
      Form1040RowHeadings.PersonalDSCR,
      Form1040RowHeadings.ExcessCashFlow,
    ];
  }

  get percentageRowLabels(): string[] {
    return [];
  }

  indexOfHeader(header: keyof typeof Form1040RowHeadings): number {
    const heading = Form1040RowHeadings[header];
    return this.asColumns().findIndex((row) => row[0] === heading) + 1;
  }
}
