import { all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import {
  CreateReportingSequencePayload,
  UpdateReportingSequencePayload,
} from "src/contracts/loan/document/reporting-sequence";
import { LoanDetails } from "src/models/Loan";
import {
  convertAnnualReviewsToLoanCardData,
  loanService,
} from "src/services/loan-services/LoanService";
import { toastService } from "src/services/ToastService";
import { PipelineLoans } from "../actions/actions.constants";
import {
  addUpdateNoteActionFailed,
  annualReviewDetailsGetAction,
  annualReviewDetailsGetActionFailed,
  annualReviewDetailsSetAction,
  createDocumentRequestActionComplete,
  createDocumentRequestActionFailed,
  createReportingSequenceActionComplete,
  createReportingSequenceActionFailed,
  editLoanDetailsFailed,
  loanDetailsGetAction,
  loanDetailsSetAction,
  setPipelineLoans,
  setPipelineReviews,
  setPipelineSearchParams,
  updateDocumentStatusActionComplete,
  updateDocumentStatusActionFailed,
} from "src/redux/actions/pipelineLoans.action";
import {
  allDocumentUploadIdsSelector,
  pipelineLoansSelector,
  pipelineSearchParamsSelector,
} from "src/redux/selectors/loan.selector";
import { DocumentUploadStatus, LoanStatus, ReminderStatus } from "@prisma/client";
import { fetchAnswerCompletedAction } from "src/redux/actions/intake.actions";
import { EntityHydrated } from "src/models/entity";
import { AnnualReviewStatusOptionEnum } from "src/Enums/AnnualReviewStatusOptionEnum";
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGINATION_PARAMS,
  isEqual,
  Pagination,
  PaginationDebounce,
  PaginationParams,
} from "src/utils/pagination";
import _ from "lodash";
import { SetSelectedUploadIds } from "../actions/ocr.actions";
import { FetchFromDb } from "../actions/spread.action";
import { ReviewEditReportingSequence } from "src/pages/api/annual-review/index.contracts";
import { DocumentQuality } from "src/Enums/DocumentQualityEnum";
import { documentReminderService } from "src/services/document-reminder/DocumentReminderService";

const pagination = new Pagination();
const paginationDebounce = new PaginationDebounce();

const loanPagination = new Pagination();
const loanPaginationDebounce = new PaginationDebounce();

function* fetchPipelineLoans(action: { type: string; payload: { fetchNextPage?: boolean } }): any {
  if (!loanPaginationDebounce.canMakeRequest()) {
    return;
  }
  const { fetchNextPage } = action.payload;
  const { limit, offset: previousOffset } = pagination.getPage();
  const offset = !fetchNextPage ? 0 : previousOffset;
  const searchParams = yield select(pipelineSearchParamsSelector);
  try {
    const response = yield call(loanService.list, {
      limit,
      offset,
      ...searchParams,
    });
    loanPaginationDebounce.setDebounceTimer();
    loanPagination.setOffset(offset + DEFAULT_LIMIT);
    yield put(setPipelineLoans(response.loanDetails));

    loanPagination.setOffset(offset + DEFAULT_LIMIT);
  } catch (e: any) {
    console.log(e);
    toastService.showError("Couldn't fetch loans. Something went wrong!");
  }
}

function* updatePipelineSearchParams(action: { type: string; payload: PaginationParams }): any {
  if (!paginationDebounce.canMakeRequest()) {
    return;
  }

  const newSearchParams = action.payload;
  const oldSearchParams = yield select(pipelineSearchParamsSelector);
  if (!oldSearchParams || !isEqual(newSearchParams, oldSearchParams)) {
    // If the search params have changed, reset the offset to 0
    pagination.setOffset(0);

    yield put(
      setPipelineSearchParams({
        ...DEFAULT_PAGINATION_PARAMS,
        ...newSearchParams,
      }),
    );
  }
}

function* fetchPipelineAnnualReviews(action: {
  type: string;
  payload: { fetchNextPage?: boolean };
}): any {
  if (!paginationDebounce.canMakeRequest()) {
    return;
  }
  const { fetchNextPage } = action.payload;
  const { limit, offset: previousOffset } = pagination.getPage();
  const offset = !fetchNextPage ? 0 : previousOffset;
  const searchParams = yield select(pipelineSearchParamsSelector);
  try {
    const [loanResponse, reviewResponse] = yield all([
      call(loanService.list, {
        limit,
        offset,
        ...searchParams,
      }),
      call(loanService.listAnnualReviews, {
        limit,
        offset,
        ...searchParams,
      }),
    ]);
    const existingData = yield select(pipelineLoansSelector);
    const results = _.uniqBy(
      [
        ...existingData,
        ...loanResponse.loanDetails,
        ...convertAnnualReviewsToLoanCardData(reviewResponse),
      ],
      (e: LoanDetails) => `${e["id"]}${e["investment_type"]}`,
    );
    paginationDebounce.setDebounceTimer();
    pagination.setOffset(offset + DEFAULT_LIMIT);
    yield put(setPipelineReviews(results));
  } catch (e: any) {
    console.log(e);
    toastService.showError("Couldn't fetch pipeline data. Something went wrong!");
  }
}

function* fetchLoanDetailsById(action: { type: string; id: number }): any {
  try {
    const response = yield call(loanService.getLoanById, action.id);
    //these ids are added temporarily just to make sure formik works fine.
    const ids = [
      "N5hiohmBZNS8",
      "XLnbZDv0ABhj",
      "zaDzQrAJ20Zv",
      "r812fbFnRg3O",
      "K5FccVBuiRZh",
      "LVEl7sVphytB",
      " jHNa637uTFCY",
    ];
    const updatedResponse = {
      ...response.loan,
      documentRequests: response.loan?.documentRequests?.map((e: any, i: number) => {
        return { ...e, id: ids[i] };
      }),
    } as LoanDetails;
    yield put(loanDetailsSetAction(updatedResponse));
    yield put(fetchAnswerCompletedAction(response.answers));
    const isReview = updatedResponse.investment_type === "annual_review";
    yield put(
      FetchFromDb({
        annualReviewId: isReview ? parseInt(updatedResponse.id.toString()) : null,
        loanId: isReview ? null : parseInt(updatedResponse.id.toString()),
      }),
    );
  } catch (e: any) {
    console.log(e);
    toastService.showError("Couldn't fetch detail for this loan. Something went wrong!");
  }
}

function* modifyCustomerUsers(action: {
  type: string;
  payload: { loanId: number; emails: string[]; returnResponse: boolean };
}): any {
  if (!action.payload.returnResponse) {
    yield call(loanService.modifyCustomerUser, action.payload);
  } else {
    try {
      const response = yield call(loanService.modifyCustomerUser, action.payload);
      //these ids are added temporarily just to make sure formik works fine.
      const ids = [
        "N5hiohmBZNS8",
        "XLnbZDv0ABhj",
        "zaDzQrAJ20Zv",
        "r812fbFnRg3O",
        "K5FccVBuiRZh",
        "LVEl7sVphytB",
        " jHNa637uTFCY",
      ];
      const updatedResponse = {
        ...response.loan,
        documentRequests: response.loan?.documentRequests?.map((e: any, i: number) => {
          return { ...e, id: ids[i] };
        }),
      };
      yield put(loanDetailsSetAction(updatedResponse));
      const borrowerEntity = response.loan.entities.find(
        (entity: EntityHydrated) => entity.role === "BORROWER",
      );
      yield put(
        fetchAnswerCompletedAction({
          answers: response.answers,
          initialEntityName: response.loan.accountName,
          loanId: response.loan.id,
          borrowerEntityId: borrowerEntity.id,
        }),
      );
    } catch (e: any) {
      console.log(e);
      toastService.showError("Couldn't fetch detail for this loan. Something went wrong!");
    }
  }
}

function* fetchAnnualReviewDetailsById(action: { type: string; id: number }): any {
  try {
    const response: LoanDetails | undefined = yield call(
      loanService.getAnnualReviewById,
      action.id,
    );
    if (!response) {
      console.error(
        `fetchAnnualReviewDetailsById: loanService.getAnnualReviewById(${action.id}) returned undefined`,
        { action },
      );
    }
    //these ids are added temporarily just to make sure formik works fine.
    const ids = [
      "N5hiohmBZNS8",
      "XLnbZDv0ABhj",
      "zaDzQrAJ20Zv",
      "r812fbFnRg3O",
      "K5FccVBuiRZh",
      "LVEl7sVphytB",
      " jHNa637uTFCY",
    ];
    //@ts-ignore
    const updatedResponse: LoanDetails = {
      ...response,
      documentRequests: response!.documentRequests.map((e, i) => {
        return { ...e, id: ids[i] };
      }),
    };
    yield put(annualReviewDetailsSetAction(updatedResponse));
  } catch (e: any) {
    yield put(annualReviewDetailsGetActionFailed(e.response.message));
    toastService.showError(e.response.message);
  }
}

function* updateReminder(action: {
  type: string;
  payload: { id: number; data: { notes?: string; status?: ReminderStatus } };
}): any {
  try {
    const response: LoanDetails | undefined = yield call(
      loanService.updateReminder,
      action.payload.id,
      action.payload.data,
    );
    if (!response) {
      console.error(
        `UpdateReminder: loanService.UpdateReminder(${action.payload.id}) returned undefined`,
        {
          action,
        },
      );
      toastService.showError("Couldn't update this reminder. Something went wrong!");
      return;
    }
    //these ids are added temporarily just to make sure formik works fine.
    const ids = [
      "N5hiohmBZNS8",
      "XLnbZDv0ABhj",
      "zaDzQrAJ20Zv",
      "r812fbFnRg3O",
      "K5FccVBuiRZh",
      "LVEl7sVphytB",
      " jHNa637uTFCY",
    ];
    const updatedResponse: LoanDetails = {
      ...response,
      documentRequests: response.documentRequests.map((e, i) => {
        return { ...e, id: ids[i] };
      }),
    };
    yield put(annualReviewDetailsSetAction(updatedResponse));
    toastService.showSuccess(`Reminder updated successfully`);
  } catch (e: any) {
    console.log(e);
    toastService.showError("Couldn't update this reminder. Something went wrong!");
  }
}

function* createDocumentsRequest(action: {
  type: string;
  payload: {
    loanId: number;
    entityId: number;
    documentsData: {
      documentTypeId: number | null;
      documentTitle: string | null;
      documentYear: string | null;
      documentQuality?: DocumentQuality;
      documentQuarter?: string;
      documentMonth?: string;
      isReview: boolean;
      documentPeriodJson?: string;
    }[];
  };
}): any {
  let hoistedIsReview = false;
  for (const documentData of action.payload.documentsData) {
    try {
      hoistedIsReview = hoistedIsReview || documentData.isReview;
      const response = yield call(
        loanService.createDocumentsRequest,
        action.payload.loanId,
        action.payload.entityId,
        documentData,
      );
      if (response.isSuccessful) {
        yield put(createDocumentRequestActionComplete());
      } else {
        yield put(createDocumentRequestActionFailed());
      }
    } catch (error) {
      yield put(createDocumentRequestActionFailed());
      toastService.showError("Could not create document request.");
    }
  }
  if (hoistedIsReview) {
    yield put(annualReviewDetailsGetAction(action.payload.loanId.toString()));
  } else {
    yield put(loanDetailsGetAction(action.payload.loanId.toString()));
  }
}

function* editReportingSequence(action: {
  type: string;
  reviewId: number;
  payload: ReviewEditReportingSequence;
}): any {
  try {
    const response = yield call(loanService.editReportingSequence, action.reviewId, action.payload);
    //these ids are added temporarily just to make sure formik works fine.
    const ids = [
      "N5hiohmBZNS8",
      "XLnbZDv0ABhj",
      "zaDzQrAJ20Zv",
      "r812fbFnRg3O",
      "K5FccVBuiRZh",
      "LVEl7sVphytB",
      " jHNa637uTFCY",
    ];
    const updatedResponse: LoanDetails = {
      ...response,
      documentRequests: response?.documentRequests?.map((e: any, i: number) => {
        return { ...e, id: ids[i] };
      }),
    };
    yield put(annualReviewDetailsSetAction(updatedResponse));
    toastService.showSuccess("Reporting sequence saved successfully!");
  } catch (e: any) {
    console.log(e);
    yield put(createReportingSequenceActionFailed());
    toastService.showError("Couldn't save reporting sequence. Something went wrong!");
  }
}

function* deleteReportingSequence(action: { type: string; reviewId: number }): any {
  try {
    const response = yield call(loanService.deleteReportingSequence, action.reviewId);
    //these ids are added temporarily just to make sure formik works fine.
    const ids = [
      "N5hiohmBZNS8",
      "XLnbZDv0ABhj",
      "zaDzQrAJ20Zv",
      "r812fbFnRg3O",
      "K5FccVBuiRZh",
      "LVEl7sVphytB",
      " jHNa637uTFCY",
    ];
    const updatedResponse: LoanDetails = {
      ...response,
      documentRequests: response?.documentRequests?.map((e: any, i: number) => {
        return { ...e, id: ids[i] };
      }),
    };
    yield put(annualReviewDetailsSetAction(updatedResponse));
    toastService.showSuccess("Reporting sequence removed successfully!");
  } catch (e: any) {
    console.log(e);
    yield put(createReportingSequenceActionFailed());
    toastService.showError("Couldn't remove reporting sequence. Something went wrong!");
  }
}

function* updateReportingSequence(action: {
  type: string;
  payload: UpdateReportingSequencePayload & { loanId: number };
}): any {
  try {
    const response = yield call(loanService.updateReportingSequence, action.payload.loanId, {
      lenderEmployeeId: action.payload.lenderEmployeeId,
      cadences: action.payload.cadences,
      messaging_sequence: action.payload.messaging_sequence,
      loanNumbers: action.payload.loanNumbers,
    });

    //these ids are added temporarily just to make sure formik works fine.
    const ids = [
      "N5hiohmBZNS8",
      "XLnbZDv0ABhj",
      "zaDzQrAJ20Zv",
      "r812fbFnRg3O",
      "K5FccVBuiRZh",
      "LVEl7sVphytB",
      " jHNa637uTFCY",
    ];
    const updatedResponse: LoanDetails = {
      ...response,
      documentRequests: response?.documentRequests?.map((e: any, i: number) => {
        return { ...e, id: ids[i] };
      }),
    };
    yield put(annualReviewDetailsSetAction(updatedResponse));
    toastService.showSuccess("Reporting sequence for this loan is saved successfully!");
  } catch (e: any) {
    console.log(e);
    yield put(createReportingSequenceActionFailed());
    toastService.showError("Couldn't save reporting sequence for this loan. Something went wrong!");
  }
}

function* createReportingSequence(action: {
  type: string;
  payload: CreateReportingSequencePayload & { loanId: number };
}): any {
  try {
    const response: { loan: LoanDetails; annualReviewId: number } = yield call(
      loanService.createReportingSequence,
      action.payload.loanId,
      {
        lenderEmployeeId: action.payload.lenderEmployeeId,
        review_end_date: action.payload.review_end_date,
        final_due_date: action.payload.final_due_date,
        cadences: action.payload.cadences,
        messaging_sequence: action.payload.messaging_sequence,
      },
    );

    const ids = [
      "N5hiohmBZNS8",
      "XLnbZDv0ABhj",
      "zaDzQrAJ20Zv",
      "r812fbFnRg3O",
      "K5FccVBuiRZh",
      "LVEl7sVphytB",
      " jHNa637uTFCY",
    ];

    const updatedLoan = {
      ...response.loan,
      documentRequests: response.loan?.documentRequests?.map((e: any, i: number) => {
        return { ...e, id: ids[i] };
      }),
    };

    yield put(loanDetailsSetAction(updatedLoan));
    yield put(createReportingSequenceActionComplete(response.annualReviewId));
    toastService.showSuccess("Reporting sequence for this loan is saved successfully!");
  } catch (e: any) {
    console.log(e);
    yield put(createReportingSequenceActionFailed());
    toastService.showError("Couldn't save reporting sequence for this loan. Something went wrong!");
  }
}

function* editLoanDetails(action: { type: string; loanId: number; payload: any }): any {
  try {
    const response = yield call(loanService.updateLoan, action.loanId, action.payload);

    const ids = [
      "N5hiohmBZNS8",
      "XLnbZDv0ABhj",
      "zaDzQrAJ20Zv",
      "r812fbFnRg3O",
      "K5FccVBuiRZh",
      "LVEl7sVphytB",
      " jHNa637uTFCY",
    ];

    const updatedResponse = {
      ...response,
      documentRequests: response?.documentRequests?.map((e: any, i: number) => {
        return { ...e, id: ids[i] };
      }),
    };

    yield put(loanDetailsSetAction(updatedResponse));
    toastService.showSuccess("Loan details are updated");
  } catch (e: any) {
    console.log(e);
    yield put(editLoanDetailsFailed());
    toastService.showError(`Edit loan details failed with error: ${e.message}`);
  }
}

function* updateDocumentStatus(action: {
  type: string;
  payload: {
    loanId: number;
    documentId: number;
    status: DocumentUploadStatus;
    isReview?: boolean;
  };
}): any {
  try {
    const response = yield call(loanService.updateDocumentStatus, action.payload);

    const ids = [
      "N5hiohmBZNS8",
      "XLnbZDv0ABhj",
      "zaDzQrAJ20Zv",
      "r812fbFnRg3O",
      "K5FccVBuiRZh",
      "LVEl7sVphytB",
      " jHNa637uTFCY",
    ];

    const updatedResponse = {
      ...response,
      documentRequests: response?.documentRequests?.map((e: any, i: number) => {
        return { ...e, id: ids[i] };
      }),
    };

    yield put(updateDocumentStatusActionComplete(updatedResponse));
    toastService.showSuccess(`Document status is changed`);
  } catch (e: any) {
    console.log(e);
    yield put(updateDocumentStatusActionFailed());
    toastService.showError(e?.errors);
  }
}

function* addUpdateNoteSaga(action: {
  type: string;
  payload: {
    documentRequestId?: string;
    reminderId?: string;
    reviewId?: string;
    loanId?: string;
    notes: string;
  };
}): any {
  try {
    yield call(documentReminderService.addUpdateNote, {
      documentRequestId: action.payload.documentRequestId,
      reminderId: action.payload.reminderId,
      reviewId: action.payload.reviewId,
      loanId: action.payload.loanId,
      notes: action.payload.notes,
    });
    toastService.showSuccess(`Note has been updated`);
  } catch (e: any) {
    console.log(e);
    yield put(addUpdateNoteActionFailed());
    toastService.showError(e?.errors);
  }
}

function* updateMetadataSaga(action: {
  type: string;
  payload: {
    docOrReminderId: string;
    loanOrReviewId: number;
    isReminder: boolean;
    isReview: boolean;
    metadata: Record<string, any>;
  };
}): any {
  try {
    const response = yield call(loanService.updateMetadata, {
      docOrReminderId: action.payload.docOrReminderId,
      isReminder: action.payload.isReminder,
      metadata: action.payload.metadata,
      ...(action.payload.isReview && { annualReviewId: action.payload.loanOrReviewId }),
      ...(!action.payload.isReview && { loanId: action.payload.loanOrReviewId }),
    });

    toastService.showSuccess(`Metadata has been updated`);
  } catch (e: any) {
    console.log(e);
    // TODO: new failed action
    yield put(addUpdateNoteActionFailed());
    toastService.showError(e?.errors);
  }
}

function* updateLoanStatusSaga(action: {
  type: string;
  payload: { loanId: number; status: LoanStatus };
}): any {
  try {
    const response = yield call(loanService.updateLoanStatus, action.payload);
    yield put(updateDocumentStatusActionComplete(response));

    yield put(setDetails);
    toastService.showSuccess("Loan status changed successfully");
  } catch (e: any) {
    yield put(updateDocumentStatusActionFailed());
    toastService.showError(e?.errors[0]);
  }
}

function* updateAnnualReviewStatusSaga(action: {
  type: string;
  payload: { annualReviewId: number; status: AnnualReviewStatusOptionEnum };
}): Generator {
  try {
    const response = yield call(loanService.updateAnnualReviewStatus, action.payload);
    const loanDetails = response as LoanDetails;
    if (!loanDetails) {
      console.error(
        `updateAnnualReviewStatusSaga: loanService.getAnnualReviewById(${action.payload}) returned undefined`,
        { action },
      );
      toastService.showError("Couldn't fetch detail for this review. Something went wrong!");
      return;
    }
    //these ids are added temporarily just to make sure formik works fine.
    const ids = [
      "N5hiohmBZNS8",
      "XLnbZDv0ABhj",
      "zaDzQrAJ20Zv",
      "r812fbFnRg3O",
      "K5FccVBuiRZh",
      "LVEl7sVphytB",
      " jHNa637uTFCY",
    ];
    const updatedResponse: LoanDetails = {
      ...loanDetails,
      documentRequests: loanDetails?.documentRequests?.map((e, i: number) => {
        return { ...e, id: ids[i] };
      }),
    };
    yield put(annualReviewDetailsSetAction(updatedResponse));
    yield put(setDetails);
    toastService.showSuccess("Annual Review status changed successfully");
  } catch (e: any) {
    console.log(e);
    toastService.showError("Couldn't fetch detail for this review. Something went wrong!");
  }
}

function* setDetails(): Generator {
  const allDocumentUploadIds = yield select(allDocumentUploadIdsSelector);
  yield put(SetSelectedUploadIds(allDocumentUploadIds as number[]));
}

function* pipelineLoans() {
  yield takeLatest(PipelineLoans.FETCH_LOANS, fetchPipelineLoans);
  yield takeLatest(PipelineLoans.FETCH_ANNUAL_REVIEWS, fetchPipelineAnnualReviews);
  yield takeEvery(PipelineLoans.UPDATE_PIPELINE_SEARCH_PARAMS, updatePipelineSearchParams);
  yield takeEvery(PipelineLoans.FETCH_LOAN_DETAIL, fetchLoanDetailsById);
  yield takeEvery(PipelineLoans.FETCH_ANNUAL_REVIEW_DETAIL, fetchAnnualReviewDetailsById);
  yield takeEvery(PipelineLoans.CREATE_DOCUMENT_REQUEST, createDocumentsRequest);
  yield takeEvery(PipelineLoans.CREATE_REPORTING_SEQUENCE, createReportingSequence);
  yield takeEvery(PipelineLoans.UPDATE_REPORTING_SEQUENCE, updateReportingSequence);
  yield takeEvery(PipelineLoans.EDIT_REPORTING_SEQUENCE, editReportingSequence);
  yield takeEvery(PipelineLoans.DELETE_REPORTING_SEQUENCE, deleteReportingSequence);
  yield takeEvery(PipelineLoans.UPDATE_DOCUMENT_STATUS, updateDocumentStatus);
  yield takeEvery(PipelineLoans.EDIT_LOAN, editLoanDetails);
  yield takeEvery(PipelineLoans.UPDATE_LOAN_STATUS, updateLoanStatusSaga);
  yield takeEvery(PipelineLoans.UPDATE_ANNUAL_REVIEW_STATUS, updateAnnualReviewStatusSaga);
  yield takeEvery(PipelineLoans.MODIFY_CUSTOMER_USERS, modifyCustomerUsers);
  yield takeEvery(PipelineLoans.ADD_UPDATE_NOTE, addUpdateNoteSaga);
  yield takeEvery(PipelineLoans.UPDATE_METADATA, updateMetadataSaga);
  yield takeEvery(PipelineLoans.UPDATE_REMINDER, updateReminder);
  yield takeEvery(PipelineLoans.SET_ANNUAL_REVIEW_DETAIL, setDetails);
  yield takeEvery(PipelineLoans.SET_LOAN_DETAIL, setDetails);
}

export default pipelineLoans;
