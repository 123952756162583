import { FileReference } from "src/pages/api/documents/ocr/FileReference";
import { baseApiService } from "./BaseApiService";
import { GetOCrParams, OcrResponse } from "src/pages/api/documents/ocr/v2.api";
import { AnalysisType } from "src/page-components/investment-detail/tabs/SpreadsTab/SpreadsTabConstants";

class OcrService {
  static getInstance(): OcrService {
    return new OcrService();
  }

  async startOcr(dataNeeded: FileReference[], analysisType: AnalysisType): Promise<OcrResponse> {
    const url =
      analysisType === AnalysisType.FINANCIALS ? "/api/documents/ocr/v3" : "/api/documents/ocr/v2";
    const dataNeededWithNoOutput = dataNeeded.map((d) => ({ ...d, output: undefined }));
    return await baseApiService.post(url, {
      dataNeeded: dataNeededWithNoOutput,
      analysisType,
    });
  }

  async checkOcrStatus(params: GetOCrParams): Promise<OcrResponse> {
    const url =
      params.analysisType === AnalysisType.FINANCIALS
        ? "/api/documents/ocr/v3?jobIdsSerialized="
        : "/api/documents/ocr/v2?jobIdsSerialized=";
    const cleanedParams = {
      dataNeeded: params.dataNeeded.map((d) => ({
        ...d,
        documentType: { ...d.documentType, output: undefined },
      })),
      analysisType: params.analysisType,
    };
    const jobIdsSerialized = encodeURIComponent(JSON.stringify(cleanedParams));
    return await baseApiService.get(url + jobIdsSerialized);
  }
}

export const ocrService = OcrService.getInstance();
