import { all, fork } from "redux-saga/effects";
import bootStrapSaga from "./bootstrap.saga";
import intakeSaga from "src/redux/sagas/intake.saga";
import loanPipelineSaga from "./pipelineLoans.saga";
import lenderSaga from "./lender.saga";
import annualReviewSaga from "src/redux/sagas/annualReview.saga";
import contactSaga from "src/redux/sagas/contact.saga";
import spreadSaga from "src/redux/sagas/spread.saga";
import featureFlagSaga from "./feature-flag.saga";
import reportingSaga from "src/redux/sagas/reporting.saga";
import ocrSaga from "./ocr.saga";
import loanWorkflows from "src/redux/sagas/loan-workflow.saga";
import management from "src/redux/sagas/management.saga";
import loanSage from "src/redux/sagas/loan.saga";
import documentAndReminderSaga from "src/redux/sagas/document-reminder.saga";

export default function* rootSaga() {
  yield all([fork(bootStrapSaga)]);
  yield all([fork(intakeSaga)]);
  yield all([fork(loanPipelineSaga)]);
  yield all([fork(lenderSaga)]);
  yield all([fork(annualReviewSaga)]);
  yield all([fork(contactSaga)]);
  yield all([fork(spreadSaga)]);
  yield all([fork(featureFlagSaga)]);
  yield all([fork(reportingSaga)]);
  yield all([fork(ocrSaga)]);
  yield all([fork(loanWorkflows)]);
  yield all([fork(management)]);
  yield all([fork(loanSage)]);
  yield all([fork(documentAndReminderSaga)]);
}
