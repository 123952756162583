import {
  DefaultRowLabelsSubset,
  HoverInfo,
  Rendered,
  RenderedDoc,
  RenderedMetaData,
} from "src/classes/RenderedDoc";
import { ExtractContext } from "src/backend/services/ocr/spread-data-aggregation/spread-data-aggregate";
import { RentRollTableData } from "src/classes/RenderedDocuments/RentRoll";
import { CellValue, RawCellContent } from "hyperformula";
import { GridState, RowId } from "src/classes/GridState";
import type { Prisma } from "@prisma/client";
import { TaxProperty } from "src/interfaces/TaxFormData/schedules/ScheduleE";
import { JsonValue } from "@prisma/client/runtime/library";
import { ExtractableDocumentType } from "src/Enums/ExtractableDocumentType";

export interface PropertyDetail {
  propertyName: string;
  grossRents: number;
  occupancyPercentage: number;
  managementFeePercentage: number;
  replacementCostPercentage: number;
  annualPropertyTaxes: number;
  annualInsurance: number;
  utilities: number;
  payments: number;
  year: number;
}

export interface IncomeAssetParts {
  [k: string]: RawCellContent | ExtractContext | RawCellContent[];
  extractContext?: ExtractContext;
  year?: number;
  grossRents?: RawCellContent;
  propertyName?: string;
  annualPropertyTaxes?: RawCellContent;
  requestedOn?: string;
  annualInsurance?: RawCellContent;
  utilities?: RawCellContent;
  repairs?: RawCellContent;
}

export interface PersonalTaxReturnParts extends Prisma.JsonObject {
  ["Wages and Salaries"]: string;
  ["Interest and Dividends"]: string;
  ["Social Security Benefits"]: string;
  ["Schedule C EBIDA"]: string;
  ["Schedule D Capital Gains"]: string;
  ["Schedule E Net Income"]: string;
  ["Plus: Interest Expense"]: string;
  ["Plus: Depreciation & Amort. Expense"]: string;
  ["K-1 Distributions, Net Contributions*"]: string;
  ["Subtotal:"]: string;
  ["Less: Federal Taxes"]: string;
  ["Less: Schedule A State & Local Taxes"]: string;
  ["Less: Schedule A Taxes"]: string;
  ["Cash Flow Available for Debt Service"]: string;
  ["Credit Cards (5-11-23 CBR)"]: string;
  ["Auto Loans (5-11-23 CBR)"]: string;
  ["HELOC (FTB) (5-11-23 CBR)"]: string;
  ["Residential Mortgage (5-11-23 CBR)"]: string;
  ["Investment Mortgages Payable (FTB)"]: string;
  ["Investment Mortgages Payable (3rd Parties)"]: string;
  ["Total Debt Service"]: string;
  ["Personal DSCR"]: string;
  ["Excess Cash Flow"]: string;
}

interface PropertyCalculations {
  get calculateNOI(): number;
  get calculateDSCR(): number;
}

export class Property implements PropertyDetail, PropertyCalculations {
  propertyName: string = "";
  grossRents: number = 0;
  occupancyPercentage: number = 0;
  managementFeePercentage: number = 0;
  replacementCostPercentage: number = 0;
  annualPropertyTaxes: number = 0;
  annualInsurance: number = 0;
  utilities: number = 0;
  payments: number = 0;
  year: number = 0;

  constructor(details: PropertyDetail) {
    Object.assign(this, details);
  }

  colLabels: string[] = ["Description", "Value"];

  get calculateNOI(): number {
    return (
      this.grossRents * (this.occupancyPercentage / 100) - // Corrected use of occupancy percentage
      (this.grossRents * (this.managementFeePercentage / 100) +
        this.grossRents * (this.replacementCostPercentage / 100) +
        this.annualPropertyTaxes +
        this.annualInsurance +
        this.utilities)
    );
  }

  get calculateDSCR(): number {
    if (this.payments === 0) {
      // Handle division by zero case, perhaps by returning a special value or throwing an error
      throw new Error("Payments cannot be zero when calculating DSCR.");
    }
    return this.calculateNOI / this.payments;
  }
}

export interface RentRollPropertyInfo {
  properties: RentRollTableData[];
}

export type PropertyDetailPersonalTaxReturn = Pick<
  PropertyDetail,
  "grossRents" | "payments" | "year"
> & {
  netIncome: number;
  mortgageInterest: number;
  otherInterest: number;
  depreciation: number;
  amortization: number;
  propertyName: string;
};

export interface PropertyTaxReturn extends Prisma.JsonObject {
  propertyName: string;
  grossRents: number;
  netIncome: number;
  mortgageInterest: number;
  otherInterest: number;
  payments: number;
  depreciation: number;
  amortization: number;
  year: number;
}

export class PropertyTaxReturn implements PropertyDetailPersonalTaxReturn, PropertyCalculations {
  static from(scheduleE: TaxProperty, entityName: string, lenderId: number): PropertyTaxReturn {
    const {
      rentsReceived,
      netIncome,
      mortgageInterest,
      otherInterest,
      depreciation,
      amortization,
      year,
    } = scheduleE;
    const parse = (value: JsonValue | undefined) => (typeof value === "number" ? value : 0);
    const calculatedGrossRents =
      parse(netIncome) +
      parse(mortgageInterest) +
      parse(otherInterest) +
      parse(depreciation) +
      parse(amortization);
    const details: PropertyDetailPersonalTaxReturn = {
      propertyName: scheduleE.propertyAddress,
      grossRents: rentsReceived ? parse(rentsReceived) : calculatedGrossRents,
      netIncome: parse(netIncome),
      mortgageInterest: parse(mortgageInterest),
      otherInterest: parse(otherInterest),
      payments: 0, // this is not used and TaxProperty does not have a payments field
      depreciation: parse(depreciation),
      amortization: parse(amortization),
      year: parse(year),
    };
    const context: ExtractContext = {
      extractorType: ExtractableDocumentType.FORM_1040,
      packageDetails: [],
      metadata: {
        year: parse(year),
        entityName: entityName,
        lenderId,
      },
    };
    return new PropertyTaxReturn(details, context);
  }
  constructor(
    details: PropertyDetailPersonalTaxReturn,
    public extractContext: ExtractContext,
  ) {
    Object.assign(this, details);
  }

  propertyName: string = "";
  grossRents: number = 0;
  netIncome: number = 0;
  interest: number = 0;
  payments: number = 0;
  depreciation: number = 0;
  amortization: number = 0;
  year: number = 0;

  colLabels: string[] = ["Description", "Value"];

  get calculateNOI(): number {
    // Example implementation, adjust according to your logic
    return this.netIncome + this.interest + this.depreciation + this.amortization;
  }

  get calculateDSCR(): number {
    if (this.payments === 0) throw new Error("Payments cannot be zero.");
    return this.calculateNOI / this.payments;
  }
}

export interface PersonalTaxReturnPropertyInfo {
  properties: PropertyTaxReturn[];
}

type PropertyTaxReturnTableDefaultRowLabels = [
  "Property Name",
  "Gross Rents",
  "Payments",
  "Net Income",
  "Interest",
  "Depreciation",
  "Amortization",
  "NOI",
  "DSCR",
];
type PropertyTaxReturnTableDefaultRowLabel = PropertyTaxReturnTableDefaultRowLabels[number];

export class PropertyTaxReturnTable
  extends Rendered<PropertyTaxReturn, PropertyTaxReturnTableDefaultRowLabel>
  implements RenderedDoc
{
  constructor(
    document: PropertyTaxReturn,
    public renderedMetadata: RenderedMetaData,
    public hoverInfos: HoverInfo[][] = [],
  ) {
    super(document);
    this.cellState = this.asArray();
    this.numberOfColumns = 2; // TODO --> Remove the hard-coded value here
  }

  numberOfColumns: number;

  // TODO --> Remote this placeholder
  get initialGridState(): GridState {
    return {
      ["blankRow" as RowId]: {
        rowDataArray: ["Dummy Row", "Dummy Row"],
        rowDataType: "text",
        rowStyle: "standard",
        isManagedByApp: false,
        index: -1,
      },
    };
  }

  // TODO --> HOPEFULLY REMOVE EVERYTHING BELOW THIS LINE
  cellState: CellValue[][];

  fromArray(arrs: CellValue[][]): PropertyTaxReturn[] {
    throw new Error("Method not implemented.");
  }
  asCol(): CellValue[] {
    throw new Error("Method not implemented.");
  }
  colLabels: string[] = ["Description", "Value"];

  labelToMemberMap: Map<PropertyTaxReturnTableDefaultRowLabel, keyof PropertyTaxReturn> = new Map([
    ["Property Name", "propertyName"],
    ["Gross Rents", "grossRents"],
    ["Payments", "payments"],
    ["Net Income", "netIncome"],
    ["Interest", "interest"],
    ["Depreciation", "depreciation"],
    ["Amortization", "amortization"],
    // Special handling might be needed for calculated values
    ["NOI", "calculateNOI"],
    ["DSCR", "calculateDSCR"],
  ]);

  defaultRowLabels: PropertyTaxReturnTableDefaultRowLabels = [
    "Property Name",
    "Gross Rents",
    "Payments",
    "Net Income",
    "Interest",
    "Depreciation",
    "Amortization",
    "NOI",
    "DSCR",
  ];

  highlightedRowLabels: DefaultRowLabelsSubset<PropertyTaxReturnTableDefaultRowLabels> = [
    "Net Income",
    "NOI",
    "DSCR",
  ];

  percentageRowLabels: DefaultRowLabelsSubset<PropertyTaxReturnTableDefaultRowLabels> = ["DSCR"];

  asArray() {
    // prettier-ignore
    let arr = [
    ["Property Name", this.underlying.propertyName],
    ["", ""],
    ["Gross Rents", this.underlying.grossRents],
    ["Net Income", this.underlying.netIncome],
    ["Interest", this.underlying.interest],
    ["Depreciation", this.underlying.depreciation],
    ["Amortization", this.underlying.amortization],
    // Special handling might be needed for calculated values
    ["NOI", "=SUM(B4:B7)"],
    ["DSCR", "=B8/B3"],
     ];
    return arr;
  }

  updateCell(docName: string, colId: string, row: number, value: string): void {
    throw new Error("Method not implemented.");
  }

  // headers: string[] = this.colLabels;
}

type PersonalTaxReturnDefaultRowLabels = [
  "Property Name",
  "Gross Rents",
  "Payments",
  "Net Income",
  "Interest",
  "Depreciation",
  "Amortization",
  "NOI",
  "DSCR",
];
type PersonalTaxReturnDefaultRowLabel = PersonalTaxReturnDefaultRowLabels[number];

export class PersonalTaxReturnRendered extends Rendered<
  PersonalTaxReturnPropertyInfo,
  PersonalTaxReturnDefaultRowLabel
> {
  constructor(
    document: PersonalTaxReturnPropertyInfo,
    renderedMetadata: RenderedMetaData,
    public hoverInfos: HoverInfo[][] = [],
  ) {
    super(document);
    this.numberOfColumns = 2; // TODO --> CHECK THIS LOGIC
  }

  numberOfColumns: number;

  // TODO --> Remote this placeholder
  get initialGridState(): GridState {
    return {
      ["blankRow" as RowId]: {
        rowDataArray: ["Dummy Row", "Dummy Row"],
        rowDataType: "text",
        rowStyle: "standard",
        isManagedByApp: false,
        index: -1,
      },
    };
  }

  asCol(): CellValue[] {
    throw new Error("Method not implemented.");
  }
  fromArray(arrs: CellValue[][]): PersonalTaxReturnPropertyInfo[] {
    throw new Error("Method not implemented.");
  }
  get colLabels(): string[] {
    return ["Description", "Value"];
  }
  defaultRowLabels: PersonalTaxReturnDefaultRowLabels = [
    "Property Name",
    "Gross Rents",
    "Payments",
    "Net Income",
    "Interest",
    "Depreciation",
    "Amortization",
    "NOI",
    "DSCR",
  ];

  highlightedRowLabels: DefaultRowLabelsSubset<PropertyTaxReturnTableDefaultRowLabels> = [
    "Net Income",
    "NOI",
    "DSCR",
  ];

  percentageRowLabels: DefaultRowLabelsSubset<PropertyTaxReturnTableDefaultRowLabels> = ["DSCR"];

  static labelToMemberMap: Map<PersonalTaxReturnDefaultRowLabel, keyof PropertyTaxReturn> = new Map(
    [
      ["Property Name", "propertyName"],
      ["Gross Rents", "grossRents"],
      ["Payments", "payments"],
      ["Net Income", "netIncome"],
      ["Interest", "interest"],
      ["Depreciation", "depreciation"],
      ["Amortization", "amortization"],
      // Special handling might be needed for calculated values
      ["NOI", "calculateNOI"],
      ["DSCR", "calculateDSCR"],
    ],
  );

  asCols(): (string | number)[][] {
    return this.underlying.properties.map((property) => [
      property.propertyName,
      property.grossRents,
      property.payments,
      property.netIncome,
      property.interest,
      property.depreciation,
      property.amortization,
      property.calculateNOI,
      property.calculateDSCR,
    ]);
  }

  asArray(): RawCellContent[][] {
    const arr: RawCellContent[][] = this.defaultRowLabels.map(
      (label: PersonalTaxReturnDefaultRowLabel) => {
        const member = PersonalTaxReturnRendered.labelToMemberMap.get(label);
        if (member === undefined) throw new Error(`No member found for label ${label}`);
        const row = [label, this.underlying.properties[0][member as keyof PropertyTaxReturn]];
        return row as RawCellContent[];
      },
    );
    return arr;
  }

  asArrays(): RawCellContent[][][] {
    return [this.asArray()];
  }
}
