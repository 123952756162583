import { DocumentUploadStatus } from "@prisma/client";

type StatusDetails = {
  selectOptions: (
    rawDocumentUrl: string | null,
    addReviewedStatus?: boolean,
  ) => { value: string; label: string }[];
  label: string;
  key: DocumentUploadStatus;
};

export type StatusTransitionMatrix = {
  [DocumentUploadStatus.REQUESTED]: StatusDetails;
  [DocumentUploadStatus.COLLECTED]: StatusDetails;
  [DocumentUploadStatus.REVIEWED]: StatusDetails;
  [DocumentUploadStatus.VERIFIED]: StatusDetails;
  [DocumentUploadStatus.WAIVED]: StatusDetails;
  [DocumentUploadStatus.COLLECTION_FAILED]: StatusDetails;
  [DocumentUploadStatus.ANALYZED]: StatusDetails;
  [DocumentUploadStatus.COMPLETED]: StatusDetails;
};

export const statusTransitionMatrix: StatusTransitionMatrix = {
  [DocumentUploadStatus.REQUESTED]: {
    selectOptions: (_rawDocumentUrl, _addReviewedStatus) => {
      return [
        { value: DocumentUploadStatus.REQUESTED, label: "Pending" },
        { value: DocumentUploadStatus.WAIVED, label: "Waived" },
      ];
    },
    label: "Pending",
    key: DocumentUploadStatus.REQUESTED,
  },
  [DocumentUploadStatus.COLLECTED]: {
    selectOptions: (_rawDocumentUrl, addReviewedStatus) => {
      return [
        { value: DocumentUploadStatus.COLLECTED, label: "Received" },
        ...(addReviewedStatus
          ? [{ value: DocumentUploadStatus.REVIEWED, label: "Reviewed" }]
          : [{ value: DocumentUploadStatus.VERIFIED, label: "Verified" }]),
        { value: DocumentUploadStatus.WAIVED, label: "Waived" },
      ];
    },
    label: "Received",
    key: DocumentUploadStatus.COLLECTED,
  },
  [DocumentUploadStatus.REVIEWED]: {
    selectOptions: (_rawDocumentUrl, _addReviewedStatus) => {
      return [
        { value: DocumentUploadStatus.ANALYZED, label: "Analyzed" },
        { value: DocumentUploadStatus.REVIEWED, label: "Reviewed" },
        { value: DocumentUploadStatus.VERIFIED, label: "Verified" },
      ];
    },
    label: "Reviewed",
    key: DocumentUploadStatus.REVIEWED,
  },
  [DocumentUploadStatus.VERIFIED]: {
    selectOptions: (_rawDocumentUrl, _addReviewedStatus) => {
      return [{ value: DocumentUploadStatus.VERIFIED, label: "Verified" }];
    },
    label: "Verified",
    key: DocumentUploadStatus.VERIFIED,
  },
  [DocumentUploadStatus.WAIVED]: {
    selectOptions: (rawDocumentUrl, _addReviewedStatus) => {
      if (rawDocumentUrl) {
        return [
          { value: DocumentUploadStatus.WAIVED, label: "Waived" },
          { value: DocumentUploadStatus.COLLECTED, label: "Received" },
          { value: DocumentUploadStatus.REVIEWED, label: "Reviewed" },
          { value: DocumentUploadStatus.ANALYZED, label: "Analyzed" },
          { value: DocumentUploadStatus.VERIFIED, label: "Verified" },
        ];
      } else {
        return [
          { value: DocumentUploadStatus.REQUESTED, label: "Pending" },
          { value: DocumentUploadStatus.WAIVED, label: "Waived" },
        ];
      }
    },
    label: "Waived",
    key: DocumentUploadStatus.WAIVED,
  },
  [DocumentUploadStatus.COLLECTION_FAILED]: {
    selectOptions: (_rawDocumentUrl, _addReviewedStatus) => {
      return [
        {
          value: DocumentUploadStatus.COLLECTION_FAILED,
          label: "Collection Failed",
        },
        { value: DocumentUploadStatus.REQUESTED, label: "Pending" },
        { value: DocumentUploadStatus.WAIVED, label: "Waived" },
      ];
    },
    label: "Collection Failed",
    key: DocumentUploadStatus.COLLECTION_FAILED,
  },
  [DocumentUploadStatus.ANALYZED]: {
    selectOptions: (_rawDocumentUrl, _addReviewedStatus) => {
      return [
        { value: DocumentUploadStatus.ANALYZED, label: "Analyzed" },
        { value: DocumentUploadStatus.COMPLETED, label: "Completed" },
      ];
    },
    label: "Analyzed",
    key: DocumentUploadStatus.ANALYZED,
  },
  [DocumentUploadStatus.COMPLETED]: {
    selectOptions: (_rawDocumentUrl, _addReviewedStatus) => {
      return [{ value: DocumentUploadStatus.COMPLETED, label: "Completed" }];
    },
    label: "Completed",
    key: DocumentUploadStatus.COMPLETED,
  },
};

const documentUploadStatusToLabels = {
  [DocumentUploadStatus.REQUESTED]: "Pending",
  [DocumentUploadStatus.COLLECTED]: "Received",
  [DocumentUploadStatus.REVIEWED]: "Reviewed",
  [DocumentUploadStatus.VERIFIED]: "Verified",
  [DocumentUploadStatus.WAIVED]: "Waived",
  [DocumentUploadStatus.COLLECTION_FAILED]: "Collection Failed",
  [DocumentUploadStatus.ANALYZED]: "Analyzed",
  [DocumentUploadStatus.COMPLETED]: "Completed",
};

export const convertDocumentStatusToOptionValue = (state: DocumentUploadStatus) => {
  return documentUploadStatusToLabels[state];
};
