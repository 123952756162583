import { ReportingAction } from "src/redux/actions/actions.constants";
import { SQLQueryWhere } from "src/utils/sql-query-builder";
import { ReportingFilter } from "@prisma/client";
import { TableHeader } from "src/models/ReportingFilter";

export const getReportingDataForTableAction = (payload: {
  sortKey: string;
  isAscending: boolean;
  filterValues: SQLQueryWhere;
  columns: string[];
  fetchNextPage: boolean;
}) => ({
  type: ReportingAction.GET_REPORTING_DATA_FOR_TABLE,
  payload: payload,
});

export const resetIsLoadingAction = () => ({
  type: ReportingAction.RESET_IS_LOADING,
});

export const setReportingDataForTableAction = (data: any) => ({
  type: ReportingAction.SET_REPORTING_DATA_FOR_TABLE,
  payload: data,
});

export const getReportingDataForCsvAction = (payload: {
  filterValues: SQLQueryWhere;
  columns: string[];
}) => ({
  type: ReportingAction.GET_REPORTING_DATA_FOR_CSV,
  payload: payload,
});

export const reportingDataForCsvActionCompleted = () => ({
  type: ReportingAction.REPORTING_DATA_FOR_CSV_COMPLETED,
});

export const getReportingFilters = () => ({
  type: ReportingAction.GET_REPORTING_FILTERS,
});

export const getReportingHeaders = () => ({
  type: ReportingAction.GET_REPORTING_HEADERS,
});

export const updateReportingFilter = (payload: {
  name: string;
  description?: string;
  filters: string;
  id: number;
}) => ({
  type: ReportingAction.UPDATE_REPORTING_FILTER,
  payload,
});

export const createReportingFilter = (payload: {
  name: string;
  description?: string;
  filters: string;
}) => ({
  type: ReportingAction.CREATE_REPORTING_FILTER,
  payload,
});

export const deleteReportingFilter = (payload: number) => ({
  type: ReportingAction.DELETE_REPORTING_FILTER,
  payload,
});

export const setReportingFilters = (payload: ReportingFilter[]) => ({
  type: ReportingAction.SET_REPORTING_FILTERS,
  payload,
});

export const setReportingHeaders = (payload: TableHeader[]) => ({
  type: ReportingAction.SET_REPORTING_HEADERS,
  payload,
});
