import { TabName } from "src/redux/reducers/types";
import { baseApiService } from "./BaseApiService";
import { GridState } from "src/classes/GridState";
import type { FinancialSheet, Prisma } from "@prisma/client";
import { ColDef } from "ag-grid-community";
import { TabGroup } from "src/classes/RenderedDocuments/Workflows/RenderedGeneralSpreads";
import { HoverInfo } from "src/classes/RenderedDoc";

export type GridStateInitParams = Omit<
  Prisma.FinancialSheetCreateInput,
  "gridState" | "columnDefs"
> & {
  gridState?: Record<TabName, GridState>;
  columnDefs?: Record<TabName, ColDef>;
};

class GridStateApiService {
  static getInstance = () => {
    return new GridStateApiService();
  };

  async saveToDb(props: {
    id: number;
    gridStates: Record<TabName, GridState>;
    columnDefs: Record<TabName, ColDef>;
    tabGroups: TabGroup[];
    hoverInfos: Record<TabName, HoverInfo[][]>;
  }): Promise<FinancialSheet | Error> {
    const { id, gridStates, columnDefs, tabGroups, hoverInfos } = props;
    const req = {
      gridState: gridStates,
      columnDefs,
      tabGroups,
      hoverInfos,
    };
    try {
      const response = (await baseApiService.patch(`/api/sheet/${id}`, req)) as FinancialSheet;
      return response as FinancialSheet;
    } catch (e) {
      const error = e as Error;
      return error;
    }
  }

  fetchByAnnualReviewOrLoanId = async (props: {
    annualReviewId: number | null;
    loanId: number | null;
  }): Promise<FinancialSheet[] | Error> => {
    const query = props.annualReviewId
      ? `annualReviewId=${props.annualReviewId}`
      : `loanId=${props.loanId}`;
    try {
      const response = (await baseApiService.get(`/api/sheet?${query}`)) as FinancialSheet[];
      return response;
    } catch (e) {
      const error = e as Error;
      return error;
    }
  };

  fetchById = async (id: number): Promise<FinancialSheet | Error> => {
    try {
      const response = (await baseApiService.get(`/api/sheet/${id}`)) as FinancialSheet;
      return response as FinancialSheet;
    } catch (e) {
      const error = e as Error;
      return error;
    }
  };

  init = async (input: GridStateInitParams) => {
    const response = await baseApiService.post(`/api/sheet`, input);
    const { loanId, annualReviewId } = response as FinancialSheet;

    if (loanId || annualReviewId) {
      return response as FinancialSheet;
    } else {
      return new Error("Failed to save sheet to db");
    }
  };
}

export const gridStateApiService = GridStateApiService.getInstance();
