import { Form1120S } from "src/interfaces/TaxFormData/Form1120s";
import { SupportedLenderId, supportedLenderIds } from "src/interfaces/SpreadsConfig/SpreadsConfig";
import spreadConfig from "src/spreads-config";
import { AutoRenderedSheetBuilder } from "../AutoRenderedSheetBuilder";

export const Form1120sCashFlowRowHeadings = {
  NetIncome: "Net income",
  Interest: "Interest",
  Depreciation: "Depreciation",
  Amortization: "Amortization",
  GainLossOnFixedAssetSale: "(Gain)/Loss on fixed asset sale",
  ExtraordinaryGainLoss: "Extraordinary (gain)/loss *",
  BusinessCashFlowBeforeTax: "Business cash flow before tax",
  TotalTax: "Total tax",
  BusinessCashFlowAfterTax: "Business cash flow after tax",
  LessDistributionsDividends: "Less: Distributions / Dividends",
  LessOtherCfAdjustment: "Less: Other CF adjustment *",
  BusinessCashFlowAvailableForDebtService: "Business cash flow available for debt service",
  DebtService: "Debt service  ",
  ProjectedDebtService: "Projected debt service",
  DebtServiceCoverageRatio: "Debt service coverage ratio",
  StressScenarioDebtService: "Stress scenario - debt service",
  InterestRateStress: "Interest rate stress",
  HypotheticalDebtServiceCoverageRatio: "Hypothetical debt service coverage ratio",
};

export const buildForm1120sCashFlow = (
  data: Form1120S,
  startingColumn: string,
  startingRow: number,
): AutoRenderedSheetBuilder<Form1120S, typeof Form1120sCashFlowRowHeadings> => {
  const hideAsterisks = supportedLenderIds.find((l) => l === data.lenderId)
    ? spreadConfig.lenderSettings[data.lenderId as SupportedLenderId]?.tempHideManualRows
    : false;
  const asterisk = (header: string) => (hideAsterisks ? header.replace(" *", "") : header);

  return new AutoRenderedSheetBuilder(
    data,
    Form1120sCashFlowRowHeadings,
    startingRow,
    startingColumn,
  )
    .addRow(({ data, labels }) => [
      labels.NetIncome,
      data.schedules?.scheduleM1?.netIncomeLoss ?? 0,
    ])
    .addRow(({ data, labels }) => [labels.Interest, data.deductions?.interest ?? 0])
    .addRow(({ data, labels }) => [labels.Depreciation, depreciationFormula(data)])
    .addRow(({ data, labels }) => [labels.Amortization, data.deductions?.amortization?.value ?? 0])
    .addRow(({ data, labels }) => [
      labels.GainLossOnFixedAssetSale,
      !data.income?.netGainOrLossFromForm4797 ? 0 : data.income?.netGainOrLossFromForm4797 * -1,
    ])
    .addRow(({ labels }) => [asterisk(labels.ExtraordinaryGainLoss), "0"])
    .addRow(({ labels, columnReference }) => [
      labels.BusinessCashFlowBeforeTax,
      `=SUM(${columnReference(labels.NetIncome)}:${columnReference(asterisk(labels.ExtraordinaryGainLoss))})`,
    ])
    .addRow(({ labels }) => [labels.TotalTax, "0"])
    .addRow(({ labels, columnReference }) => [
      labels.BusinessCashFlowAfterTax,
      `=${columnReference(labels.BusinessCashFlowBeforeTax)} - ${columnReference(labels.TotalTax)}`,
    ])
    .addRow(({ labels, data }) => [
      labels.LessDistributionsDividends,
      data.schedules?.scheduleM2?.distributions?.accumulatedAdjustments ?? 0,
    ])
    .addRow(({ labels }) => [asterisk(labels.LessOtherCfAdjustment), "0"])
    .addRow(({ labels, columnReference }) => [
      labels.BusinessCashFlowAvailableForDebtService,
      `=${columnReference(labels.BusinessCashFlowAfterTax)} - ${columnReference(labels.LessDistributionsDividends)} - ${columnReference(asterisk(labels.LessOtherCfAdjustment))}`,
    ])

    .addRow()
    .addRow(({ labels }) => [labels.DebtService, ""])
    .addRow(({ labels }) => [labels.ProjectedDebtService, "0"])
    .addRow(({ labels, columnReference }) => [
      labels.DebtServiceCoverageRatio,
      `=${columnReference(labels.BusinessCashFlowAvailableForDebtService)} / ${columnReference(labels.ProjectedDebtService)}`,
    ])

    .addRow()
    .addRow(({ labels }) => [labels.StressScenarioDebtService, ""])
    .addRow(({ labels, columnReference }) => [
      labels.ProjectedDebtService,
      `=${columnReference(labels.ProjectedDebtService)}`,
    ])
    .addRow(({ labels }) => [labels.InterestRateStress, "0"])
    .addRow(({ labels, columnReference }) => [
      labels.HypotheticalDebtServiceCoverageRatio,
      `=${columnReference(labels.BusinessCashFlowAvailableForDebtService)} / (${columnReference(labels.ProjectedDebtService, "end")} + ${columnReference(labels.InterestRateStress)})`,
    ]);
};

function depreciationFormula(data: Form1120S): string {
  // Note: The field data.form4562?.section179Deduction is intentionally omitted
  // because we believe it represents the same data as data.schedules?.scheduleK?.section179Deduction.
  return `=${data.deductions?.depreciation ?? 0} + ${data.schedules?.scheduleK?.section179Deduction ?? 0} - ${data.schedules?.scheduleM1?.deductionsOnReturnDepreciation ?? 0} + ${data.schedules?.scheduleM1?.expensesRecordedOnBooksDepreciation ?? 0}`;
}
