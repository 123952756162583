import { RentRollTableData } from "src/classes/RenderedDocuments/RentRoll";
import { CanonicalRentRoll } from "src/models/CanonicalRentRoll";

export const canonicalRentRoll2RentRollData = (
  canonicalRentRoll: CanonicalRentRoll,
): RentRollTableData => {
  const rentRoll: RentRollTableData = {
    managementFeePercentage: canonicalRentRoll.managementFeePercentage,
    replacementCostPercentage: null,
    grossRents: canonicalRentRoll.monthlyGrossRents * 12,
    annualPropertyTaxes: canonicalRentRoll.annualPropertyTaxes ?? null,
    annualInsurance: canonicalRentRoll.annualInsurance ?? null,
    utilities: canonicalRentRoll.utilities ?? null,
    occupancyPercentage: null,
    extractContext: canonicalRentRoll.extractContext,
    propertyName: canonicalRentRoll.streetAddress,
    year: canonicalRentRoll.extractContext.metadata.year,
  };

  return rentRoll;
};
