import { baseApiService } from "src/services/api-services/BaseApiService";
import { DocumentReminderOption, DocumentReminderResponse } from "src/models/DocumentAndReminders";

export type DocumentAndRemindersFetchResponse = {
  documents: DocumentReminderOption[];
  reminders: DocumentReminderOption[];
};

export class DocumentReminderService {
  static getInstance(): DocumentReminderService {
    return new DocumentReminderService();
  }

  async fetchDocumentAndReminders(): Promise<DocumentAndRemindersFetchResponse> {
    const documentTypes = await baseApiService.get<DocumentReminderResponse>("/api/documents");
    const reminderTypes = await baseApiService.get<DocumentReminderResponse>("/api/reminder");

    return {
      documents: documentTypes.data ?? [],
      reminders: reminderTypes.data ?? [],
    };
  }

  async addUpdateNote(payload: {
    documentRequestId?: string;
    reminderId?: string;
    reviewId?: string;
    loanId?: string;
    notes: string;
  }): Promise<any> {
    const documentRequestIds = (payload.documentRequestId ?? "")
      .split(",")
      .map((part) => part.trim())
      .filter((part) => part.length > 0);
    const reminderIds = (payload.reminderId ?? "")
      .split(",")
      .map((part) => part.trim())
      .filter((part) => part.length > 0);

    const params = new URLSearchParams();
    if (payload.reviewId) {
      params.append("annualReviewId", String(payload.reviewId));
    } else if (payload.loanId) {
      params.append("loanId", String(payload.loanId));
    }

    const url =
      documentRequestIds.length > 0 ? "/api/document-request/notes" : "/api/reminder/notes";
    return await baseApiService.patch(`${url}?${params.toString()}`, {
      ...(documentRequestIds.length > 0 ? { documentRequestIds } : {}),
      ...(reminderIds.length > 0 ? { reminderIds } : {}),
      notes: payload.notes,
    });
  }
}

export const documentReminderService = DocumentReminderService.getInstance();
