import { Form1040 } from "src/interfaces/TaxFormData/Form1040";
import { AutoRenderedSheetBuilder } from "../AutoRenderedSheetBuilder";
import { SupportedLenderId, supportedLenderIds } from "src/interfaces/SpreadsConfig/SpreadsConfig";
import { spreadConfig } from "src/spreads-config";
import { ficaCalculationConfiguration } from "../FicaCalculationConfiguration";
import { LoanCalculatorData, LoanCalculatorRendered } from "../LoanCalculatorRendered";

export const Form1040RowHeadings = {
  WagesAndSalaries: "Wages and Salaries",
  InterestAndDividends: "Interest and Dividends",
  LessInterestAndDividendIncomeFromK1s: "Less: Interest & dividend Income from K-1's *",
  PensionsAndAnnuities: "Pensions and Annuities",
  SocialSecurityBenefits: "Social Security Benefits",
  ScheduleCEBIDA: "Schedule C EBIDA",
  ScheduleDCapitalGains: "Schedule D Capital Gains",
  LessCapitalGainsOrAddBackCapitalLossesFromK1s:
    "Less: Capital Gains or Add Back Capital Losses from K-1's *",
  ScheduleENetIncome: "Schedule E Net Income",
  PlusInterestExpense: "Plus: Interest Expense",
  PlusDepreciationAndAmortExpense: "Plus: Depreciation & Amort. Expense",
  ScheduleFCashFlow: "Schedule F Cash Flow *",
  K1DistributionsNetContributions: "K-1 Distributions, Net Contributions",
  Subtotal: "Subtotal",
  LessFederalTaxes: "Less: Federal Taxes",
  LessScheduleAStateAndLocalTaxes: "Less: Schedule A State & Local Taxes",
  LessFICAOtherEmployerDeductions: "Less: FICA/Other Employer Deductions",
  LessLivingExpenses: "Less: Living Expenses",
  CashFlowAvailableForDebtService: "Cash Flow Available for Debt Service",
  TotalDebtService: "Total Debt Service",
  PersonalDSCR: "Personal DSCR",
  ExcessCashFlow: "Excess Cash Flow",
};

export function createForm1040Rendered(
  data: Form1040,
  startingColumn: string = "B",
  startingRow: number = 1,
  k1Distribution: number = 0,
  scheduleETabName: string | null = null,
  scheduleCTabName: string | null = null,
  enableLoanCalculator: boolean = false,
) {
  const shouldHideManualRows = supportedLenderIds.find((l) => l === data.lenderId)
    ? spreadConfig.lenderSettings[data.lenderId as SupportedLenderId]?.tempHideManualRows
    : false;

  const builder = new AutoRenderedSheetBuilder(
    data,
    Form1040RowHeadings,
    startingRow,
    startingColumn,
  );

  builder
    .addRow(({ data, labels }) => [labels.WagesAndSalaries, data.income?.wages || 0])
    .addRow(({ labels }) => [labels.InterestAndDividends, calculateInterestAndDividends()]);

  if (!shouldHideManualRows) {
    builder.addRow(({ labels }) => [labels.LessInterestAndDividendIncomeFromK1s, 0], undefined);
  }

  builder
    .addRow(({ labels }) => [labels.PensionsAndAnnuities, calculatePensionsAndAnnuities()])
    .addRow(({ data, labels }) => [
      labels.SocialSecurityBenefits,
      data.income?.socialSecurityBenefits || 0,
    ])
    .addRow(({ labels }) => [labels.ScheduleCEBIDA, scheduleCEBIDACalculation()])
    .addRow(({ data, labels }) => [
      labels.ScheduleDCapitalGains,
      data.schedules?.scheduleD?.capitalGainsAndLosses || data.income?.capitalGains || 0,
    ]);

  if (!shouldHideManualRows) {
    builder.addRow(({ labels }) => [labels.LessCapitalGainsOrAddBackCapitalLossesFromK1s, 0]);
  }

  builder
    .addRow(({ labels }) => [labels.ScheduleENetIncome, scheduleENetIncomeCalculation()])
    .addRow(({ labels }) => [labels.PlusInterestExpense, scheduleEInterestExpenseCalculation()])
    .addRow(({ labels }) => [
      labels.PlusDepreciationAndAmortExpense,
      scheduleEDepreciationAndAmortizationCalculation(),
    ]);
  if (!shouldHideManualRows) {
    builder.addRow(({ labels }) => [labels.ScheduleFCashFlow, 0]);
  }
  builder
    .addRow(({ labels }) => [asterisk(labels.K1DistributionsNetContributions), k1Distribution])
    .addRow(({ labels, columnReference }) => [
      labels.Subtotal,
      calculateSubtotal(columnReference, labels),
    ])
    .addRow(({ data, labels }) => [labels.LessFederalTaxes, data.tax?.totalTax || 0])
    .addRow(({ data, labels }) => [
      labels.LessScheduleAStateAndLocalTaxes,
      data.schedules?.scheduleA?.stateAndLocalTaxes || 0,
    ])
    .addRow(({ labels, columnReference }) => [
      labels.LessFICAOtherEmployerDeductions,
      calculateFICADeduction(columnReference(labels.WagesAndSalaries)),
    ])
    .addRow(({ labels }) => [asterisk(labels.LessLivingExpenses), 0])
    .addRow(({ labels, columnReference }) => [
      labels.CashFlowAvailableForDebtService,
      `=${columnReference(labels.Subtotal)} - SUM(${columnReference(labels.LessFederalTaxes)}:${columnReference(asterisk(labels.LessLivingExpenses))})`,
    ]);

  const lenderSpecificPart = Object.entries(data.lenderSpecificDebtLineItems || {});
  lenderSpecificPart.forEach(([key, value]) => {
    builder.addRow(() => [key, value?.toString()]);
  });

  builder
    .addRow(({ labels, columnReference }) => [
      labels.TotalDebtService,
      lenderSpecificPart.length
        ? `=SUM(${columnReference(labels.CashFlowAvailableForDebtService, "end")}:${columnReference(labels.TotalDebtService, "end")})`
        : "0",
    ])
    .addRow(({ labels, columnReference }) => [
      labels.PersonalDSCR,
      `=${columnReference(labels.CashFlowAvailableForDebtService)}/${columnReference(labels.TotalDebtService)}`,
    ])
    .addRow(({ labels, columnReference }) => [
      labels.ExcessCashFlow,
      `=${columnReference(labels.CashFlowAvailableForDebtService)} - ${columnReference(labels.TotalDebtService)}`,
    ]);

  if (enableLoanCalculator) {
    const loanCalculatorData: LoanCalculatorData = {
      excessCashflow: builder.referenceInColumn(
        Form1040RowHeadings.CashFlowAvailableForDebtService,
      ),
      lenderId: (data.lenderId ? data.lenderId : "1") as SupportedLenderId,
      newDebt: "0",
    };

    const loanCalcStartingRow = builder.body.length + startingRow;
    const loanCalculatorPart = new LoanCalculatorRendered(
      loanCalculatorData,
      loanCalcStartingRow,
    ).asColumns();

    loanCalculatorPart.forEach((row) => {
      builder.addRow(() => row);
    });
  }

  return builder;

  function calculateInterestAndDividends(): number {
    const { income } = data;
    return (
      (income?.taxExemptInterest || 0) +
      (income?.taxableInterest || 0) +
      (income?.qualifiedDividends || 0) +
      (income?.ordinaryDividends || 0)
    );
  }

  function calculatePensionsAndAnnuities(): number {
    return (
      (data?.income?.pensionsAndAnnuities || 0) + (data?.income?.pensionsAndAnnuitiesTaxable || 0)
    );
  }

  function calculateFICADeduction(wageCell: string) {
    const year = data.year;
    const config = ficaCalculationConfiguration[year] ?? ficaCalculationConfiguration["2020"];
    const socialSecurityDeduction = `IF(${wageCell} > ${config.socialSecurityThreshold}, ${config.socialSecurityThreshold} * ${config.socialSecurity}, ${wageCell} * ${config.socialSecurity})`;
    const medicareDeduction = `${wageCell} * ${config.medicare}`;
    const extraMedicareDeduction = `IF(${wageCell} > ${config.additionalMedicareThreshold}, ${wageCell} * ${config.additionalMedicare}, 0)`;

    return `=ROUND(${socialSecurityDeduction} + ${medicareDeduction} + ${extraMedicareDeduction}, 2)`;
  }

  function scheduleCEBIDACalculation() {
    if (!scheduleCTabName || !data.schedules?.scheduleC?.length) {
      return 0;
    }
    const scheduleCCount = data.schedules?.scheduleC?.length ?? 0;
    const row = scheduleCCount + 2;
    return `='${scheduleCTabName}'!H${row}`;
  }

  function scheduleENetIncomeCalculation() {
    if (!scheduleETabName || !hasScheduleEProperties()) {
      return 0;
    }
    return `='${scheduleETabName}'!${scheduleETotalColumn()}${2}`;
  }

  function scheduleEInterestExpenseCalculation() {
    if (!scheduleETabName || !hasScheduleEProperties()) {
      return 0;
    }
    const interest = `'${scheduleETabName}'!${scheduleETotalColumn()}${4}`;
    const otherInterest = `'${scheduleETabName}'!${scheduleETotalColumn()}${5}`;
    return `=${interest} + ${otherInterest}`;
  }

  function scheduleEDepreciationAndAmortizationCalculation() {
    if (!scheduleETabName || !hasScheduleEProperties()) {
      return 0;
    }
    const depreciation = `'${scheduleETabName}'!${scheduleETotalColumn()}${3}`;
    const amortization = `'${scheduleETabName}'!${scheduleETotalColumn()}${6}`;
    return `=${depreciation} + ${amortization}`;
  }

  function hasScheduleEProperties() {
    return data.schedules?.scheduleE?.properties?.length ?? 0 > 0;
  }

  function scheduleETotalColumn() {
    const numberOfProperties = data.schedules?.scheduleE?.properties?.length ?? 0;
    return String.fromCharCode(65 + numberOfProperties + 1);
  }

  function calculateSubtotal(
    columnReference: (header: string) => string,
    labels: typeof Form1040RowHeadings,
  ) {
    const wagesAndInterest = `SUM(${columnReference(labels.WagesAndSalaries)}:${columnReference(labels.InterestAndDividends)})`;
    const pensionsToCapitalGains = `SUM(${columnReference(labels.PensionsAndAnnuities)}:${columnReference(labels.ScheduleDCapitalGains)})`;
    const scheduleEToK1 = `SUM(${columnReference(labels.ScheduleENetIncome)}:${columnReference(asterisk(labels.K1DistributionsNetContributions))})`;

    if (shouldHideManualRows) {
      return `=${wagesAndInterest} + ${pensionsToCapitalGains} + ${scheduleEToK1}`;
    }

    const lessInterestAndDividendIncomeFromK1sCell = columnReference(
      labels.LessInterestAndDividendIncomeFromK1s,
    );
    const lessCapitalGainsOrAddBackCapitalLossesFromK1sCell = columnReference(
      labels.LessCapitalGainsOrAddBackCapitalLossesFromK1s,
    );
    const scheduleFCashFlowCell = columnReference(labels.ScheduleFCashFlow);

    return `=${wagesAndInterest} - ${lessInterestAndDividendIncomeFromK1sCell} + ${pensionsToCapitalGains} - ${lessCapitalGainsOrAddBackCapitalLossesFromK1sCell} + ${scheduleEToK1} + ${scheduleFCashFlowCell}`;
  }

  function asterisk(header: string) {
    return shouldHideManualRows ? header : `${header} *`;
  }
}
