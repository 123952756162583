import { AnnualReviewStatusOptionEnum } from "src/Enums/AnnualReviewStatusOptionEnum";
import { LoanStatusOptionEnum } from "src/Enums/LoanStatusOptionEnum";
import { LoanPipelineTypeEnums } from "src/Enums/LoanPipelineTypeEnums";

export const annualReviewTypes = [
  { value: AnnualReviewStatusOptionEnum.OPEN_ANNUAL, label: "Open" },
  { value: AnnualReviewStatusOptionEnum.COMPLETED, label: "Completed" },
  { value: AnnualReviewStatusOptionEnum.UPCOMING, label: "Upcoming" },
] as const;

export const loanStatus = [
  { value: LoanStatusOptionEnum.OPEN, label: "Open" },
  { value: LoanStatusOptionEnum.WON, label: "Won" },
  { value: LoanStatusOptionEnum.LOST, label: "Lost" },
] as const;

export const loanPipelineTypeFilters = [
  { value: LoanPipelineTypeEnums.NEW_LOANS, label: "New Loan" },
  { value: LoanPipelineTypeEnums.ANNUAL_REVIEWS, label: "Annual Review" },
] as const;

export const loanPipelineTileTypes = [
  { value: LoanPipelineTypeEnums.NEW_LOANS, label: "Loan" },
  { value: LoanPipelineTypeEnums.ANNUAL_REVIEWS, label: "Annual Review" },
] as const;

export const loanPipelineSortOptions = {
  documentDueDate: {
    key: "documentDueDate",
    type: "date",
    label: "Document due date",
  },
  closingDate: { key: "closingDate", type: "date", label: "Closing date" },
  // TODO: add origination date to LoanDetails
  // originationDate: {
  //   key: "originationDate",
  //   type: "date",
  //   label: "Created date",
  // },
  amountCents: { key: "amountCents", type: "number", label: "Loan size" },
} as const;

export type loanPipelineSortingEnums = keyof typeof loanPipelineSortOptions;

export const lenderEmployeeTypeOptions = {
  accountOwner: {
    label: "Account Owner",
  },
  collaborator: {
    label: "Collaborator",
  },
} as const;

export const LOCAL_BANKER_ID = 1;
export const LOCAL_BANKER_ID2 = 2;
export const FCN_LENDER_ID = 3;
export const PAB_LENDER_ID = 4;
export const CBTX_LENDER_ID = 6;
export const ACB_LENDER_ID = 7;
export const VANTAGE_LENDER_ID = 8;
export const FIRST_TRUST_LENDER_ID = 9;

export const EMAIL_REGEX = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
export const YEAR_REGEX = /\b20[1-9]\d\b/;

export const JOB_RUN_HOUR_UTC = 13;
export const STRING_LENGTH_THRESHOLD = 13;
export const LOCALHOST = "http://localhost:3000";
export const FCN_URL = "https://fcn.app.interfold.ai";
export const PAB_URL = "https://pab.app.interfold.ai";
export const LNB_URL = "https://lnb.app.interfold.ai";
export const CBTX_URL = "https://cbtx.app.interfold.ai";

export const ABSOLUTE_SESSION_DURATION = 86400;

export const ZEROBYZERO = "0/0";

export const EMAIL_WINDOW_SIZE = 7;

export const AUTH0_FALLBACK_BASE_URL = process.env.AUTH0_FALLBACK_BASE_URL || LOCALHOST;

// The maximum number of characters allowed in a tab name to stay compatible with the tab name length limit in excel
export const MAX_TAB_CHARACTERS = 31;

export const AUTH0_DEV_TENTANT_CLIENT_ID = "Zsaqjtsxq48OxluBuXnr9Zyys4IwoFOq";
