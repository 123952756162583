import type * as db from "@prisma/client";
import {
  DocumentUploadStatus,
  EntityLoanRole,
  EntityType,
  LenderEmployeeAccess,
  LenderEmployeeRole,
  LoanStatus,
  ReminderStatus,
} from "@prisma/client";
import _ from "lodash";
import moment from "moment-timezone";
import pino, { Logger } from "pino";
import { Cadence, cadenceSchema } from "prisma/schema";
import { UserAnswers } from "src/contracts/intake/answer";
import {
  CreateReportingSequencePayload,
  UpdateReportingSequencePayload,
} from "src/contracts/loan/document/reporting-sequence";
import entityToDocumentMapping, {
  documentsThatCannotBeMultipleOnSameEntity,
} from "src/data/EntityToDocumentMapping";
import { AnnualReviewStatusOptionEnum } from "src/Enums/AnnualReviewStatusOptionEnum";
import { DocumentTypeEnum } from "src/Enums/DocumentTypeEnum";
import { LoanPipelineTypeEnums } from "src/Enums/LoanPipelineTypeEnums";
import { LoanStatusOptionEnum } from "src/Enums/LoanStatusOptionEnum";
import {
  countDocumentDetailsByStatus,
  DocumentDetails,
  DocumentStatus,
} from "src/models/DocumentDetails";
import { documentTypePeriodFromDbDocumentType } from "src/models/DocumentStatusFrequency";
import { EntityHydrated } from "src/models/entity";
import { LenderEmployee } from "src/models/LenderEmployee";
import { Loan, LoanDetails, ManagementTableData, MetaData } from "src/models/Loan";
import {
  AnnualReviewDto,
  GetAnnualReviewsResponse,
  ReviewEditReportingSequence,
} from "src/pages/api/annual-review/index.contracts";
import {
  DocumentRequestWithUploadsAndSchedule,
  FullEntityWithLoanRole,
} from "src/pages/api/entity/[entityId]/relationship/document-request.contracts";
import { Cents, Dollars, dollarsFromCents } from "src/utils/currency";
import { check } from "src/utils/zod";
import { baseApiService } from "src/services/api-services/BaseApiService";
import { defaultEntityObject } from "./defaultEntityObject";
import { Reminder } from "src/models/Reminder";
import { DocumentRequest } from "src/models/DocumentRequest";
import { DocumentQuality } from "src/Enums/DocumentQualityEnum";
import { convertJsonValueToRecord } from "src/utils/prisma";
import {
  CreateOrUpdateLoanRequestType,
  LoanCreateOrUpdateResponse,
  UpdateLoanRequestType,
} from "src/contracts/loan";

type GetLoansResponse = { data: Loan[] };

const collateralDocumentNames: string[] = entityToDocumentMapping.collateral.map(
  (document) => document.name,
);

export class LoanService {
  private readonly logger: Logger;

  constructor(logger: Logger) {
    this.logger = logger.child({ service: "LoanService" });
  }

  static getInstance(logger: Logger): LoanService {
    return new LoanService(logger);
  }

  async listAnnualReviews(args: {
    limit: number;
    offset: number;
    search: string;
    sortBy: string;
    sortOrder: string;
    filterByStatus: string | string[];
    filterByAccountOwner: string | string[];
  }): Promise<AnnualReviewDto[]> {
    const searchParams = new URLSearchParams();
    searchParams.append("limit", String(args.limit ?? 20));
    searchParams.append("offset", String(args.offset ?? 0));
    searchParams.append("search", args.search ?? "");
    searchParams.append("sortBy", args.sortBy ?? "dueDate");
    searchParams.append("sortOrder", args.sortOrder ?? "asc");
    if (args.filterByStatus) {
      if (Array.isArray(args.filterByStatus)) {
        args.filterByStatus.forEach((status) => {
          searchParams.append("filterByStatus", status);
        });
      } else {
        searchParams.append("filterByStatus", args.filterByStatus);
      }
    }
    if (args.filterByAccountOwner) {
      if (Array.isArray(args.filterByAccountOwner)) {
        args.filterByAccountOwner.forEach((owner) => {
          searchParams.append("filterByAccountOwner", owner);
        });
      } else {
        searchParams.append("filterByAccountOwner", args.filterByAccountOwner);
      }
    }
    const response = await baseApiService.get<GetAnnualReviewsResponse>(
      `/api/annual-review?${searchParams.toString()}`,
    );

    if ("message" in response) {
      throw new Error(response.message);
    }

    const { data: annualReviews } = response;

    if ("message" in annualReviews) {
      return [];
    }

    return annualReviews;
  }

  // Returns the contents of the pipeline page
  async list(args: {
    limit: number;
    offset: number;
    search: string;
    sortBy: string;
    sortOrder: string;
    filterByStatus: string | string[];
    filterByAccountOwner: string | string[];
  }): Promise<{
    loanDetails: LoanDetails[];
    managementDetails: ManagementTableData[];
  }> {
    const searchParams = new URLSearchParams();
    searchParams.append("limit", String(args.limit ?? 20));
    searchParams.append("offset", String(args.offset ?? 0));
    searchParams.append("search", args.search ?? "");
    searchParams.append("sortBy", args.sortBy ?? "dueDate");
    searchParams.append("sortOrder", args.sortOrder ?? "asc");
    if (args.filterByStatus) {
      if (Array.isArray(args.filterByStatus)) {
        args.filterByStatus.forEach((status) => {
          searchParams.append("filterByStatus", status);
        });
      } else {
        searchParams.append("filterByStatus", args.filterByStatus);
      }
    }
    if (args.filterByAccountOwner) {
      if (Array.isArray(args.filterByAccountOwner)) {
        args.filterByAccountOwner.forEach((owner) => {
          searchParams.append("filterByAccountOwner", owner);
        });
      } else {
        searchParams.append("filterByAccountOwner", args.filterByAccountOwner);
      }
    }
    const { data: loans } = await baseApiService.get<GetLoansResponse>(
      `/api/loan?${searchParams.toString()}`,
    );
    const loanDetailsFromLoan = loanService.convertLoanResponseToLoanCardData(loans);

    const managementData = loanService.convertLoansToManagementTableData(loans);

    return {
      loanDetails: loanDetailsFromLoan,
      managementDetails: managementData,
    };
  }

  async getLoanById(
    loanId: number,
  ): Promise<{ loan: LoanDetails; answers: UserAnswers["answers"] } | undefined> {
    const response = await baseApiService.get(`/api/loan/${loanId}`);
    if (!response) {
      console.log("Loan not found");
      return;
    }
    const loanDetail = loanService.convertLoanResponseToLoanCardData([response.loan as Loan]);

    return {
      loan: loanDetail[0],
      answers: response.answers,
    };
  }

  async modifyCustomerUser(payload: {
    loanId: number;
    emails: string[];
  }): Promise<{ loan: LoanDetails; answers: UserAnswers["answers"] } | undefined> {
    const response = await baseApiService.post(`/api/loan/${payload.loanId}/modify-customer-user`, {
      emails: payload.emails,
    });
    if (!response) {
      console.log("Loan not found");
      return;
    }
    const loanDetail = loanService.convertLoanResponseToLoanCardData([response.loan as Loan]);

    return {
      loan: loanDetail[0],
      answers: response.answers,
    };
  }

  async getAnnualReviewById(annualReviewId: number): Promise<LoanDetails | undefined> {
    const annualReview = (await baseApiService.get(`/api/annual-review/${annualReviewId}`)).data;
    if (!annualReview) {
      console.log("Review not found");
      return;
    }
    const response = convertAnnualReviewsToLoanCardData([annualReview]);

    return response[0];
  }

  async updateReminder(
    reminderId: number,
    data: { notes?: string; status?: ReminderStatus },
  ): Promise<LoanDetails | undefined> {
    const annualReview = (await baseApiService.put(`/api/reminder/${reminderId}`, { data })).data;
    if (!annualReview) {
      console.log("Review not found");
      return;
    }
    const response = convertAnnualReviewsToLoanCardData([annualReview]);

    return response[0];
  }

  async updateLoan(loanId: number, data: any): Promise<any> {
    try {
      const loan = await baseApiService.put<any, { data: Loan }>(`/api/loan/${loanId}`, data);

      const response = loanService.convertLoanResponseToLoanCardData([loan.data]);
      return response[0];
    } catch (e) {
      console.log(`Update loan failed for loanId: ${loanId} with error ${e}`);
    }
  }

  async createReportingSequence(
    loanId: number,
    data: CreateReportingSequencePayload,
  ): Promise<{ loan: LoanDetails; annualReviewId: number }> {
    const response = await baseApiService.post(`/api/loan/${loanId}/reporting-sequence`, data);

    const loans = loanService.convertLoanResponseToLoanCardData([response.data]);
    return { loan: loans[0], annualReviewId: response.annualReviewId };
  }

  async editReportingSequence(
    reviewId: number,
    data: ReviewEditReportingSequence,
  ): Promise<LoanDetails> {
    const review = await baseApiService.put(
      `/api/annual-review/${reviewId}/reporting-sequence`,
      data,
    );

    const response = convertAnnualReviewsToLoanCardData([review.data]);
    return response[0];
  }

  async deleteReportingSequence(reviewId: number): Promise<LoanDetails> {
    const review = await baseApiService.delete(`/api/annual-review/${reviewId}/reporting-sequence`);

    const response = convertAnnualReviewsToLoanCardData([review.data]);
    return response[0];
  }

  async updateReportingSequence(
    loanId: number,
    data: UpdateReportingSequencePayload,
  ): Promise<LoanDetails> {
    const review = await baseApiService.put(`/api/loan/${loanId}/reporting-sequence`, data);

    const response = convertAnnualReviewsToLoanCardData([review.data]);
    return response[0];
  }

  async createDocumentsRequest(
    loanId: number,
    entityId: number,
    documentData: {
      documentTypeId: number | null;
      documentTitle: string | null;
      documentYear: string | null;
      documentQuarter?: string;
      documentMonth?: string;
      documentQuality?: DocumentQuality;
      isReview: boolean;
      documentPeriodJson?: string;
    },
  ): Promise<
    (DocumentRequest & { isSuccessful: true }) | { isSuccessful: false; failureReason: string }
  > {
    return await baseApiService.post(`/api/loan/${loanId}/document/request`, {
      entityId,
      ...documentData,
    });
  }

  async updateDocumentStatus(payload: {
    loanId: number;
    documentId: number;
    status: DocumentUploadStatus;
    isReview?: boolean;
  }): Promise<any> {
    const loan = (
      await baseApiService.put(
        `/api/loan/${payload.loanId}/document/${payload.documentId}/update-status`,
        {
          status: payload.status,
          isReview: !!payload.isReview,
        },
      )
    ).data;

    if (!loan) {
      console.log("Loan not found");
      return;
    }
    let response;
    if (payload.isReview) {
      response = convertAnnualReviewsToLoanCardData([loan as AnnualReviewDto]);
    } else {
      response = loanService.convertLoanResponseToLoanCardData([loan as Loan]);
    }

    return response[0];
  }

  async updateMetadata(payload: {
    docOrReminderId: string;
    isReminder: boolean;
    metadata: Record<string, any>;
    annualReviewId?: number;
    loanId?: number;
  }): Promise<any> {
    const params = new URLSearchParams();
    if (payload.annualReviewId) {
      params.append("annualReviewId", String(payload.annualReviewId));
    } else if (payload.loanId) {
      params.append("loanId", String(payload.loanId));
    }
    // TODO: handle reminder call when we add support for it
    return await baseApiService.put(
      `/api/document-request/${payload.docOrReminderId}/metadata?${params.toString()}`,
      {
        ...payload.metadata,
      },
    );
  }

  async updateLoanStatus(payload: { loanId: number; status: LoanStatus }): Promise<any> {
    const loan = (
      await baseApiService.put(`/api/loan/${payload.loanId}/update-status`, {
        status: payload.status,
      })
    ).data as Loan;

    if (!loan) {
      console.log("Loan not found");
      return;
    }

    const response = loanService.convertLoanResponseToLoanCardData([loan]);
    return response[0];
  }

  async updateAnnualReviewStatus(payload: {
    annualReviewId: number;
    status: AnnualReviewStatusOptionEnum;
  }): Promise<any> {
    const annualReview = (
      await baseApiService.put(`/api/annual-review/${payload.annualReviewId}/update-status`, {
        status: payload.status,
      })
    ).data;

    if (!annualReview) {
      this.logger.info("Review not found");
      return;
    }
    const response = convertAnnualReviewsToLoanCardData([annualReview]);

    return response[0];
  }

  convertLoansToManagementTableData(input: Loan[]): ManagementTableData[] {
    const output: ManagementTableData[] = [];
    for (const loan of input) {
      let manager = loan.employees?.filter((e) => e?.access === LenderEmployeeAccess.SUPERVISOR)[0];
      if (!manager) {
        manager = loan.employees[0];
      }

      const details = loanService.getDocumentDetails(
        loan.id,
        loan.annualReviewEntity && loan.annualReviewEntity.asIndividual
          ? [{ ...loan.annualReviewEntity, role: EntityLoanRole.BORROWER }]
          : loan.entities,
        loan.annualReviewEntity && loan.annualReviewEntity.asCompany
          ? { ...loan.annualReviewEntity, role: EntityLoanRole.BORROWER }
          : undefined,
      );

      const amountDollars = dollarsFromCents(loan.amountCents);
      const loanSize = amountDollars.success ? amountDollars.data : "";

      output.push({
        accountName: loan.accountName,
        accountOwner: manager.name,
        closingDate: loan.closingDate ? moment(loan.closingDate).format("MM/DD/y") : "-",
        docDueDate: loan.documentDueDate ? moment(loan.documentDueDate).format("MM/DD/y") : "-",
        loanSize: Dollars.parse(loanSize),
        loanStatus: LoanStatusOptionEnum.OPEN,
        workFlowType: loan.workFlowType,
        status: `${details.totalDocs - details.totalIncompleteDocs}/${details.totalDocs} Docs.
        ${details.totalIncompleteDocs === 0 ? "Complete" : "Pending"}`,
      });
    }

    return output;
  }

  convertLoanResponseToLoanCardData(input: Loan[]): LoanDetails[] {
    const output: LoanDetails[] = [];
    for (const loan of input) {
      if (
        loan.entities &&
        loan.entities.length === 1 &&
        loan.entities[0].entityType === "UNKNOWN"
      ) {
        loan.entities = defaultEntityObject(loan);
      }

      let manager: LenderEmployee;

      // TODO: Remove this check
      if (loan.type === LoanPipelineTypeEnums.ANNUAL_REVIEWS) {
        manager = loan.employees[0];
      } else {
        manager = loan.employees?.filter((e) => e?.access === LenderEmployeeAccess.SUPERVISOR)[0];
        if (!manager) {
          manager = loan.employees[0];
        }
      }

      let employees: { [key: string]: string[] } = {};

      loan.employees?.forEach((e) => {
        let role;
        switch (e.role) {
          case LenderEmployeeRole.UNDERWRITER:
            role = LenderEmployeeRole.UNDERWRITER;
            break;

          case LenderEmployeeRole.CREDIT_ANALYST:
            role = LenderEmployeeRole.CREDIT_ANALYST;
            break;

          case LenderEmployeeRole.RELATIONSHIP_MANAGER:
            role = LenderEmployeeRole.RELATIONSHIP_MANAGER;
            break;

          default:
            throw new Error("Unknown Lender Employee Role");
        }
        if (e?.id !== manager.id) {
          if (employees[role]) {
            employees[role].push(e.name);
          } else {
            employees[role] = [e.name];
          }
        }
      });

      const details = loanService.getDocumentDetails(
        loan.id,
        loan.annualReviewEntity && loan.annualReviewEntity.asIndividual
          ? [{ ...loan.annualReviewEntity, role: EntityLoanRole.BORROWER }]
          : loan.entities,
        loan.annualReviewEntity && loan.annualReviewEntity.asCompany
          ? { ...loan.annualReviewEntity, role: EntityLoanRole.BORROWER }
          : undefined,
      );

      const areDocumentsCompleted = this.allDocumentsCollected(
        loan.entities.map((ent) => ent.documentRequests).flat(),
      );
      // TODO: how do we determine whether a loan is closed?
      const isLoanClosed = false;
      const loanStatus = loanService.getLoanStatus(loan, isLoanClosed, loan.annualReviewEntity);
      const timezone = moment.tz.guess();
      // TODO: move strings we're going to display directly
      //  into the appropriate React component
      const activity =
        loan.activities.length > 0
          ? `${loan.activities[0].type} by
          ${loan.activities[0].performedBy} at
          ${moment(loan.activities[0].time).tz(timezone).format("MM/DD/YYYY, hh:mm A z")}`
          : "";

      const uniqueBorrowers = details.borrowers.filter(
        (borrower, index, borrowers) => borrowers.indexOf(borrower) === index,
      );

      output.push({
        id: loan.id,
        accountName: loan.accountName,
        amountCents: loan.amountCents,
        pauseNotifications: loan.pauseNotifications,
        borrowers: uniqueBorrowers,
        guarantors: details.guarantors,
        // we are seeing duplicates here. Unclear about the root cause
        // but think getting unique users by email is reasonable.
        customerUsers: _.uniqBy(loan.customerUsers, "email"),
        lastUpdatedOn: details.lastUpdatedOn,
        activity,
        // TODO: move strings we're going to display directly
        //  into the appropriate React component
        alertMessage: `${details.totalDocs - details.totalIncompleteDocs}/${
          details.totalDocs
        } documents received`,
        verified_documents: `${details.totalDocs - details.totalNotVerifiedDocs}/${
          details.totalDocs
        } documents verified`,
        closingDate: loan.closingDate,
        documentDueDate: loan.documentDueDate,
        investment_type:
          loan.type === LoanPipelineTypeEnums.ANNUAL_REVIEWS
            ? LoanPipelineTypeEnums.ANNUAL_REVIEWS
            : LoanPipelineTypeEnums.NEW_LOANS,
        documentRequests: _.sortBy(details.documentRequests, [
          function (resultItem) {
            return resultItem.item === "Collateral";
          },
          "item",
        ]),
        areDocumentsCompleted: areDocumentsCompleted,
        isLoanClosed,
        manager,
        loan_status: loanStatus,
        workFlowType: loan.workFlowType,
        entities: _.sortBy(details.input, "role"),
        employees: loan.employees,
        notificationSequences: loan.notificationSequences,
      });
    }
    return output;
  }

  allDocumentsCollected(documentRequests: any[]) {
    if (!documentRequests.length) {
      return false;
    }

    let documentUploads: any[] = [];
    for (const doc of documentRequests) {
      for (const upload of doc.documentUpload) {
        documentUploads.push(upload);
      }
    }

    let collectedOnCount = 0;

    for (const documentUpload of documentUploads) {
      // TODO will have to add below check when the data will be correct, right now checking only for URL after discussion
      // if(documentUpload.collectedOn && documentUpload.rawUploadUrl)
      if (documentUpload.rawUploadUrl) {
        collectedOnCount++;
      }
    }

    return collectedOnCount === documentUploads.length;
  }

  getLoanStatus(loan: Loan, isLoanClosed: boolean, entity?: EntityHydrated) {
    if (loan.type === "annual_reviews") {
      let earliestStartDate = Infinity;
      if (entity && entity.role != EntityLoanRole.BORROWER) {
        if (loanService.allDocumentsCollected(entity.documentRequests)) {
          return AnnualReviewStatusOptionEnum.COMPLETED;
        }

        for (const doc of entity.documentRequests) {
          if (doc?.cadence?.periods) {
            for (const period of doc.cadence.periods) {
              if (period?.start) {
                const start = moment(period.start);
                earliestStartDate = Math.min(start.unix(), earliestStartDate);
              }
            }
          }
        }
      }

      if (earliestStartDate > moment().unix()) {
        return AnnualReviewStatusOptionEnum.UPCOMING;
      } else {
        return AnnualReviewStatusOptionEnum.OPEN_ANNUAL;
      }
    } else {
      return !!loan?.status ? loan.status : LoanStatusOptionEnum.OPEN;
    }
  }

  getDocumentDetails(
    id: number,
    input: EntityHydrated[],
    annualReviewEntity?: EntityHydrated,
  ): {
    totalDocs: number;
    totalIncompleteDocs: number;
    borrowers: string[];
    guarantors: string[];
    ownedByGuarantors: string[];
    documentRequests: DocumentDetails[];
    lastUpdatedOn: string | undefined;
    input: EntityHydrated[];
    totalNotVerifiedDocs: number;
  } {
    const borrowers = [];
    const guarantors = [];
    const ownedByGuarantors = [];
    // The last time any of the entity's documents were
    // either requested or uploaded.
    let anyEntityLastUpdatedOn: moment.Moment | undefined = undefined;
    const documentRequests: DocumentDetails[] = [];
    const collateralDocuments: DocumentStatus[] = [];
    let collateralCompleted = true;

    for (const entity of input) {
      let individualDocsCompleted = true;
      let docType: DocumentTypeEnum;
      const docStatus: DocumentStatus[] = [];

      for (const doc of entity.documentRequests ?? []) {
        let currentDRLastCollectedOn: moment.Moment | undefined = undefined;
        let currentDRLastRawUploadUrl = null;
        let currentDRLastDocumentUploadId = null;
        let currentDRLastDocumentUploadStatus: DocumentUploadStatus = "" as DocumentUploadStatus;
        const currentDRIsCollateral = collateralDocumentNames.indexOf(doc.type.name) > -1;

        for (const upload of doc.documentUpload) {
          if (upload.requestedOn) {
            const uploadRequestedOn = moment(upload.requestedOn);
            anyEntityLastUpdatedOn = moment.max(
              uploadRequestedOn,
              anyEntityLastUpdatedOn ?? uploadRequestedOn,
            );
          }
          if (upload.collectedOn) {
            const uploadCollectedOn = moment(upload.collectedOn);
            anyEntityLastUpdatedOn = moment.max(
              uploadCollectedOn,
              anyEntityLastUpdatedOn ?? uploadCollectedOn,
            );
            currentDRLastCollectedOn = moment.max(
              uploadCollectedOn,
              currentDRLastCollectedOn ?? uploadCollectedOn,
            );
          }
          if (upload.rawUploadUrl) {
            currentDRLastRawUploadUrl = upload.rawUploadUrl;
          }
          currentDRLastDocumentUploadId = upload.id;
          currentDRLastDocumentUploadStatus = upload.status as DocumentUploadStatus;
        }

        if (!currentDRIsCollateral && !currentDRLastRawUploadUrl) {
          individualDocsCompleted = false;
        } else if (currentDRIsCollateral && !currentDRLastRawUploadUrl) {
          collateralCompleted = false;
        }

        const index = docStatus.findIndex(
          (d) => d.docType === doc?.type?.name && doc?.type?.name !== "Custom",
        );
        const collateralIndex = collateralDocuments.findIndex((d) => d.docType === doc?.type?.name);
        if (currentDRIsCollateral && collateralIndex != -1 && index !== -1) {
          if (!currentDRLastRawUploadUrl) {
            collateralDocuments[index].incompleteDocs = 1;
          }
          collateralDocuments[index].frequencies.push({
            documentRequestId: doc.id,
            documentUploadId: currentDRLastDocumentUploadId!,
            rawDocumentUrl: currentDRLastRawUploadUrl,
            year: doc?.type?.year,
            month: doc?.type?.month,
            quarter: doc?.type?.quarter,
            lastUploadedOn: currentDRLastCollectedOn?.format("YYYY-MM-DD hh:mm:ss"),
            status: currentDRLastDocumentUploadStatus,
            metadata: doc.metadata,
          });
        } else if (currentDRIsCollateral) {
          const displayName =
            doc?.type?.displayName ??
            [doc.type.month || doc.type.quarter, doc.type.year, doc.type.name].join(" ");
          collateralDocuments.push({
            docType: doc.type?.name,
            displayName,
            incompleteDocs: currentDRLastRawUploadUrl ? 0 : 1,
            documentRequestId: String(doc.id),
            notes: doc.notes,
            frequencies: [
              {
                documentRequestId: doc.id,
                documentUploadId: currentDRLastDocumentUploadId!,
                rawDocumentUrl: currentDRLastRawUploadUrl,
                year: doc?.type?.year,
                month: doc?.type?.month,
                quarter: doc?.type?.quarter,
                lastUploadedOn: currentDRLastCollectedOn?.format("YYYY-MM-DD hh:mm:ss"),
                status: currentDRLastDocumentUploadStatus,
                metadata: doc.metadata,
              },
            ],
            cadence: doc.cadence,
            attributes: doc.type,
          });
        } else if (index !== -1) {
          if (!currentDRLastRawUploadUrl) {
            docStatus[index].incompleteDocs = 1;
          }
          docStatus[index].frequencies.push({
            documentRequestId: doc.id,
            documentUploadId: currentDRLastDocumentUploadId!,
            rawDocumentUrl: currentDRLastRawUploadUrl,
            year: doc?.type?.year,
            month: doc?.type?.month,
            quarter: doc?.type?.quarter,
            lastUploadedOn: currentDRLastCollectedOn?.format("YYYY-MM-DD hh:mm:ss"),
            status: currentDRLastDocumentUploadStatus,
            metadata: doc.metadata,
          });
        } else {
          const displayName =
            doc?.type?.displayName ??
            [doc.type.month || doc.type.quarter, doc.type.year, doc.type.name].join(" ");
          docStatus.push({
            docType: doc?.type?.name,
            displayName,
            incompleteDocs: currentDRLastRawUploadUrl ? 0 : 1,
            documentRequestId: String(doc.id),
            notes: doc.notes,
            frequencies: [
              {
                documentRequestId: doc.id,
                documentUploadId: currentDRLastDocumentUploadId!,
                rawDocumentUrl: currentDRLastRawUploadUrl,
                year: doc?.type?.year,
                month: doc?.type?.month,
                quarter: doc?.type?.quarter,
                lastUploadedOn: currentDRLastCollectedOn?.format("YYYY-MM-DD hh:mm:ss"),
                status: currentDRLastDocumentUploadStatus,
                metadata: doc.metadata,
              },
            ],
            cadence: doc.cadence,
            attributes: doc.type,
          });
        }
      }

      const entityObj =
        annualReviewEntity && annualReviewEntity.asCompany ? annualReviewEntity : entity;

      switch (entityObj.role) {
        case EntityLoanRole.BORROWER:
          docType = DocumentTypeEnum.BORROWER;
          if (entityObj.asCompany) {
            borrowers.push(entityObj.asCompany.name);
          } else if (entityObj.asIndividual) {
            borrowers.push(entityObj.asIndividual.name);
          }
          break;

        case EntityLoanRole.GUARANTOR:
          docType = DocumentTypeEnum.GUARANTOR;
          entityObj.asIndividual ? guarantors.push(`${entityObj.asIndividual.name}`) : undefined;
          break;

        case EntityLoanRole.OWNED_BY_GUARANTOR:
          docType = DocumentTypeEnum.OWNED_BY_GUARANTOR;
          if (entityObj.asCompany) {
            ownedByGuarantors.push(entityObj.asCompany.name);
          } else if (entityObj.asIndividual) {
            ownedByGuarantors.push(entityObj.asIndividual.name);
          }
          break;

        default:
          console.warn("Unknown entity role", entityObj.role);
          continue;
      }

      documentRequests.push({
        id: "1",
        item: docType,
        entityType: entity?.entityType ?? "UNKNOWN",
        entityName:
          (entity?.entityType === EntityType.COMPANY
            ? entity?.asCompany?.name
            : entity?.asIndividual?.name) ?? "Pending Account Creation",
        completed: individualDocsCompleted,
        docStatus,
        entityId: entity.id,
      });
    }

    if (collateralDocuments.length !== 0) {
      const collateralRequestGroup: DocumentDetails = {
        id: "1",
        item: DocumentTypeEnum.COLLATERAL,
        completed: collateralCompleted,
        docStatus: _.uniqBy(collateralDocuments, "docType"),
      };
      const borrowerEntityId = input.find((entity) => entity.role === EntityLoanRole.BORROWER)?.id;
      if (borrowerEntityId) {
        collateralRequestGroup.entityId = borrowerEntityId;
      }
      documentRequests.push(collateralRequestGroup);
    }

    const { totalCurrentlyRequestedDocs, totalIncompleteDocs, totalNotVerifiedDocs } =
      countDocumentDetailsByStatus(documentRequests);

    return {
      totalDocs: totalCurrentlyRequestedDocs,
      totalIncompleteDocs,
      borrowers,
      guarantors,
      ownedByGuarantors,
      documentRequests,
      lastUpdatedOn: anyEntityLastUpdatedOn?.format("hh:mm A, MM/DD/YY"),
      input,
      totalNotVerifiedDocs,
    };
  }
}

export const findEarliestRequestDate = (documentStatus: DocumentStatus[]): moment.Moment => {
  return documentStatus.reduce((minStartDate, status) => {
    const startDate = moment(status?.cadence?.periods?.[0]?.start);
    return startDate.isBefore(minStartDate) ? startDate : minStartDate;
  }, moment(documentStatus[0]?.cadence?.periods?.[0]?.start));
};

export const convertAnnualReviewsToLoanCardData = (
  annualReviews: AnnualReviewDto[],
): LoanDetails[] => {
  // This redundant variable makes type errors less obnoxious
  // noinspection UnnecessaryLocalVariableJS
  const result = annualReviews.map((annualReview) => {
    const documentDetails = getDocumentDetails(
      annualReview.entities,
      annualReview.workflow.map((wf) => wf.documentRequest),
    );
    const allDocumentStatuses = documentDetails
      .flatMap((dr) => dr.docStatus)
      .filter((dr) => dr !== undefined) as DocumentStatus[];

    const { totalCurrentlyRequestedDocs, totalIncompleteDocs, totalNotVerifiedDocs } =
      countDocumentDetailsByStatus(documentDetails);
    const customerUsers = annualReview.entities
      .map((entity) => {
        return entity.entity.customerUserContacts.map((customerUserContacts) => {
          return {
            id: customerUserContacts.customerUser.internalId,
            name: customerUserContacts.customerUser.name,
            email: customerUserContacts.customerUser.email,
            role: customerUserContacts.role,
            entityId: entity.entityId,
            attributes: customerUserContacts.customerUser.attributes,
          };
        });
      })
      .flat();

    const earliestRequestDate = findEarliestRequestDate(allDocumentStatuses);
    let status: string;

    if (annualReview.closedOn) {
      status = AnnualReviewStatusOptionEnum.COMPLETED;
    } else {
      if (moment().isBefore(earliestRequestDate)) {
        status = AnnualReviewStatusOptionEnum.UPCOMING;
      } else {
        status = AnnualReviewStatusOptionEnum.OPEN_ANNUAL;
      }
    }

    let totalReminders = annualReview.reminder?.length || 0;
    let totalRemindersCompleted = 0;
    totalRemindersCompleted +=
      annualReview.reminder?.filter((reminder) => reminder.status === ReminderStatus.COMPLETED)
        .length || 0;

    return {
      id: annualReview.id,
      pauseNotifications: annualReview.pauseNotifications,
      investment_type: LoanPipelineTypeEnums.ANNUAL_REVIEWS,
      accountName: annualReview.entities
        .filter((entity) => entity.role === EntityLoanRole.BORROWER)
        .map((entity) => getEntityName(entity.entity))
        .join(", "),
      borrowers: annualReview.entities
        .filter((entity) => entity.role === EntityLoanRole.BORROWER)
        .map((entity) => getEntityName(entity.entity)),
      guarantors: annualReview.entities
        .filter((entity) => entity.role === EntityLoanRole.GUARANTOR)
        .map((entity) => getEntityName(entity.entity)),
      customerUsers: _.uniqBy(customerUsers, "email"),
      amountCents: Cents.parse(0),
      lastUpdatedOn: getLastUpdatedOn(annualReview)?.format("hh:mm A, MM/DD/YY"),
      alertMessage:
        status === AnnualReviewStatusOptionEnum.UPCOMING
          ? `${totalCurrentlyRequestedDocs} docs scheduled (future)`
          : `${
              totalCurrentlyRequestedDocs - totalIncompleteDocs
            }/${totalCurrentlyRequestedDocs} documents received`,
      reminderMessage: `${totalRemindersCompleted}/${totalReminders} reminders completed`,
      activity: "",
      closingDate: annualReview.endDate.toString(),
      areDocumentsCompleted: totalIncompleteDocs === 0,
      isLoanClosed: annualReview.closedOn !== null,
      manager: getLenderEmployeeAccountOwnerForAnnualReview(annualReview),
      employees: getLenderEmployeesForAnnualReview(annualReview),
      documentDueDate: getDocumentDueDateForAnnualReview(
        annualReview,
        annualReview.selfAnnualReviewWorkflows
          ? annualReview.selfAnnualReviewWorkflows.map((wf) => wf.id)
          : [],
      ),
      documentRequests: documentDetails,
      loan_status: status,
      workFlowType: "Review",
      verified_documents: `${
        totalCurrentlyRequestedDocs - totalNotVerifiedDocs
      }/${totalCurrentlyRequestedDocs} documents verified`,
      entities: _.sortBy(
        annualReview.entities.map((entity) => {
          return {
            id: entity.entityId,
            role: entity.role,
            entityType: entity.entity.entityType,
            asIndividual: entity.entity.asIndividual || undefined,
            asCompany: entity.entity.asCompany || undefined,
            customerUserContacts: entity.entity.customerUserContacts,
            documentRequests: annualReview.workflow
              .filter((wf) => {
                return wf.documentRequest.entityId === entity.entityId;
              })
              .map((wf) => {
                return {
                  ...wf.documentRequest,
                  documentRequestId: String(wf.documentRequest.id),
                  notes: wf.documentRequest.notes ?? undefined,
                  reportingSequence: wf.documentRequest.reportingSequenceId,
                  documentUpload: wf.documentRequest.DocumentUpload.map((du) => {
                    return {
                      ...du,
                      requestedOn: du.requestedOn.toString(),
                      collectedOn: du.collectedOn?.toString() ?? null,
                      collectionFailedOn: du.collectionFailedOn?.toString() ?? null,
                    };
                  }),
                };
              }) as unknown as DocumentRequest[],
            reminders: annualReview?.reminder
              ? (annualReview.reminder.filter((rem) => {
                  return rem.entityId === entity.entityId;
                }) as unknown as Reminder[])
              : undefined,
          };
        }),
        "role",
      ),
      notificationSequences: annualReview.notificationSequences,
      metaData: annualReview.metadata as MetaData,
    } satisfies LoanDetails;
  });

  return result;
};

const getLenderEmployeeAccountOwnerForAnnualReview = (
  annualReview: AnnualReviewDto,
): LenderEmployee => {
  const lenderEmployees = annualReview.AnnualReviewLenderEmployee.map((arle) => {
    return {
      ...arle.lenderEmployee,
      isAccountOwner: arle.isAccountOwner,
    };
  })
    .map((le) => {
      if (!le.name) {
        return null;
      }
      return {
        id: le.id,
        employerId: le.employerId,
        name: le.name,
        role: le.role,
        access: le.access,
        isAccountOwner: le.isAccountOwner,
      };
    })
    .filter((x): x is LenderEmployee => x !== null);

  return lenderEmployees.find((x) => x.isAccountOwner) || lenderEmployees[0];
};

const getLenderEmployeesForAnnualReview = (annualReview: AnnualReviewDto): LenderEmployee[] => {
  return annualReview.AnnualReviewLenderEmployee.map((arle) => {
    return {
      ...arle.lenderEmployee,
      isAccountOwner: arle.isAccountOwner,
    };
  })
    .map((le) => {
      if (!le.name) {
        return null;
      }
      return {
        id: le.id,
        employerId: le.employerId,
        name: le.name,
        role: le.role,
        access: le.access,
        isAccountOwner: le.isAccountOwner,
      };
    })
    .filter((x): x is LenderEmployee => x !== null);
};

const getLastUpdatedOn = (annualReview: AnnualReviewDto): moment.Moment | undefined => {
  const lastUpdatedOn = _.chain(annualReview.workflow)
    .map((wf) => wf.documentRequest)
    .flatMap((dr) => dr.DocumentUpload)
    .map((du) => du.collectedOn ?? du.requestedOn)
    .filter((x) => x !== null)
    .max()
    .value();

  return lastUpdatedOn ? moment(lastUpdatedOn) : undefined;
};

const genDocumentStatusesForAnnualReview = (
  documentRequests: (db.DocumentRequest & {
    type: db.DocumentType & any;
    DocumentUpload: db.DocumentUpload[];
  })[],
  documentTypeName: string,
  documentRequestId?: any,
): DocumentStatus => {
  const drWithHighestEndDate = documentRequests.reduce((latest, current) => {
    if (!current.cadence || !(current.cadence as any).end) {
      return latest;
    }

    const currentEndDate = moment((current.cadence as any).end);
    if (!latest || currentEndDate.isAfter(moment((latest.cadence as any).end))) {
      return current;
    }

    return latest;
  }, documentRequests[0]);

  const documentStatus: DocumentStatus = {
    senderId: documentRequests?.[0]?.type?.from,
    borrowerContactIds: documentRequests?.[0]?.type?.to,
    loanNumbers: documentRequests?.[0]?.type?.loanNumbers,
    attributes: documentRequests?.[0]?.type,
    docType: documentsThatCannotBeMultipleOnSameEntity.includes(documentRequests?.[0]?.type?.name)
      ? documentTypeName
      : documentRequests?.[0]?.type?.name,
    incompleteDocs: (() => {
      let incompleteDocs = 0;
      documentRequests.forEach((dr) => {
        const latestDocumentUpload = _.last(_.sortBy(dr.DocumentUpload, "id"));
        if (!latestDocumentUpload?.rawUploadUrl) {
          incompleteDocs++;
        }
      });
      return incompleteDocs;
    })(),
    cadence: drWithHighestEndDate!.cadence as unknown as Cadence,
    frequencies: documentRequests.map((dr) => {
      const uploadDates = dr.DocumentUpload.map((u) =>
        u.collectedOn ? moment(u.collectedOn) : null,
      ).filter((x) => x !== null) as moment.Moment[];
      const latestDocumentUpload = _.last(_.sortBy(dr.DocumentUpload, "id")) || {
        id: -1,
        rawUploadUrl: "",
        status: DocumentUploadStatus.REQUESTED,
      };
      return {
        ...documentTypePeriodFromDbDocumentType(dr.type),
        lastUploadedOn:
          uploadDates.length === 0
            ? undefined
            : moment.max(uploadDates).format("YYYY-MM-DD hh:mm:ss"),
        documentUploadId: latestDocumentUpload.id,
        rawDocumentUrl: latestDocumentUpload.rawUploadUrl || "",
        status: latestDocumentUpload.status,
        masterLeaseNumber: dr?.type?.masterLeaseNumber || null,
        documentRequestId: dr.id,
        metadata: convertJsonValueToRecord(dr.metadata),
      };
    }),
  };

  // Include "id" and "notes" on the same level as "incompleteDocs"
  if (documentRequests[0]?.id) {
    documentStatus.documentRequestId = String(documentRequests[0].id);
  }

  // Include "notes" whether or not it is null
  documentStatus.notes = documentRequests[0]?.notes;

  return documentStatus;
};

// Transform a set of backend entities,
// and backend document requests that belong to those entities,
// into a DocumentDetails[] for display in an accordion.
export const getDocumentDetails = (
  relationship: FullEntityWithLoanRole[],
  documentRequests: DocumentRequestWithUploadsAndSchedule[],
): DocumentDetails[] => {
  return _.sortBy(
    relationship
      .filter((re) => {
        // If entity role is owner_of_guarantor, skip
        return re.role !== EntityLoanRole.OWNER_OF_GUARANTOR;
      })
      .map((requestEntity) => {
        // Get all documentRequests that relate to the entity
        const entityDocuments = documentRequests.filter(
          (dr) => dr.entityId === requestEntity.entityId,
        );
        // All of this entity's document requests are completed
        // if _none_ of them is _not_ completed (sorry about this)
        const completed = entityDocuments.every((dr) => {
          const latestDocumentUpload = _.last(_.sortBy(dr.DocumentUpload, "id"));
          const hasNotSuccessfullyUploaded =
            latestDocumentUpload?.status === DocumentUploadStatus.REQUESTED ||
            latestDocumentUpload?.status === DocumentUploadStatus.COLLECTION_FAILED;

          return !hasNotSuccessfullyUploaded;
        });
        return {
          id: requestEntity.entityId.toString(),
          completed: completed,
          item:
            requestEntity.role === EntityLoanRole.BORROWER
              ? DocumentTypeEnum.BORROWER
              : DocumentTypeEnum.GUARANTOR,
          entityId: requestEntity.entityId,
          entityType: requestEntity.entity.entityType,
          entityName: getEntityName(requestEntity.entity),
          docStatus: [
            ..._.chain(entityDocuments.filter((doc) => !!doc.type.name))
              .map((doc) => genDocumentStatusesForAnnualReview([doc], doc.type.name, doc.id))
              .value(),
          ],
        } satisfies DocumentDetails;
      }),
    "item",
  );
};

const getDocumentDueDateForAnnualReview = (
  annualReview: AnnualReviewDto,
  selfAnnualReviewWorkflowIds: number[],
) => {
  const endDates: moment.Moment[] = annualReview.workflow
    .filter((w) =>
      selfAnnualReviewWorkflowIds.length ? selfAnnualReviewWorkflowIds.includes(w.id) : true,
    )
    .map((w) => w.documentRequest)
    .map((dr) => (check(cadenceSchema, dr.cadence) ? moment(dr.cadence.end) : undefined))
    .filter((date): date is moment.Moment => date !== undefined);
  const date = endDates.length > 0 ? moment.max(endDates) : undefined;
  return date ? date.format("MM/DD/YY") : "";
};

interface NamedEntity {
  asIndividual?: db.IndividualEntity | null;
  asCompany?: db.CompanyEntity | null;
}

// This is a little wrapper over<br>
// `entity.asCompany?.name ?? entity.asIndividual?.name ?? ""`.
export const getEntityName = (entity: NamedEntity): string => {
  return entity.asCompany?.name ?? entity.asIndividual?.name ?? "";
};

export async function createNewLoan(
  loanData: CreateOrUpdateLoanRequestType,
): Promise<LoanCreateOrUpdateResponse> {
  return await baseApiService.post("/api/loan", loanData);
}

export async function updateExistingLoan(
  loanData: UpdateLoanRequestType,
): Promise<LoanCreateOrUpdateResponse> {
  return await baseApiService.patch(`/api/loan/${loanData.loanId}`, loanData);
}

export async function getDraftLoansByLenderEmployeeId(lenderEmployeeId: number): Promise<Loan[]> {
  const searchParams = new URLSearchParams();
  searchParams.append("filterByAccountOwner", lenderEmployeeId.toString());
  searchParams.append("filterByStatus", LoanStatus.DRAFT);
  const results = await baseApiService.get<GetLoansResponse>(
    `/api/loan?${searchParams.toString()}`,
  );

  return results.data;
}

export const loanService = LoanService.getInstance(pino());
