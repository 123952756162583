import { RawCellContent } from "hyperformula";
import { AutoRendered, AutoRenderedRow } from "../AutoRendered";
import { RenderableBase } from "../RenderedDoc";

interface Labels {
  [key: string]: string;
}

export type StartAt = "beginning" | "end";

/**
 * AutoRenderedSheetBuilder - A class for building a rendered document's body with an `addRow` function.
 *
 * The `addRow` function allows you to add a row to the body and returns the row number. This row number can be used to reference the added row,
 * which is particularly useful for building formulas or other data-dependent calculations.
 */

export class AutoRenderedSheetBuilder<
  T extends RenderableBase,
  L extends Labels,
> extends AutoRendered<T> {
  body: AutoRenderedRow[] = [];
  private _highlightedRowLabels: string[] = [];
  private _percentageRowLabels: string[] = [];

  constructor(
    private data: T,
    private labels: L,
    public startingRow: number,
    public columnId: string,
  ) {
    super(data, columnId, startingRow);
  }

  /**
   * Finds the row index by header.
   * @param {string} header - The header to search for.
   * @returns {number} - The row number if found, otherwise -1.
   */
  findRowIndex(header: string, startAt: StartAt = "beginning"): number {
    const index =
      startAt === "beginning"
        ? this.body.findIndex((row) => row[0] === header) + 1
        : this.body.findLastIndex((row) => row[0] === header) + 1;
    if (index === 0) {
      throw new Error(`Row for ${header} not found.`);
    }
    return index + this.startingRow;
  }

  /**
   * Returns a reference in a specific column for a given header.
   * @param {string} header - The header to reference.
   * @returns {string} - The reference in the column.
   */
  referenceInColumn(header: string, startAt: StartAt = "beginning"): string {
    const rowIndex = this.findRowIndex(header, startAt);
    return `${this.columnId}${rowIndex}`;
  }

  /**
   * Adds a row to the body.
   *
   * If no rowBuilder is provided, an empty row will be added.
   * If no rowDataType is provided, it will be calculated based on the row's values.
   *
   * @param {Function} rowBuilder - A function that returns an array representing a row.
   * @param {string} rowDataType - The type of data in the row, either "text" or "number".
   * @param {"highlighted" | "normal"} highlightStatus - Whether the row should be highlighted.
   * @returns {AutoRenderedSheetBuilder} - The current instance of the AutoRenderedSheetBuilder.
   */
  addRow(
    rowBuilder: ({
      data,
      labels,
      columnReference,
      rowNumber,
    }: {
      data: T;
      labels: L;
      columnReference: (header: string, startAt?: StartAt) => string;
      rowNumber: number;
    }) => AutoRenderedRow = () => [""],
    rowDataType: "text" | "number" | undefined = undefined,
    highlightStatus: "highlighted" | "normal" = "normal",
  ): AutoRenderedSheetBuilder<T, L> {
    const boundReferenceInColumn = this.referenceInColumn.bind(this);

    const rowNumber = this.body.length + 1;
    const rowIndex = this.body.length;
    const row = rowBuilder({
      data: this.data,
      labels: this.labels,
      columnReference: boundReferenceInColumn,
      rowNumber,
    });
    row.rowDataType = rowDataType;

    this.body.push(row);
    if (highlightStatus === "highlighted") {
      this.highlightedRowIndexes = [...this.highlightedRowIndexes, rowIndex];
    }
    return this;
  }

  asColumns(): RawCellContent[][] {
    return this.body;
  }

  set highlightedRowLabels(labels: L[]) {
    this._highlightedRowLabels = labels.map((label) => Object.values(label)[0]);
  }

  get highlightedRowLabels(): string[] {
    return this._highlightedRowLabels;
  }

  get percentageRowLabels(): string[] {
    return this._percentageRowLabels;
  }
}
