import { Form1120S } from "src/interfaces/TaxFormData/Form1120s";
import { AutoRenderedSheetBuilder } from "../AutoRenderedSheetBuilder";

export const Form1120sIncomeStatementRowHeadings = {
  GrossRevenues: "Gross revenues",
  LessReturnsAndAllowances: "Less: Returns and allowances",
  NetRevenues: "Net revenues",
  LessCostOfGoodsSold: "Less: Cost of goods sold",
  GrossProfit: "Gross profit",
  NetGainOrLossFromForm4797: "Net gain or (loss) from form 4797, part II, line 17",
  OtherIncome: "Other income",
  TotalIncome: "Total income",
  CompensationOfOfficers: "Compensation of officers",
  SalariesAndWages: "Salaries and wages (less employment credits)",
  RepairsAndMaintenance: "Repairs and maintenance",
  BadDebts: "Bad debts",
  Rents: "Rents",
  TaxesAndLicenses: "Taxes and licenses",
  InterestExpense: "Interest expense",
  Depreciation: "Depreciation from form 4562 not claimed on form 1125-A or elsewhere",
  Depletion: "Depletion",
  Advertising: "Advertising",
  PensionAndProfitSharingPlans: "Pension, profit-sharing, etc., plans",
  EmployeeBenefitPrograms: "Employee benefit programs",
  EnergyEfficientCommercialBuildingsDeduction: "Energy efficient commercial buildings deduction",
  OtherDeductions: "Other deductions",
  TotalExpensesAndDeductions: "Total expenses and deductions",
  PreTaxProfitOrLoss: "Pre-tax profit or loss",
  TotalTax: "Total tax",
  NetIncome: "Net income",
};

export function build1120sIncomeStatement(
  data: Form1120S,
  startingRow: number,
  startingColumn: string,
): AutoRenderedSheetBuilder<Form1120S, typeof Form1120sIncomeStatementRowHeadings> {
  return new AutoRenderedSheetBuilder(
    data,
    Form1120sIncomeStatementRowHeadings,
    startingRow,
    startingColumn,
  )
    .addRow(({ data, labels }) => [labels.GrossRevenues, data.income?.grossRevenue ?? 0])
    .addRow(({ data, labels }) => [
      labels.LessReturnsAndAllowances,
      data.income?.lessReturnsAndAllowances ?? 0,
    ])
    .addRow(({ labels, columnReference }) => [
      labels.NetRevenues,
      `=${columnReference(labels.GrossRevenues)} - ${columnReference(labels.LessReturnsAndAllowances)}`,
    ])
    .addRow(({ data, labels }) => [
      labels.LessCostOfGoodsSold,
      data.income?.lessCostOfGoodsSold ?? 0,
    ])
    .addRow(({ labels, columnReference }) => [
      labels.GrossProfit,
      `=${columnReference(labels.NetRevenues)} - ${columnReference(labels.LessCostOfGoodsSold)}`,
    ])
    .addRow(({ data, labels }) => [
      labels.NetGainOrLossFromForm4797,
      data.income?.netGainOrLossFromForm4797 ?? 0,
    ])
    .addRow(({ data, labels }) => [labels.OtherIncome, data.income?.otherIncome ?? 0])
    .addRow(({ labels, columnReference }) => [
      labels.TotalIncome,
      `=SUM(${columnReference(labels.GrossProfit)}:${columnReference(labels.OtherIncome)})`,
    ])
    .addRow()
    .addRow(({ data, labels }) => [
      labels.CompensationOfOfficers,
      data.deductions?.compensationOfOfficers ?? 0,
    ])
    .addRow(({ data, labels }) => [labels.SalariesAndWages, data.deductions?.salariesAndWages ?? 0])
    .addRow(({ data, labels }) => [
      labels.RepairsAndMaintenance,
      data.deductions?.repairsAndMaintenance ?? 0,
    ])
    .addRow(({ data, labels }) => [labels.BadDebts, data.deductions?.badDebts ?? 0])
    .addRow(({ data, labels }) => [labels.Rents, data.deductions?.rents ?? 0])
    .addRow(({ data, labels }) => [labels.TaxesAndLicenses, data.deductions?.taxesAndLicenses ?? 0])
    .addRow(({ data, labels }) => [labels.InterestExpense, data.deductions?.interest ?? 0])
    .addRow(({ data, labels }) => [labels.Depreciation, data.deductions?.depreciation ?? 0])
    .addRow(({ data, labels }) => [labels.Depletion, data.deductions?.depletion ?? 0])
    .addRow(({ data, labels }) => [labels.Advertising, data.deductions?.advertising ?? 0])
    .addRow(({ data, labels }) => [
      labels.PensionAndProfitSharingPlans,
      data.deductions?.pensionAndProfitSharingPlans ?? 0,
    ])
    .addRow(({ data, labels }) => [
      labels.EmployeeBenefitPrograms,
      data.deductions?.employeeBenefitPrograms ?? 0,
    ])
    .addRow(({ data, labels }) => [
      labels.EnergyEfficientCommercialBuildingsDeduction,
      data.deductions?.energyEfficientCommercialBuildingsDeduction ?? 0,
    ])
    .addRow(({ data, labels }) => [labels.OtherDeductions, data.deductions?.otherDeductions ?? 0])
    .addRow(({ labels, columnReference }) => [
      labels.TotalExpensesAndDeductions,
      `=SUM(${columnReference(labels.CompensationOfOfficers)}:${columnReference(labels.OtherDeductions)})`,
    ])
    .addRow(({ labels, columnReference }) => [
      labels.PreTaxProfitOrLoss,
      `=${columnReference(labels.TotalIncome)} - ${columnReference(labels.TotalExpensesAndDeductions)}`,
    ])
    .addRow(({ data, labels }) => [labels.TotalTax, data.totalTax ?? 0])
    .addRow(({ labels, columnReference }) => [
      labels.NetIncome,
      `=${columnReference(labels.PreTaxProfitOrLoss)} - ${columnReference(labels.TotalTax)}`,
    ]);
}
