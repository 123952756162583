import { HoverCard } from "@mantine/core";
import { CustomCellRendererProps } from "ag-grid-react";
import { CellValue, DetailedCellError, RawCellContent, SimpleCellAddress } from "hyperformula";
import moment from "moment";
import React from "react";
import { RowDataType, RowMetadata, RowStyle } from "src/classes/GridState";
import { excelColToColNumber, HoverInfo, HoverLabel } from "src/classes/RenderedDoc";
import { TabName } from "src/redux/reducers/types";
import { CodeHighlightTabs } from "@mantine/code-highlight";

export interface HFCellRenderContext {
  editing: boolean;
  tab: TabName;
  headers: string[];
  currentCol: string | null;
  currentRow: number | null;
  sheetId: number;
  rawSheetData: Record<string, RawCellContent[][]>;
  calculatedSheetData: Record<string, CellValue[][]>;
  hoverInfoData: Record<string, HoverInfo[][]>;
}

export const HFCellRenderer = (props: CustomCellRendererProps<any, any, HFCellRenderContext>) => {
  const { context, column, node } = props;
  const {
    sheetId,
    tab,
    rawSheetData,
    calculatedSheetData: sheetData,
    hoverInfoData,
  } = context as HFCellRenderContext;

  const rowIdx = node.rowIndex;
  const colId = column?.getColId() as string;
  const colIdx = excelColToColNumber(colId);
  const cellData: GridCellObject | undefined = node.data[colId];

  if (!cellData) {
    return null;
  }

  const hoverInfo = !rowIdx
    ? { type: "auto" }
    : (hoverInfoData?.[tab]?.[rowIdx]?.[colIdx] ?? { type: "auto" });

  const cellAddress: SimpleCellAddress = {
    sheet: sheetId,
    col: colIdx,
    row: rowIdx || 0,
  };

  const { formula, value } = {
    // formula: cellData.value,
    // formula: rawSheetData[tab][cellAddress.row][colIdx],
    formula: rawSheetData?.[tab]?.[cellAddress.row]?.[colIdx],
    value: sheetData?.[tab]?.[cellAddress.row]?.[colIdx],
  };

  const cellDataType = cellData?.type;

  const startsWithEquals = typeof formula === "string" && formula.startsWith("=");

  const _toRender = cellDataType === "date" || !startsWithEquals ? formula : value;
  const toRender =
    _toRender === undefined
      ? ""
      : _toRender instanceof Date
        ? moment(_toRender).format("MM-DD-YYYY")
        : _toRender;
  const displayValue = getDisplayValue(toRender, cellDataType);

  const rowNumberForDisplay = typeof rowIdx === "number" && rowIdx + 1; // remove null

  if (colId === "A") {
    // Adjusted for the column naming convention
    return (
      <div style={{ position: "relative" }}>
        <span
          style={{
            position: "absolute",
            left: "-0.5em",
            top: "50%",
            transform: "translateY(-50%)",
          }}
          className="text-xs font-light text-gray-600"
        >
          {rowNumberForDisplay}
        </span>
        <span id={`hf-value-${rowIdx}-${colId}`} className="text-base text-gray-900 ml-5">
          {displayValue}
        </span>
      </div>
    );
  }

  return (
    <HoverCard
      openDelay={250}
      styles={{
        dropdown: {
          width: "auto",
          padding: "8px",
          borderRadius: "8px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <HoverCard.Target>
        <span
          id={`hf-value-${rowIdx}-${colId}`}
          className="text-base text-gray-900 ml-5 w-full text-right"
        >
          {displayValue}
        </span>
      </HoverCard.Target>
      {
        /* disabled for now */ (false &&
          ((hoverInfo.type == "auto" && startsWithEquals) || hoverInfo.type == "formula") && (
            <HoverCard.Dropdown
              style={{
                backgroundColor: "#ffffff",
                border: "1px solid #e2e8f0",
                borderRadius: "8px",
              }}
            >
              <CodeHighlightTabs
                copyLabel="Copy formula"
                code={[
                  {
                    fileName: "xls",
                    language: "excel",
                    code: `${formula?.toString() ?? ""}`,
                  },
                ]}
              >
                {formula?.toString()}
              </CodeHighlightTabs>
            </HoverCard.Dropdown>
          )) ||
          (hoverInfo.type === "text" && (
            <HoverCard.Dropdown>
              <span>{(hoverInfo as HoverLabel).label}</span>
            </HoverCard.Dropdown>
          ))
      }
    </HoverCard>
  );
};

export type GridCellObject = {
  value: RawCellContent;
  style: RowStyle;
  metadata?: RowMetadata;
  type: RowDataType;
  isManagedByApp: boolean;
};

const getDisplayValue = (cellValue: CellValue, cellType: RowDataType): React.ReactNode => {
  if (cellValue instanceof DetailedCellError) {
    // Handle detailed cell errors by returning their value, ensuring it's a string
    return cellValue.value.toString();
  }

  if (cellValue === null || cellValue === undefined) {
    return "";
  }

  switch (cellType) {
    case "number":
      const num = cellValue as number;
      // Whole numbers have no decimal places, numbers less than 1 have 3 decimal places, and all others have 2 decimal places
      const maximumFractionDigits = num % 1 === 0 ? 0 : num < 1 ? 3 : 2;
      return cellValue.toLocaleString("en-US", { maximumFractionDigits, useGrouping: true });
    case "percentage":
      return `${((cellValue as number) * 100).toLocaleString("en-US", {
        maximumFractionDigits: 0,
      })}%`;
    case "date":
      const allowedTypes = ["number", "string", "boolean"];
      if (!allowedTypes.includes(typeof cellValue)) {
        return "";
      } else if (typeof cellValue === "number") {
        return moment(cellValue).format("MM-DD-YYYY");
      } else {
        return moment(cellValue as string).format("MM-DD-YYYY");
      }
    case "text":
    case "formula":
      return `${cellValue}`;
    case "ratio":
      return `${Number(cellValue).toFixed(2)}x`;
    default:
      return "";
  }
};
