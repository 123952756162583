import { combineReducers } from "redux";
import { intakeReducer } from "src/redux/reducers/intake.reducer";
import { loanReducer } from "src/redux/reducers/loan.reducer";
import { lenderReducer } from "./lender.reducer";
import { annualReviewReducer } from "src/redux/reducers/annual-review.reducer";
import { interfoldUserReducer } from "src/redux/reducers/interfold-user.reducer";
import { contactReducer } from "src/redux/reducers/contact.reducer";
import { featureFlagReducer } from "src/redux/reducers/feature-flag.reducer";
import { reportingReducer } from "src/redux/reducers/reporting.reducer";
import { ocrReducer } from "./ocr.reducer";
import { spreadReducer } from "./spread.reducer";
import { loanWorkflowReducer } from "./loan-workflow.reducer";
import { managementReducer } from "src/redux/reducers/management.reducer";
import { documentAndReminderReducer } from "src/redux/reducers/document-reminder.reducer";
import postProcessingReducer from "src/redux/slices/postProcessing";

const appReducer = combineReducers({
  intake: intakeReducer,
  loan: loanReducer,
  lender: lenderReducer,
  annualReview: annualReviewReducer,
  interfoldUser: interfoldUserReducer,
  contacts: contactReducer,
  spread: spreadReducer,
  featureFlags: featureFlagReducer,
  reporting: reportingReducer,
  ocr: ocrReducer,
  loanWorkflow: loanWorkflowReducer,
  management: managementReducer,
  documentAndReminder: documentAndReminderReducer,
  postProcessing: postProcessingReducer,
});

export const rootReducer = (state: any, action: any) => appReducer(state, action);

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
