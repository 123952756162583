import { z } from "zod";

export enum ExtractableDocumentType {
  GUARANTOR_ANALYSIS_CORPORATION_FINANCIALS = "GUARANTOR_ANALYSIS_CORPORATION_FINANCIALS",
  GUARANTOR_ANALYSIS_CORPORATION_TAX_RETURN = "GUARANTOR_ANALYSIS_CORPORATION_TAX_RETURN",

  ANY = "ANY",
  GUARANTOR_ANALYSIS_PERSONAL = "GUARANTOR_ANALYSIS_PERSONAL",
  RENT_ROLL = "RENT_ROLL",
  PERSONAL_TAX_RETURN_PROPERTY = "PERSONAL_TAX_RETURN_PROPERTY",
  PERSONAL_TAX_RETURN = "PERSONAL_TAX_RETURN",
  MULTI_PROPERTY_RENTROLL = "MULTI_PROPERTY_RENTROLL",
  CORPORATE_TAX_RETURN_PROPERTY = "CORPORATE_TAX_RETURN_PROPERTY",
  FORM_1040 = "FORM_1040",
  FORM_1065 = "FORM_1065",
  FORM_1120 = "FORM_1120",
  FORM_1120S = "FORM_1120S",
  FORM_8825 = "FORM_8825",
  SCHEDULE_K1 = "SCHEDULE_K1",
  GENERIC_CASH_FLOW = "GENERIC_CASH_FLOW",
  GENERIC_BALANCE_SHEET = "GENERIC_BALANCE_SHEET",
  GENERIC_RENTROLL = "GENERIC_RENTROLL",
  FINANCIALS = "FINANCIALS",
}

export const ZExtractableDocumentType = z.nativeEnum(ExtractableDocumentType);
