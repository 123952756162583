import { createAction } from "@reduxjs/toolkit";
import { LoanAction } from "src/redux/actions/actions.constants";
import {
  CreateOrUpdateLoanRequestType,
  UpdateLoanRequestType,
  LoanCreateOrUpdateResponse,
} from "src/contracts/loan";
import { Loan } from "src/models/Loan";

export const createLoanStarted = createAction(LoanAction.CREATE_LOAN_STARTED);
export const createLoanEnded = createAction(LoanAction.CREATE_LOAN_ENDED);
export const createLoanData = createAction<CreateOrUpdateLoanRequestType>(LoanAction.CREATE_LOAN);
export const createLoanDataCompleted = createAction<LoanCreateOrUpdateResponse>(
  LoanAction.CREATE_LOAN_COMPLETED,
);
export const createLoanDataFailed = createAction<Error>(LoanAction.CREATE_LOAN_FAILED);

export const updateLoanData = createAction<UpdateLoanRequestType & { loanId: number }>(
  LoanAction.UPDATE_LOAN,
);
export const updateLoanDataCompleted = createAction<LoanCreateOrUpdateResponse>(
  LoanAction.UPDATE_LOAN_COMPLETED,
);
export const updateLoanDataFailed = createAction<Error>(LoanAction.UPDATE_LOAN_FAILED);

export const fetchDraftLoans = createAction<{ lenderEmployeeId: number }>(
  LoanAction.FETCH_DRAFT_LOANS,
);
export const fetchDraftLoansCompleted = createAction<Loan[]>(
  LoanAction.FETCH_DRAFT_LOANS_COMPLETED,
);
export const fetchDraftLoansFailed = createAction<Error>(LoanAction.FETCH_DRAFT_LOANS_FAILED);

export const deleteDraftLoans = createAction<{ loanId: number }>(LoanAction.DELETE_DRAFT_LOAN);
export const deleteDraftLoansCompleted = createAction<{ loanId: number }>(
  LoanAction.DELETE_DRAFT_LOAN_COMPLETED,
);
export const deleteDraftLoansFailed = createAction<Error>(LoanAction.DELETE_DRAFT_LOAN_FAILED);
