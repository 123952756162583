import { ScheduleK1Data } from "src/interfaces/FinancialFormData/ScheduleK1";
import { AutoRendered } from "src/classes/AutoRendered";
import { RawCellContent } from "hyperformula";
import { ScheduleK1 } from "src/interfaces/TaxFormData/schedules/ScheduleK1";
import { SpreadsDocumentType } from "src/page-components/investment-detail/tabs/SpreadsTab/SpreadsDocumentType";
import { TaxFormYear } from "src/interfaces/TaxFormData/TaxFormData";

export class ScheduleK1Rendered extends AutoRendered<ScheduleK1Data> {
  asColumns(): RawCellContent[][] {
    const data = this.underlying;
    if (data.summaries.length === 0) {
      return [];
    }

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    // const sortedSummaries = data.summaries.sort((a, b) => a.year - b.year);
    const years = data.summaries.map((summary) => summary.year);
    const headers = [`${years[0]} K-1's`, `Distributions`, `Contributions`, `Net Total`];
    const body = data.summaries.map((summary) => [
      summary.businessName,
      formatter.format(summary.distributions),
      formatter.format(summary.contributions),
      "",
    ]);
    const distributionTotal = data.summaries.reduce((acc, curr) => acc + curr.distributions, 0);
    const contributionTotal = data.summaries.reduce((acc, curr) => acc + curr.contributions, 0);
    const total = distributionTotal + contributionTotal;
    const footers = [
      "",
      formatter.format(distributionTotal),
      formatter.format(contributionTotal),
      formatter.format(total),
    ];

    return [headers, ...body, footers];
  }

  get highlightedRowLabels(): string[] {
    return [`${this.year} K-1s`, "Distributions", "Contributions", "Net Total"];
  }

  get highlightedRowIndexes(): number[] {
    return [0, this.scheduleK1s.length + 1];
  }

  get percentageRowLabels() {
    return [];
  }

  constructor(
    public scheduleK1s: ScheduleK1[],
    public year: TaxFormYear,
  ) {
    const lenderId = scheduleK1s.length > 0 ? scheduleK1s[0].lenderId : 0;
    const data: ScheduleK1Data = {
      form: "K1",
      summaries: [],
      year,
      entityName: "unused",
      lenderId,
    };

    scheduleK1s.forEach((schedule) => {
      data.summaries.push({
        year: schedule.year,
        businessName: schedule.businessName,
        distributions: schedule.distributions,
        contributions: -schedule.contributions,
        documentType: SpreadsDocumentType.SCHEDULE_K1,
      });
    });

    super(data);
  }

  getEntityName(): string {
    return this.underlying.extractContext?.metadata?.entityName ?? "Entity";
  }
}
