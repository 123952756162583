import { Action } from "redux";
import { END, eventChannel } from "redux-saga";
import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { BorrowerIntakeSection } from "src/models/BorrowerIntakePage";
import {
  bulkUploadDocumentCompleteAction,
  createQuestionAction,
  createQuestionErrorAction,
  documentUploadAction,
  documentUploadCompletedAction,
  documentUploadErrorAction,
  dropUploadDocumentAction,
  fetchAnswerCompletedAction,
  isOverviewCompletedAction,
  lenderSideDocumentUploadActionComplete,
  navBarLoadingAction,
  reviewDocumentCompletedUpload,
  reviewDocumentUpload,
} from "src/redux/actions/intake.actions";
import {
  annualReviewDetailsGetAction,
  loanDetailsGetAction,
  loanDetailsSetAction,
  modifyCustomerUsersAction,
  setUploadedDocumentAction,
} from "src/redux/actions/pipelineLoans.action";
import { overviewService } from "src/services/api-services/OverviewService";
import { toastService } from "src/services/ToastService";
import { uploadService } from "src/services/UploadService";
import { BorrowerIntakeAction } from "../actions/actions.constants";
import { genUploadedDocumentKey, zipFiles } from "src/utils/helpers";
import { UploadDocumentResponse } from "src/models/DocumentRequest";
import { DocumentQuality } from "src/Enums/DocumentQualityEnum";

interface BulkUploadDocumentPayload {
  loanId: number;
  entityId: number;
  section: BorrowerIntakeSection;
  sectionTitle: string;
  documentsData: {
    key: string;
    files: File[];
    documentTypeId: number | null;
    documentTitle: string | null;
    documentYear: string;
    documentQuarter?: string;
    documentMonth?: string;
    documentQuality?: DocumentQuality;
    isReview: boolean;
    documentPeriodJson?: string;
  }[];
}

interface MultiDocumentDeletePayload {
  loanId: number;
  isReview: boolean;
  documentRequestId: number;
}

function* createQuestionSaga(payload: Action<string>): any {
  try {
    if (createQuestionAction.match(payload)) {
      const { answers, context, isOverview3Completed, modifyCustomerUserData, callback } =
        payload.payload;

      if (answers.length === 0) {
        /*
         * TODO: We expect this to happen on pages with only file inputs.
         *  This will no longer be true once the answer API handles document uploads;
         *  at this time, this should be a more serious error (eg. it indicates a bug if
         *  the array is empty.)
         */
        console.error("No answers provided");
        if (callback) {
          callback();
        }
        return;
      }
      const response = yield call(overviewService.submitAnswer, answers, context);
      yield put(fetchAnswerCompletedAction(response.answers));
      yield put(loanDetailsSetAction(response.loan));
      if (isOverview3Completed) {
        yield put(isOverviewCompletedAction(true));
        yield put(navBarLoadingAction(false));
      }
      if (modifyCustomerUserData) {
        modifyCustomerUsersAction(
          modifyCustomerUserData.loanId,
          modifyCustomerUserData.emails,
          modifyCustomerUserData.returnResponse,
        );
        if (modifyCustomerUserData.returnResponse)
          toastService.showSuccess("Borrower contacts are updated successfully");
      }
      if (callback) {
        callback();
      }
    }
  } catch (e: any) {
    yield put(createQuestionErrorAction(e?.errors[0]));
    yield put(navBarLoadingAction(false));
    toastService.showError(e?.response.error);
  }
}

function* reviewUploadDocumentSaga(action: Action<string>): any {
  if (reviewDocumentUpload.match(action)) {
    const data = action.payload;
    const { payload: file, documentRequestId, lenderId, formik, key } = data;
    let emit: any;
    eventChannel((emitter) => {
      emit = emitter;
      return () => {};
    });
    const onUploadProgress = (e: ProgressEvent) => {
      const progress = Math.floor((e.loaded / e.total) * 100);
      emit(progress);
      if (progress === 100) {
        emit(END);
      } else emit(progress);
    };
    try {
      const response = yield call(uploadService.reviewUploadFile, `/api/documents`, {
        file,
        onUploadProgress,
        lenderId,
        documentRequestId,
      });

      formik.setFieldValue(key, file, false);
      yield put(reviewDocumentCompletedUpload({ reviews: response.reviews }));
      toastService.showSuccess(`File${file?.name ? ` ${file.name}` : ""} uploaded successfully`);
    } catch (caught: unknown) {
      const err: Error = caught instanceof Error ? caught : new Error(String(caught));
      yield put(documentUploadErrorAction(err));
      console.error("reviewUploadDocumentSaga", caught);
      toastService.showError(
        `Could not upload file${file?.name ? ` ${file.name}` : ""}. Please try again.`,
      );
    }
  }
}

function* uploadDocumentSaga(action: Action<string>): any {
  if (documentUploadAction.match(action)) {
    const data = action.payload;
    const {
      payload: file,
      documentTypeId,
      documentYear,
      documentQuarter,
      documentMonth,
      loanId,
      section,
      entityId,
      page,
      key,
      formik,
      formIndex,
      isReview = false,
      displayName,
    } = data;
    const guarantorName = data.guarantor?.name;
    let emit: any;
    eventChannel((emitter) => {
      emit = emitter;
      return () => {};
    });
    const onUploadProgress = (e: ProgressEvent) => {
      const progress = Math.floor((e.loaded / e.total) * 100);
      emit(progress);
      if (progress === 100) {
        emit(END);
      } else emit(progress);
    };
    try {
      let response;
      let apiCallFailed = false;
      try {
        response = yield call(uploadService.uploadFile, `/api/loan/${loanId}/document`, {
          file,
          onUploadProgress,
          entityId,
          guarantorName,
          documentTypeId: String(documentTypeId),
          documentYear: documentYear,
          documentQuarter: documentQuarter,
          documentMonth: documentMonth,
          isReview,
          index: formIndex,
          displayName,
        });
      } catch (caught: unknown) {
        const err: Error = caught instanceof Error ? caught : new Error(String(caught));
        yield put(documentUploadErrorAction(err));
        console.error("uploadDocumentSaga API call", caught);
        toastService.showError(
          `Could not upload file${file?.name ? ` ${file.name}` : ""}. Please try again.`,
        );
        apiCallFailed = true;
      }
      if (!apiCallFailed) {
        yield put(
          dropUploadDocumentAction(
            section,
            data.title,
            genUploadedDocumentKey({
              page,
              documentTypeId,
              documentYear,
              formIndex,
              displayName,
            }),
          ),
        );
        formik.setFieldValue(key, file, false);
        yield put(documentUploadCompletedAction(section, file, response, documentTypeId));
        toastService.showSuccess(`File${file?.name ? ` ${file.name}` : ""} uploaded successfully`);
      }
    } catch (caught: unknown) {
      console.error("uploadDocumentSaga redux", caught);
    }
  }
}

function* bulkUploadDocumentSaga(data: { type: string; payload: BulkUploadDocumentPayload }): any {
  const section = data.payload.section;
  const sectionTitle = data.payload.sectionTitle;
  const loanId = data.payload.loanId;
  const entityId = data.payload.entityId;
  let hoistedIsReview = false;
  let filesUploaded = false;

  for (const uploadData of data.payload.documentsData) {
    const files = uploadData.files;
    const documentTypeId = uploadData.documentTypeId;
    const documentTitle = uploadData.documentTitle;
    const documentYear = uploadData.documentYear;
    const documentPeriodJson = uploadData.documentPeriodJson;
    const documentQuarter = uploadData.documentQuarter;
    const documentMonth = uploadData.documentMonth;
    const documentQuality = uploadData.documentQuality;
    const isReview = uploadData.isReview;
    hoistedIsReview = hoistedIsReview || isReview;

    let emit: any;
    eventChannel((emitter) => {
      emit = emitter;
      return () => {};
    });
    const onUploadProgress = (e: ProgressEvent) => {
      const progress = Math.floor((e.loaded / e.total) * 100);
      emit(progress);
      if (progress === 100) {
        emit(END);
      } else emit(progress);
    };
    try {
      let response: UploadDocumentResponse | undefined;
      let apiCallFailed = false;
      let file: File | null = files.length
        ? files.length === 1
          ? files[0]
          : yield zipFiles(files, `${documentYear}-${documentTitle?.replace(/\s/g, "-")}.zip`)
        : null;
      filesUploaded = filesUploaded || !!file;
      if (documentTypeId && file) {
        try {
          response = yield call(uploadService.uploadFile, `/api/loan/${loanId}/document`, {
            file,
            onUploadProgress,
            entityId,
            documentTypeId: String(documentTypeId),
            documentYear: documentYear,
            documentQuarter: documentQuarter,
            documentMonth: documentMonth,
            documentQuality: documentQuality,
            isReview,
            documentPeriodJson,
          });
        } catch (caught: unknown) {
          const err: Error = caught instanceof Error ? caught : new Error(String(caught));
          yield put(documentUploadErrorAction(err));
          toastService.showError(
            `Could not upload file${file?.name ? ` ${file.name}` : ""}. Please try again.`,
          );
          apiCallFailed = true;
        }
        if (!apiCallFailed && response) {
          yield put(documentUploadCompletedAction(section, file, response, documentTypeId));
        }
      } else {
        try {
          response = yield call(uploadService.uploadFile, `/api/loan/${loanId}/document`, {
            file,
            onUploadProgress,
            entityId,
            documentTitle: documentTitle!,
            documentYear: String(documentYear),
            documentQuarter: documentQuarter,
            documentMonth: documentMonth,
            documentQuality: documentQuality,
            isReview,
            documentPeriodJson,
          });
        } catch (caught: unknown) {
          const err: Error = caught instanceof Error ? caught : new Error(String(caught));
          yield put(documentUploadErrorAction(err));
          console.error("bulkUploadDocumentSaga API call documentTypeId=false", caught);
          toastService.showError(
            `Could not upload file${file?.name ? ` ${file.name}` : ""}. Please try again.`,
          );
          apiCallFailed = true;
        }
        if (!apiCallFailed && response) {
          yield put(lenderSideDocumentUploadActionComplete(section, response));
          yield put(setUploadedDocumentAction(sectionTitle, response, entityId));
        }
      }
    } catch (caught: unknown) {
      console.error("bulkUploadDocumentSaga redux", caught);
    }
  }
  if (data.payload?.documentsData?.length > 0) {
    toastService.showSuccess(
      filesUploaded ? "Files uploaded successfully" : "Document request created",
    );
  }
  yield put(bulkUploadDocumentCompleteAction());
  if (hoistedIsReview) {
    yield put(annualReviewDetailsGetAction(loanId.toString()));
  } else {
    yield put(loanDetailsGetAction(loanId.toString()));
  }
}

function* multiDocumentUploadSaga(data: { type: string; payload: BulkUploadDocumentPayload }): any {
  const { section, loanId, entityId, documentsData } = data.payload;

  let hoistedIsReview = false;
  let filesUploaded = false;

  for (const uploadData of documentsData) {
    const { files, isReview } = uploadData;

    hoistedIsReview = hoistedIsReview || isReview;

    // Handling multiple files
    for (const file of files) {
      let emit: any;
      const channel = eventChannel((emitter) => {
        emit = emitter;
        return () => {};
      });

      const onUploadProgress = (e: ProgressEvent) => {
        const progress = Math.floor((e.loaded / e.total) * 100);
        emit(progress);
        if (progress === 100) {
          emit(END);
        } else emit(progress);
      };

      try {
        let response: UploadDocumentResponse | undefined;
        let apiCallFailed = false;

        filesUploaded = filesUploaded || !!file;

        try {
          response = yield call(uploadService.uploadFile, `/api/loan/${loanId}/document`, {
            file,
            onUploadProgress,
            entityId,
            documentTitle: "Custom",
            displayName: file.name,
            documentYear: new Date().getFullYear().toString(),
            isReview,
          });
        } catch (caught: unknown) {
          const err: Error = caught instanceof Error ? caught : new Error(String(caught));
          yield put(documentUploadErrorAction(err));
          toastService.showError(`Could not upload file ${file.name}. Please try again.`);
          apiCallFailed = true;
        }

        if (!apiCallFailed && response) {
          yield put(documentUploadCompletedAction(section, file, response, -1));
        }
      } catch (caught: unknown) {
        console.error("bulkUploadDocumentSaga redux", caught);
      } finally {
        channel.close();
      }
    }
  }

  if (documentsData.length > 0) {
    toastService.showSuccess(
      filesUploaded ? "Files uploaded successfully" : "Document request created",
    );
  }

  yield put(bulkUploadDocumentCompleteAction());

  if (hoistedIsReview) {
    yield put(annualReviewDetailsGetAction(loanId.toString()));
  } else {
    yield put(loanDetailsGetAction(loanId.toString()));
  }
}

function* multiDocumentDeleteSaga(data: {
  type: string;
  payload: MultiDocumentDeletePayload;
}): any {
  const { loanId, isReview, documentRequestId } = data.payload;

  try {
    yield call(uploadService.deleteDocument, `/api/loan/${loanId}/document/${documentRequestId}`, {
      isReview,
    });
  } catch (caught: unknown) {
    console.error("multiDocumentDeleteSaga", caught);
    toastService.showError("Could not delete document. Please try again.");
  }

  if (isReview) {
    yield put(annualReviewDetailsGetAction(loanId.toString()));
  } else {
    yield put(loanDetailsGetAction(loanId.toString()));
  }
}

function* intakeSaga() {
  yield all([
    takeLatest(BorrowerIntakeAction.CREATE_QUESTION, createQuestionSaga),
    takeEvery(BorrowerIntakeAction.UPLOAD_DOCUMENT, uploadDocumentSaga),
    takeEvery(BorrowerIntakeAction.REVIEW_UPLOAD_DOCUMENT, reviewUploadDocumentSaga),
    takeEvery(BorrowerIntakeAction.BULK_UPLOAD_DOCUMENT, bulkUploadDocumentSaga),
    takeLatest(BorrowerIntakeAction.MULTI_DOCUMENT_UPLOAD, multiDocumentUploadSaga),
    takeLatest(BorrowerIntakeAction.MULTI_DOCUMENT_DELETE, multiDocumentDeleteSaga),
  ]);
}

export default intakeSaga;
