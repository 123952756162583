import { AutoRendered } from "src/classes/AutoRendered";
import { HoverLabel, RenderableBase } from "src/classes/RenderedDoc";
import { defaultCliLogger } from "src/utils/defaultCliLogger";

export abstract class AutoRenderedWithLineNumberHovers<
  T extends RenderableBase,
> extends AutoRendered<T> {
  public abstract labelToLineNumber: Record<string, number>;

  setHoverLabels(colsToSkip: number[] = [0]): void {
    const labelsToSet = Object.entries(this.labelToLineNumber);
    labelsToSet.forEach(([label, lineNumber]) => {
      this.setHoverInfoForLabel(label, HoverLabel.from(`Line ${lineNumber}`), colsToSkip);
    });
  }

  constructor(underlying: T, columnId: string = "B", rowStart: number = 0) {
    super(underlying, columnId, rowStart);
  }
}
