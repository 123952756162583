import { RawCellContent } from "hyperformula";
import { HoverInfo, RenderableBase, Rendered, RenderedDoc } from "./RenderedDoc";
import { GridRow, GridState, RowId } from "./GridState";

export type AutoRenderedRowDataType = "number" | "text";

export interface AutoRenderedRow extends Array<RawCellContent> {
  rowDataType?: AutoRenderedRowDataType;
}

/**
 * Will set the data type to text so the renderer will not format as a number with commas, etc...
 * It's a shortcut because the syntax is a bit verbose.
 */
export function autoRenderedTextRow(row: AutoRenderedRow): AutoRenderedRow {
  const rowCopy: AutoRenderedRow = [...row];
  rowCopy.rowDataType = "text";
  return rowCopy;
}

export abstract class AutoRendered<T extends RenderableBase>
  extends Rendered<T, string>
  implements RenderedDoc
{
  public hoverInfos: HoverInfo[][] = [];
  constructor(
    public underlying: T,
    public columnId: string = "B",
    public rowStart: number = 1,
  ) {
    super(underlying);
  }

  /*
    This method returns data like: [["label1", "data1"], ["label2", "data2"]]
    The first column is the label, the second is the data.
    */
  abstract asColumns(): RawCellContent[][];

  private _highlightedRowIndexes: number[] = [];
  set highlightedRowIndexes(indexes: number[]) {
    this._highlightedRowIndexes = indexes;
  }
  get highlightedRowIndexes(): number[] {
    return this._highlightedRowIndexes;
  }

  setDefaultHoverInfos(): void {
    if (!this.underlying || !this.asColumns() || this.asColumns().length === 0) {
      return;
    }
    const height = this.asColumns().length + this.rowStart - 1;
    const width = this.numberOfColumns;
    this.hoverInfos = Array.from({ length: height }, () =>
      Array.from({ length: width }, () => ({ type: "auto" })),
    );
  }

  setHoverInfoForLabel(label: string, hoverInfo: HoverInfo, colsToSkip: number[] = [0]): void {
    const rowIndex = this.asColumns().findIndex((row) => row[0] === label);
    if (rowIndex === -1) {
      return;
    }
    this.hoverInfos[rowIndex + this.rowStart]?.forEach((_, colIndex) => {
      if (colsToSkip.includes(colIndex)) {
        return;
      }
      this.hoverInfos[rowIndex + this.rowStart][colIndex] = hoverInfo;
    });
  }

  // Return only the data,
  asCol() {
    return this.asColumns().map((row) => row[1]);
  }

  asLabels() {
    return this.asColumns().map((row) => row[0]);
  }

  get initialGridState(): GridState {
    const entries = this.asColumns()?.map((row: AutoRenderedRow, index) => {
      const rowDataType = row?.rowDataType ?? determineDataType(row[1]);
      const isHighlighted =
        this.highlightedRowIndexes.includes(index) ||
        this.highlightedRowLabels.findIndex(
          (label) => typeof row[0] === "string" && row[0].includes(label),
        ) !== -1;
      const patchedData = row.map((cell, idx) => {
        // Skip label column
        if (idx === 0) {
          return cell;
        }

        if (typeof cell === "string" && cell.startsWith("=")) {
          // handle formula specific logic here
        } else if (typeof cell === "string" && rowDataType === "number") {
          const value = parseFloat(cell);
          return isNaN(value) ? cell : value;
        }
        return cell;
      });
      const rowId = `row-${index}` as RowId;
      const gridRow: GridRow = {
        rowDataArray: patchedData,
        rowDataType,
        rowStyle: isHighlighted ? "highlighted" : "standard",
        isManagedByApp: true,
        index: this.rowStart + index,
      };
      return [rowId, gridRow];
    });

    return Object.fromEntries(entries ?? []);
  }

  get numberOfColumns() {
    if (this.asColumns().length === 0) {
      console.warn("No columns found in AutoRendered object. Returning 0.");
      return 0;
    }
    const allLengths = this.asColumns().map((row) => row.length);
    return Math.max(...allLengths);
  }

  get defaultRowLabels(): string[] {
    return Object.keys(this.underlying);
  }

  columnAtIndex(index: number) {
    return this.columnIndexToId(this.columnIdToIndex(this.columnId) + index);
  }
}

function determineDataType(patchedData: RawCellContent): AutoRenderedRowDataType {
  if (typeof patchedData === "string" && parseFloat(patchedData).toString() === patchedData) {
    return "number";
  } else if (typeof patchedData === "string" && patchedData.match(/^[0-9]+$/)) {
    return "number";
  } else if (typeof patchedData === "string" && patchedData.startsWith("=")) {
    return "number";
  } else if (typeof patchedData === "number") {
    return "number";
  } else {
    return "text";
  }
}
