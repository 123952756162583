import { TableGeneric } from "amazon-textract-response-parser/dist/types/table.js";
import { Page, TextractDocument } from "amazon-textract-response-parser";
import { SpreadsDocumentType } from "src/page-components/investment-detail/tabs/SpreadsTab/SpreadsDocumentType";
import { DocumentType } from "@prisma/client";
import { ApiWordBlock } from "amazon-textract-response-parser/dist/types/api-models";
import { RawCellContent } from "hyperformula";

export type Metadata = {
  extractorType?: string | SpreadsDocumentType;
  documentType?: DocumentType;
  propertyName?: string | null;
  id?: string | null;
};

export type TableRow = {
  [key: string]: string | null;
};

export type Table = {
  headers?: string[];
  rows: Record<string, any>[];
  heading?: string;
  pageNumber?: number;
  metadata?: Metadata;
};

export const extraCellTable2CSV = (table: Table): RawCellContent[][] => {
  const rows = table.rows;
  const csv: RawCellContent[][] = [];
  rows.forEach((row) => {
    csv.push(Object.values(row));
  });
  return csv;
};

export type PageInfo = {
  pageNumber: number;
  header: string | undefined;
  footer: string | undefined;
  lines: string[];
  tables: Table[];
};

export function getRawTables(
  input: TextractDocument,
  startPage: number = 1,
  endPage: number = 1,
): TableGeneric<Page>[] {
  const tables: TableGeneric<Page>[] = [];
  for (let pageIndex = startPage; pageIndex <= endPage; pageIndex++) {
    const page = input._pages[pageIndex - 1];
    for (let tableIndex = 0; tableIndex < page._tables.length; tableIndex++) {
      const table = page._tables[tableIndex];
      tables.push(table);
    }
  }
  return tables;
}

function extractHeading(input: TableGeneric<Page>): string | undefined {
  const headerIds =
    input.dict.Relationships.find((relationship) => (relationship.Type as string) === "TABLE_TITLE")
      ?.Ids ?? [];

  const document = input.parentPage.parentDocument;
  if (document && headerIds.length > 0) {
    const headerBlocks = headerIds.map((id) => document.getBlockById(id));
    const childBlockIds = headerBlocks
      .map(
        (block: any) =>
          block?.Relationships?.find((relationship: any) => relationship.Type === "CHILD")?.Ids ??
          [],
      )
      .flat();
    const childBlocks = childBlockIds.map((id) => document.getBlockById(id)) as ApiWordBlock[];
    const headingCandidate = childBlocks
      .map((block) => block?.Text)
      .join(" ")
      .trim();
    return headingCandidate.length > 0 ? headingCandidate : undefined;
  }
  return undefined;
}

export function getTable(input: TableGeneric<Page>, hasHeader: boolean = false): Table {
  let rows: TableRow[] = [];
  let headers: string[] | undefined = undefined; // Change to string[] | undefined
  let heading: string | undefined = extractHeading(input);
  let pageNumber = input.parentPage.pageNumber;

  let startRowIndex = 1;
  if (hasHeader) {
    // Filter out null values to align with the Table type
    headers = input
      .cellsAt(1, null)
      ?.map((cell) => (cell ? cell.text : null))
      .filter((header) => header !== null) as string[];
    startRowIndex = 2;
  }

  for (let i = startRowIndex; i <= input.nRows; i++) {
    let cells = input.cellsAt(i, null) || [];
    let row: TableRow = {};

    cells.forEach((cell, index) => {
      let header: string =
        headers && index < headers.length ? headers[index] : `ExtraCell${index + 1}`;
      row[header] = cell ? cell.text : null;
    });

    rows.push(row);
  }

  return { headers, rows, heading, pageNumber };
}

export function combineTables(table1: Table, table2: Table): Table {
  // Clone the first table to avoid modifying the original directly
  let combinedTable = JSON.parse(JSON.stringify(table1));

  // Append each row from the second table to the combined table
  for (const row of table2.rows) {
    combinedTable.rows.push(row);
  }

  return combinedTable;
}

export function valuesInclude(candidate: Record<string, any>, ...target: string[]): boolean {
  return target.some((value) => combineAndClean(candidate).includes(combineAndClean({ value })));
}

function combineAndClean(obj: { [key: string]: any }): string {
  return Object.values(obj)
    .join(" ")
    .replace(/[^a-zA-Z0-9]/g, "")
    .toLowerCase();
}

export function tableIncludes(table: Table, ...target: string[]): boolean {
  return table.rows.some((row) => valuesInclude(row, ...target));
}
