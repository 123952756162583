import type { RawCellContent } from "hyperformula";
import { AutoRendered, AutoRenderedRow, autoRenderedTextRow } from "src/classes/AutoRendered";
import { Form1065 } from "src/interfaces/TaxFormData/Form1065";
import {
  buildForm1065IncomeStatement,
  Form1065IncomeStatementRowHeadings,
} from "src/classes/RenderedDocuments/Form1065/Form1065IncomeStatement";

export class Form1065IncomeStatementGrouped extends AutoRendered<Form1065[]> {
  static netIncomeRowNumber = 28;
  constructor(public taxData: Form1065[]) {
    super(taxData);
  }

  private getColumnLetter(offset: number): string {
    return String.fromCharCode("A".charCodeAt(0) + offset);
  }

  asColumns(): RawCellContent[][] {
    const startingRow = 1;
    const data = this.underlying.sort((a, b) => parseInt(a.year) - parseInt(b.year));
    const header = autoRenderedTextRow(["Year", ...data.map((r) => r.year)]);
    const body: RawCellContent[][] = [];

    data.forEach((form, i) => {
      const startingColumn = this.getColumnLetter(i + 1);
      const singleRendered = buildForm1065IncomeStatement(form, startingColumn, startingRow);
      const formColumns = singleRendered.asColumns();
      formColumns.forEach((row, rowIndex) => {
        if (!body[rowIndex]) {
          body[rowIndex] = [row[0]];
        }
        body[rowIndex].push(row[1]);
      });
    });

    return [header, ...body];
  }

  get highlightedRowLabels() {
    return [
      "Year",
      Form1065IncomeStatementRowHeadings.NetRevenues,
      Form1065IncomeStatementRowHeadings.GrossProfit,
      Form1065IncomeStatementRowHeadings.TotalIncome,
      Form1065IncomeStatementRowHeadings.TotalExpensesAndDeductions,
      Form1065IncomeStatementRowHeadings.PreTaxProfitOrLoss,
      Form1065IncomeStatementRowHeadings.TotalTax,
      Form1065IncomeStatementRowHeadings.NetIncome,
    ];
  }

  get percentageRowLabels() {
    return [];
  }

  indexOfHeader(header: keyof typeof Form1065IncomeStatementRowHeadings): number {
    const heading = Form1065IncomeStatementRowHeadings[header];
    return this.asColumns().findIndex((row) => row[0] === heading) + 1;
  }
}
