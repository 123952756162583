import { baseApiService } from "src/services/api-services/BaseApiService";
import { ReportingDetails } from "src/components/ReportingDetailsTable/ReportingDetailsTable";
import { encodeWhereClause, SQLQueryWhere } from "src/utils/sql-query-builder";
import { ReportingFilter } from "@prisma/client";

export class ReportingService {
  static getInstance(): ReportingService {
    return new ReportingService();
  }

  async getReportingFilters(): Promise<ReportingFilter[]> {
    try {
      return (await baseApiService.get("/api/reporting/filters")).data;
    } catch (error) {
      throw error;
    }
  }

  async getReportingHeaders(): Promise<ReportingFilter[]> {
    try {
      return (await baseApiService.get("/api/reporting/headers")).data;
    } catch (error) {
      throw error;
    }
  }

  async updateReportingFilter(filter: ReportingFilter): Promise<ReportingFilter> {
    try {
      return (await baseApiService.put(`/api/reporting/filters/${filter.id}`, filter)).data;
    } catch (error) {
      throw error;
    }
  }

  async createReportingFilter(filter: ReportingFilter): Promise<ReportingFilter> {
    try {
      return (await baseApiService.post(`/api/reporting/filters`, filter)).data;
    } catch (error) {
      throw error;
    }
  }

  async deleteReportingFilter(filterId: number): Promise<void> {
    try {
      await baseApiService.delete(`/api/reporting/filters/${filterId}`);
    } catch (error) {
      throw error;
    }
  }

  async getReportingData(
    limit: number,
    offset: number,
    sortKey: string,
    sortDirection: sortDirection,
    filterValues: SQLQueryWhere,
    columns: string[],
  ): Promise<ReportingDetails[]> {
    try {
      const columnsString = columns?.length > 0 ? `&columns=${columns.join(",")}` : "";
      return (
        await baseApiService.get(
          `/api/reporting?limit=${limit}&offset=${offset}&sortKey=${sortKey}&sortDirection=${sortDirection}&${encodeWhereClause(filterValues)}${columnsString}`,
        )
      ).data;
    } catch (error) {
      throw error;
    }
  }
}
export const reportingService = ReportingService.getInstance();

export type sortDirection = "ASC" | "DESC";
