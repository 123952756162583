import type { RawCellContent } from "hyperformula";
import { AutoRendered, AutoRenderedRow, autoRenderedTextRow } from "src/classes/AutoRendered";
import { Form1120S } from "src/interfaces/TaxFormData/Form1120s";
import { Form1120sIncomeStatementGrouped } from "./Form1120sIncomeStatementGrouped";
import { Form1120sBalanceSheetGrouped } from "./Form1120sBalanceSheetGrouped";
import { Form1120sCashFlowGrouped } from "./Form1120sCashFlowGrouped";
import { buildForm1120sRatios, Form1120sRatiosRowHeadings } from "./Form1120sRatios";

export class Form1120sRatiosGrouped extends AutoRendered<Form1120S[]> {
  constructor(
    public taxData: Form1120S[],

    public balanceSheetTabName: string,
    public incomeStatementTabName: string,
    public cashFlowTabName: string,

    public balanceSheet: Form1120sBalanceSheetGrouped,
    public incomeStatement: Form1120sIncomeStatementGrouped,
    public cashFlow: Form1120sCashFlowGrouped,
  ) {
    super(taxData);
  }

  private getColumnLetter(offset: number): string {
    return String.fromCharCode("A".charCodeAt(0) + offset);
  }

  asColumns(): RawCellContent[][] {
    const startingRow = 1;
    const data = this.underlying.sort((a, b) => parseInt(a.year) - parseInt(b.year));
    const header = autoRenderedTextRow(["Year", ...data.map((r) => r.year)]);
    const body: RawCellContent[][] = [];

    data.forEach((form, i) => {
      const startingColumn = this.getColumnLetter(i + 1);
      const singleRendered = buildForm1120sRatios(
        form,
        this.balanceSheetTabName,
        this.incomeStatementTabName,
        this.cashFlowTabName,
        this.balanceSheet,
        this.incomeStatement,
        this.cashFlow,
        startingColumn,
        startingRow,
      );
      const formColumns = singleRendered.asColumns();
      formColumns.forEach((row, rowIndex) => {
        if (!body[rowIndex]) {
          body[rowIndex] = [row[0]];
        }
        body[rowIndex].push(row[1]);
      });
    });

    return [header, ...body];
  }

  get highlightedRowLabels() {
    return ["Year"];
  }

  get percentageRowLabels() {
    return [];
  }

  public indexOfHeader(header: keyof typeof Form1120sRatiosRowHeadings): number {
    const heading = Form1120sRatiosRowHeadings[header];
    return this.asColumns().findIndex((row) => row[0] === heading);
  }
}
