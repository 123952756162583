export function parseTaxCellAsFloat(cell: string | undefined): number | null {
  const wrappedInParens = cell?.match(/\((.*)\)/);
  const innerCell = wrappedInParens ? wrappedInParens[1].trim() : cell?.trim();
  if (!innerCell || innerCell === "") {
    return null;
  }
  let parsed;
  try {
    // remove dollar signs and commas
    parsed = parseFloat(innerCell.replace(/[$,]/g, ""));
  } catch (e) {
    console.log("Error parsing cell as float failed, returning null", cell);
    return null;
  }

  return wrappedInParens ? -parsed : parsed;
}

export function parseTaxCellAsFloatOrUndefined(cell: string): number | undefined {
  const val = parseTaxCellAsFloat(cell) ?? undefined;
  if (Number.isNaN(val)) {
    return undefined;
  }
  return val;
}
