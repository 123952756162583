import { RawCellContent } from "hyperformula";
import {
  FinancialPeriodItem,
  FinancialStatementItems,
} from "src/backend/services/ocr/abstractions/financial-statement";
import { GridRow } from "src/classes/GridState";
import { parseTaxCellAsFloatOrUndefined } from "src/backend/services/ocr/utils/parseTaxCellAsFloat";
import moment from "moment";

export const convertToGridRows = (jsonData: FinancialStatementItems): GridRow[] => {
  let rowIndex = 0;

  const processItem = (
    item: FinancialPeriodItem,
    allPeriods: string[],
    levelIndex: number,
  ): GridRow[] => {
    const gridRows: GridRow[] = [];

    const skipItemLevelValues = item.total || (item.nestedItems && item.nestedItems.length > 0);
    const rowDataArray: RawCellContent[] = [
      item.name,
      ...allPeriods.map((period) => {
        if (skipItemLevelValues) {
          return "";
        } else {
          return parseTaxCellAsFloatOrUndefined(item.periodValues?.[period] || "");
        }
      }),
    ];
    const gridRow: GridRow = {
      rowDataArray,
      rowDataType: "number",
      rowStyle: levelIndex === 0 ? "highlighted" : "standard",
      rowMetadata: {
        levelIndex,
      },
      isManagedByApp: false,
      index: rowIndex++,
    };
    gridRows.push(gridRow);

    item.nestedItems?.forEach((nestedItem) => {
      gridRows.push(...processItem(nestedItem, allPeriods, levelIndex + 1));
    });

    if (item.total) {
      const totalRowDataArray: RawCellContent[] = [
        item.total.name,
        ...allPeriods.map((period) =>
          parseTaxCellAsFloatOrUndefined(item.total?.periodValues?.[period] || ""),
        ),
      ];

      const totalGridRow: GridRow = {
        rowDataArray: totalRowDataArray,
        rowDataType: "number",
        rowStyle: "highlighted",
        rowMetadata: {
          levelIndex: levelIndex + 1,
        },
        isManagedByApp: false,
        index: rowIndex++,
      };
      gridRows.push(totalGridRow);
    }

    return gridRows;
  };

  const gridRows: GridRow[] = [];
  const allPeriods = [...jsonData.availablePeriods]
    .map((period) => moment(period, "MM-DD-YYYY"))
    .sort((a, b) => a.diff(b))
    .map((date) => date.format("MM-DD-YYYY"));

  gridRows.push({
    rowDataArray: ["", ...allPeriods],
    rowDataType: "text",
    rowStyle: "highlighted",
    rowMetadata: {
      levelIndex: 0,
    },
    isManagedByApp: false,
    index: rowIndex++,
  });

  jsonData.items.forEach((item) => {
    gridRows.push(...processItem(item, allPeriods, 0));
  });

  return gridRows;
};
