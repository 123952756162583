import type { RawCellContent } from "hyperformula";
import { AutoRendered, AutoRenderedRow, autoRenderedTextRow } from "src/classes/AutoRendered";
import { Form1120S } from "src/interfaces/TaxFormData/Form1120s";
import {
  buildForm1120sCashFlow,
  Form1120sCashFlowRowHeadings,
} from "src/classes/RenderedDocuments/Form1120s/Form1120sCashFlow";

export class Form1120sCashFlowGrouped extends AutoRendered<Form1120S[]> {
  constructor(data: Form1120S[]) {
    super(data);
  }

  private getColumnLetter(offset: number): string {
    return String.fromCharCode("A".charCodeAt(0) + offset);
  }

  asColumns(): RawCellContent[][] {
    const startingRow = 1;
    const data = this.underlying.sort((a, b) => parseInt(a.year) - parseInt(b.year));
    const header = autoRenderedTextRow(["Year", ...data.map((r) => r.year)]);
    const body: RawCellContent[][] = [];

    data.forEach((form, i) => {
      const startingColumn = this.getColumnLetter(i + 1);
      const singleRendered = buildForm1120sCashFlow(form, startingColumn, startingRow);
      const formColumns = singleRendered.asColumns();
      formColumns.forEach((row, rowIndex) => {
        if (!body[rowIndex]) {
          body[rowIndex] = [row[0]];
        }
        body[rowIndex].push(row[1]);
      });
    });

    return [header, ...body];
  }

  get highlightedRowLabels(): string[] {
    return [
      "Year",
      Form1120sCashFlowRowHeadings.BusinessCashFlowBeforeTax,
      Form1120sCashFlowRowHeadings.BusinessCashFlowAfterTax,
      Form1120sCashFlowRowHeadings.BusinessCashFlowAvailableForDebtService,
      Form1120sCashFlowRowHeadings.DebtService,
      Form1120sCashFlowRowHeadings.StressScenarioDebtService,
    ];
  }

  get percentageRowLabels(): string[] {
    return [];
  }

  public indexOfHeader(header: keyof typeof Form1120sCashFlowRowHeadings): number {
    const heading = Form1120sCashFlowRowHeadings[header];
    return this.asColumns().findIndex((row) => row[0] === heading) + 1;
  }
}
