import _ from "lodash";
import moment from "moment/moment";
import {
  DocumentRequest as PrismaDocumentRequest,
  DocumentUpload as PrismaDocumentUpload,
  DocumentUploadStatus,
  Prisma,
  Reminder,
} from "@prisma/client";
import { DocumentPeriodFormat } from "src/Enums/DocumentPeriodFormatEnum";
import { documentsWithMonthInNameWhenAnnualFreq } from "src/data/EntityToDocumentMapping";
import { FrequencyOptionEnum } from "src/Enums/FrequencyOptionEnum";
import z from "zod";
import { ExtractContext } from "src/backend/services/ocr/spread-data-aggregation/spread-data-aggregate";
import { DocumentQuality } from "src/Enums/DocumentQualityEnum";

export enum MonthlyPeriodEnum {
  Jan = "Jan",
  Feb = "Feb",
  Mar = "Mar",
  Apr = "Apr",
  May = "May",
  Jun = "Jun",
  Jul = "Jul",
  Aug = "Aug",
  Sep = "Sep",
  Oct = "Oct",
  Nov = "Nov",
  Dec = "Dec",
}

export const MonthlyPeriodSchema = z.nativeEnum(MonthlyPeriodEnum);

export enum YTDPeriodEnum {
  Jan = "Jan",
  Feb = "Feb",
  Mar = "Mar",
  Apr = "Apr",
  May = "May",
  Jun = "Jun",
  Jul = "Jul",
  Aug = "Aug",
  Sep = "Sep",
  Oct = "Oct",
  Nov = "Nov",
  Dec = "Dec",
}

export const YTDPeriodSchema = z.nativeEnum(YTDPeriodEnum);

export enum QuarterlyPeriodEnum {
  Q1 = "Q1",
  Q2 = "Q2",
  Q3 = "Q3",
  Q4 = "Q4",
}

export const QuarterlyPeriodSchema = z.nativeEnum(QuarterlyPeriodEnum);
export type ValidDocumentRequestPeriod =
  | "Annual"
  | MonthlyPeriodEnum
  | QuarterlyPeriodEnum
  | YTDPeriodEnum;
export type DocumentRequestPeriod = {
  year: string;
  period: ValidDocumentRequestPeriod;
  month: MonthlyPeriodEnum | null;
  quarter: QuarterlyPeriodEnum | null;
};

export function documentRequestPeriodToCadence(
  documentRequestPeriod: DocumentRequestPeriod,
): DocumentRequestCadence {
  if (documentRequestPeriod.period === "Annual") {
    return {
      end: moment.utc(documentRequestPeriod.year, "YYYY").endOf("year").toISOString(),
      periods: [
        {
          start: moment.utc(documentRequestPeriod.year, "YYYY").startOf("year").toISOString(),
          period: FrequencyOptionEnum.ANNUALLY,
          customPeriod: {
            count: 0,
            interval_type: "n/a",
          },
        },
      ],
    };
  } else if (documentRequestPeriod.period in MonthlyPeriodEnum) {
    return {
      end: moment.utc(documentRequestPeriod.year, "YYYY").endOf("year").toISOString(),
      periods: [
        {
          start: moment.utc(documentRequestPeriod.year, "YYYY").startOf("year").toISOString(),
          period: FrequencyOptionEnum.MONTHLY,
          customPeriod: {
            count: 0,
            interval_type: "n/a",
          },
        },
      ],
    };
  } else if (documentRequestPeriod.period in QuarterlyPeriodEnum) {
    return {
      end: moment.utc(documentRequestPeriod.year, "YYYY").endOf("year").toISOString(),
      periods: [
        {
          start: moment.utc(documentRequestPeriod.year, "YYYY").startOf("year").toISOString(),
          period: FrequencyOptionEnum.QUARTERLY,
          customPeriod: {
            count: 0,
            interval_type: "n/a",
          },
        },
      ],
    };
  } else if (documentRequestPeriod.period in YTDPeriodEnum) {
    const month = documentRequestPeriod.period;
    // end period is end of the month given in the period; rather than the end of the year.
    // e.g. if month is "Mar", then endPeriod is end of March.
    const end = moment.utc(documentRequestPeriod.year).month(month).endOf("month").toISOString();
    return {
      end,
      periods: [
        {
          start: moment.utc(documentRequestPeriod.year, "YYYY").startOf("year").toISOString(),
          period: FrequencyOptionEnum.CUSTOM,
          customPeriod: {
            count: 0,
            interval_type: "months",
          },
        },
      ],
    };
  } else {
    throw new Error("Invalid documentRequestPeriod");
  }
}

export interface UploadDocumentResponse {
  documentRequestId: number;
  documentUploadId: number;
  documentUrl: string;
  documentType: {
    id: number;
    name: string;
    year: string;
    month: string | null;
    quarter: string | null;
  };
  isSuccessful: boolean;
  documentName: string;
  entityId: number;
  failureReason: string | null;
  attributes: DocumentRequestAttributes;
  metadata: Record<string, any>;
}

export interface DocumentUpload {
  id: number;
  status: string;
  requestedOn: string;
  collectedOn: string | null;
  rawUploadUrl: string | null;
  collectionFailedOn: string | null;
}

// Note: I believe this interface is not exactly correct.
// I believe this is what is returned from loanService.
export interface DocumentRequest {
  id: number;
  reportingSequence: number;
  cadence: DocumentRequestCadence;
  type: {
    id: number;
    name: string;
  } & DocumentRequestAttributes;
  documentUpload: DocumentUpload[];
  notes?: string;
  successorId?: number;
  attributes?: DocumentRequestAttributes;
  metadata: Record<string, any>;
}

// The type of "attributes" in the document_request table.
export interface DocumentRequestAttributes extends Prisma.JsonObject {
  year: number;
  month?: string;
  quarter?: string;
  half?: string;
  ytd?: string;
  quality?: DocumentQuality;
  displayName?: string;
  to?: number[];
  from?: number;
  loanNumbers?: string[];
  masterLeaseNumber?: string | null | undefined;
  defaultPeriodFormat?: DocumentPeriodFormat;
  uniqueIdentifier?: string;
  name?: string;
  docType?: string;
  dueDate?: string;
  extractContext?: ExtractContext;
  updatedAt?: string;
  label?: string;
  subQuestionIndex?: number;
}

export type DocumentRequestWithDocumentUpload =
  | (PrismaDocumentRequest & { documentUpload: PrismaDocumentUpload[] })
  | (PrismaDocumentRequest & { DocumentUpload: PrismaDocumentUpload[] });

type DocumentRequestOrReminder = DocumentRequestWithDocumentUpload | Reminder;

export const isDocumentRequest = (obj: any): obj is PrismaDocumentRequest => {
  return obj && typeof obj.reportingSequenceId === "number";
};

/**
 * These document types WILL NOT be shown in the dropdown
 * Fiscal year end Income Statement
 * Fiscal year end Balance Sheet
 * YTD Income Statement
 * YTD Balance Sheet
 * Rent Roll
 * Leases
 * Personal tax return and affiliated Schedule K-1's
 * Financials
 * Audited Financial Statement
 * Cash Flow Statements
 * Reviewed Financial Statement
 * Income Statement
 * Balance Sheet
 * Corporate tax return
 * Schedule K-1's / Corporate tax return
 * Personal financial statement
 * Business debt schedule
 * AR aging schedule
 * AP aging schedule
 * Borrowing base certificate (BBC)
 * Projected income statement - * This one has a special logic that puts it for the next year instead of this year
 * Personal bank statement
 */
export const DOCUMENT_TYPES_WITH_DEFAULT_PERIODS: string[] = [
  "AR aging schedule",
  "AP aging schedule",
  "Audited Financial Statement",
  "Balance Sheet",
  "Borrowing base certificate (BBC)",
  "Business debt schedule",
  "Cash Flow Statements",
  "Corporate tax return",
  "Financials",
  "Fiscal year end Income Statement",
  "Fiscal year end Balance Sheet",
  "Income Statement",
  "Leases",
  "Personal bank statement",
  "Personal financial statement",
  "Personal tax return",
  "Personal tax return and affiliated Schedule K-1's",
  "Projected income statement",
  "Rent Roll",
  "Reviewed Financial Statement",
  "Schedule K-1's / Corporate tax return",
  "YTD Balance Sheet",
  "YTD Income Statement",
  "YTD financial statements",
];

export type CustomFrequencyIntervalType = "weeks" | "months" | "n/a";

/**
 * The cadence of a document request.
 * {
 *   "end": "2024-05-01T05:00:00.000Z",
 *   "periods": [
 *     {
 *       "start": "2024-04-17T05:00:00.000Z",
 *       "period": "Biannually",
 *       "customPeriod": {
 *         count: 0,
 *         interval_type: "",
 *       }
 *     }
 *   ]
 * }
 */
export type DocumentRequestCadence = {
  end: string;
  periods: {
    start: string;
    period: FrequencyOptionEnum;
    customPeriod: {
      count: number;
      interval_type: CustomFrequencyIntervalType;
    };
  }[];
};

export type SupportedAttributes = {
  year: number;
  month?: string;
  quarter?: string;
  half?: string;
  ytd?: string;
  masterLeaseNumber?: string | null;
  loanNumbers?: string[];
  subQuestionIndex?: number;
  quality?: DocumentQuality;
};

const LIST_OF_PERIOD_ATTRIBUTES: (keyof SupportedAttributes)[] = [
  "year",
  "month",
  "quarter",
  "half",
  "ytd",
];

const LIST_OF_CONTEXTUAL_ATTRIBUTES: (keyof SupportedAttributes)[] = [
  "masterLeaseNumber",
  "loanNumbers",
  "subQuestionIndex",
];

export const LIST_OF_SUPPORT_ATTRIBUTES: (keyof SupportedAttributes)[] = [
  ...LIST_OF_PERIOD_ATTRIBUTES,
  ...LIST_OF_CONTEXTUAL_ATTRIBUTES,
];

export const generateDocumentPeriod = (documentTypeName: string): DocumentPeriodFormat => {
  if (DOCUMENT_TYPES_WITH_DEFAULT_PERIODS.includes(documentTypeName)) {
    return DocumentPeriodFormat.DEFAULT;
  } else {
    return DocumentPeriodFormat.DUE_DATE;
  }
};

// Has this DR started?
export const shouldShow = (documentRequest: DocumentRequest): boolean => {
  const now = moment.utc().startOf("day");
  const startDate = _.get(documentRequest, "cadence.periods.[0].start", false);
  if (!startDate) {
    return false;
  } else {
    return moment.utc(startDate).startOf("day").isSameOrBefore(now);
  }
};

export const filterWaivedDocuments = (documentRequests: DocumentRequest[]): DocumentRequest[] => {
  return documentRequests.filter((documentRequest) => {
    const latestDocumentUpload = _.last(
      _.sortBy<DocumentUpload>(_.get(documentRequest, "documentUpload", []), "id"),
    );
    return latestDocumentUpload && latestDocumentUpload?.status !== DocumentUploadStatus.WAIVED;
  });
};

export const genAttributeKey = (attr: SupportedAttributes): string => {
  const results: string[] = [];
  LIST_OF_SUPPORT_ATTRIBUTES.forEach((attrName, index: number) => {
    const attributeValue = attr[attrName];
    if (attributeValue) {
      if (Array.isArray(attributeValue)) {
        results.push(`${attributeValue.sort()}`);
      } else {
        results.push(`${attributeValue}`);
      }
    }
  });
  return _.compact(results).join("-");
};

export const genAttributeKeyVariations = (attributes: SupportedAttributes): string[] => {
  const results: string[] = [];
  const contextualAttributes = _.pick(attributes, LIST_OF_CONTEXTUAL_ATTRIBUTES);
  let growingAttributes: SupportedAttributes = {
    ...contextualAttributes,
  };

  LIST_OF_PERIOD_ATTRIBUTES.forEach((attributeName) => {
    const attributeValue = _.get(attributes, attributeName, undefined);
    if (attributeValue) {
      growingAttributes = {
        ...growingAttributes,
        [attributeName]: attributeValue,
      };
      results.push(genAttributeKey(growingAttributes));
    }
  });
  return results;
};

export const createPertinentAttributes = (attributes: Prisma.JsonValue): SupportedAttributes => {
  return {
    year: _.get(attributes, "year", 0) as number,
    month: _.get(attributes, "month", undefined) as string,
    quarter: _.get(attributes, "quarter", undefined) as string,
    half: _.get(attributes, "half", undefined) as string,
    ytd: _.get(attributes, "ytd", undefined) as string,
    masterLeaseNumber: _.get(attributes, "masterLeaseNumber", undefined) as string,
    loanNumbers: _.get(attributes, "loanNumbers", undefined) as string[],
    subQuestionIndex: _.get(attributes, "subQuestionIndex", undefined) as number,
    quality: _.get(attributes, "quality", DocumentQuality.NONE) as DocumentQuality,
  };
};

export type AttributesKeyToDocumentRequestOrReminderList = {
  [key in string]: DocumentRequestOrReminder[];
};

export const removeItemFromAttributeKey = (
  attributesKeyToDocumentRequestOrReminder: AttributesKeyToDocumentRequestOrReminderList,
  attributeKey: string,
): void => {
  const attributeValueArray = _.get(
    attributesKeyToDocumentRequestOrReminder,
    attributeKey,
    undefined,
  );
  if (attributeValueArray) {
    attributesKeyToDocumentRequestOrReminder[attributeKey].shift();
    if (attributeValueArray.length === 1) {
      delete attributesKeyToDocumentRequestOrReminder[attributeKey];
    }
  }
};

export const genAttributeKeyVariationsAndIdSet = (
  objArray: DocumentRequestOrReminder[],
): {
  attributeKeyToRecord: AttributesKeyToDocumentRequestOrReminderList;
  objIdSet: Set<number>;
} => {
  const attributeKeyToRecord: AttributesKeyToDocumentRequestOrReminderList = {};
  const objIdSet: Set<number> = new Set();
  objArray.forEach((obj) => {
    const attributes = obj.attributes;
    const pertinentAttributes = createPertinentAttributes(attributes);
    const attributeKeys = genAttributeKeyVariations(pertinentAttributes);
    objIdSet.add(obj.id);
    attributeKeys.forEach((key) => {
      const fullKey = `${obj.typeId}-${obj.entityId}-${key}`;
      if (!attributeKeyToRecord[fullKey]) {
        attributeKeyToRecord[fullKey] = [obj];
      }
      attributeKeyToRecord[fullKey].push(obj);
    });
  });

  return { attributeKeyToRecord, objIdSet };
};

// NOTE(@dpow101): This is a helper method that converts a request date and a period
// to a human-readable object with Quarter, month and year to be used in documentRequest
// attributes. That then will power the FE to show Q2 2022, or JUL 2022, etc.
//
// Note: this method gives us the period of the document that we want to request _on_ requestDate.
// For example, if requestDate is 2022-01-10, and period is "Quarterly",
// then the period of the document we want is Q4 2021.
// (It's the "previous period" with respect to requestDate;
// we're not requesting the document for Q1 2022 because it doesn't exist yet!)
export const calculatePreviousPeriod = (
  requestDate: string,
  period: FrequencyOptionEnum,
  documentTypeName: string,
): DocumentRequestAttributes => {
  // NOTE(@dpow101): The FCN team is requesting the document
  // close to the end of the period - e.g., 3/31/2023 to
  // request the Q1 2023 financial; hence the adding of 7 day
  let date = moment(requestDate).add(7, "days");
  const defaultPeriodFormat = generateDocumentPeriod(documentTypeName);
  switch (period) {
    case "Annually":
      if (documentTypeName === "Financial statements for the same period YTD") {
        // This is the only document type (as of July 2023) that has a special interpretation:
        // it means "Financial statements YTD, for the same period as last year".
        // See: https://interfoldai.slack.com/archives/C045QCCK9CL/p1689815757201969
        date.subtract(2, "years");
      } else {
        date.subtract(1, "years");
      }

      if (documentsWithMonthInNameWhenAnnualFreq.includes(documentTypeName)) {
        date.subtract(1, "months");
        date.add(1, "years");
        return {
          year: date.year(),
          month: date.format("MMM"),
          defaultPeriodFormat,
        };
      } else {
        return {
          year: date.year(),
          defaultPeriodFormat,
        };
      }
    case "Biannually":
      date.subtract(6, "months");
      return {
        year: date.year(),
        half: date.month() < 6 ? "H1" : "H2",
        defaultPeriodFormat,
      };
    case "Quarterly":
      date.subtract(3, "months");
      return {
        year: date.year(),
        quarter: `Q${date.quarter()}`,
        defaultPeriodFormat,
      };
    case "Monthly":
      date.subtract(1, "months");
      return {
        year: date.year(),
        month: date.format("MMM"),
        defaultPeriodFormat,
      };
    default:
      return {
        year: date.year(),
        defaultPeriodFormat,
      };
  }
};

export const calculateNextPeriod = (
  frequency: FrequencyOptionEnum,
  newStartDate: string,
  previousPeriod: DocumentRequestAttributes,
): DocumentRequestAttributes => {
  if (frequency === FrequencyOptionEnum.MONTHLY && previousPeriod.month) {
    const m = moment(`${previousPeriod.month} 01, ${previousPeriod.year}`, "MMM DD, YYYY").add(
      1,
      "month",
    );
    return { ...previousPeriod, year: m.year(), month: m.format("MMM") };
  }

  if (frequency === FrequencyOptionEnum.QUARTERLY && previousPeriod.quarter) {
    let nextYear = previousPeriod.year;
    let nextQuarter: string;
    const quarterNumber = parseInt(previousPeriod.quarter.slice(1));
    if (quarterNumber === 4) {
      nextYear++;
      nextQuarter = "Q1";
    } else {
      nextQuarter = "Q" + (quarterNumber + 1);
    }
    return { ...previousPeriod, year: nextYear, quarter: nextQuarter };
  }

  if (frequency === FrequencyOptionEnum.BIANNUALLY && previousPeriod.half) {
    let nextYear = previousPeriod.year;
    let nextHalf: string;
    if (previousPeriod.half === "H2") {
      nextYear++;
      nextHalf = "H1";
    } else {
      nextHalf = "H2";
    }
    return { ...previousPeriod, year: nextYear, half: nextHalf };
  }

  if (frequency === FrequencyOptionEnum.ANNUALLY && previousPeriod.year) {
    const newYear = previousPeriod.year + 1;
    return {
      ...previousPeriod,
      year: newYear,
      ...fixMonthInAnnualRequestIfNeeded(newStartDate, newYear, previousPeriod.month),
    };
  }
  //TODO: handle custom here

  // probably one-time, nothing to bump
  return { ...previousPeriod };
};

/**
 * If for some reason the Month was bumped in an existing request that does not correspond to the start date. This fixes it.
 * It only applies to new request created for this/current year. This is to avoid asking for future months data current year,
 * like asking Aug 2024 on April 2024 etc.,
 */
const fixMonthInAnnualRequestIfNeeded = (
  newStart: string,
  newYear: number,
  month: string | undefined,
): { month: string } | {} => {
  if (month && newYear === moment.utc().year()) {
    const m = moment(newStart).utc();
    const endOfM = moment(newStart).utc().endOf("month");
    const isLast7DaysOfMonth = m.date() > endOfM.date() - 7;
    return { month: isLast7DaysOfMonth ? m.format("MMM") : m.subtract(1, "months").format("MMM") };
  }
  return {};
};

const DOCUMENT_PERIOD_FORMAT_MAPPING = {
  [DocumentPeriodFormat.DEFAULT]: (attributes: DocumentRequestAttributes): string => {
    const { year, month, quarter, half, name, docType, ytd } = attributes;
    if (
      (name && !DOCUMENT_TYPES_WITH_DEFAULT_PERIODS.includes(name)) ||
      (docType && !DOCUMENT_TYPES_WITH_DEFAULT_PERIODS.includes(docType))
    ) {
      return "";
    } else {
      return [quarter, half, month, year, ytd].filter((value) => value).join(" ");
    }
  },
  [DocumentPeriodFormat.DUE_DATE]: (attributes: DocumentRequestAttributes): string => {
    return attributes?.dueDate ? moment(attributes.dueDate).format("MM/DD/YYYY") : "";
  },
  [DocumentPeriodFormat.YEAR]: (attributes: DocumentRequestAttributes): string => {
    const { year } = attributes;
    return year.toString();
  },
  [DocumentPeriodFormat.NO_DATE]: (_attributes: DocumentRequestAttributes): string => {
    return "";
  },
};

export const getDocumentPeriodText = (attributes: DocumentRequestAttributes): string => {
  const documentPeriodFormat = _.get(
    attributes,
    "defaultPeriodFormat",
    DocumentPeriodFormat.DEFAULT,
  );

  if (!DOCUMENT_PERIOD_FORMAT_MAPPING[documentPeriodFormat]) {
    return DOCUMENT_PERIOD_FORMAT_MAPPING[DocumentPeriodFormat.DEFAULT](attributes);
  } else {
    return DOCUMENT_PERIOD_FORMAT_MAPPING[documentPeriodFormat](attributes);
  }
};

// In the instance where a document request is not yet fully created by the backend,
// we need to take in the parameters from the document request context and
// generate the document period text.
export const getDocumentPeriodTextFromDocumentRequestContext = (
  requestDate: string,
  period: FrequencyOptionEnum,
  documentTypeName: string,
): string => {
  const previousPeriod = calculatePreviousPeriod(requestDate, period, documentTypeName);
  return getDocumentPeriodText(previousPeriod);
};
