export type ThemeObject = {
  [key in "1" | "2" | "3" | "4" | "5" | "6" | "7"]: {
    // primary
    "--theme-primary-400": string;
    "--theme-primary-600": string;
    "--theme-primary-800": string;
    "--theme-primary-900": string;

    // secondary
    "--theme-secondary-600": string;
    "--theme-secondary-800": string;

    // blue
    "--theme-blue-400": string;
    "--theme-blue-800": string;

    // orange
    "--theme-orange-900": string;

    // gray
    "--theme-gray-400": string;
    "--theme-gray-700": string;
    "--theme-gray-800": string;
    "--theme-gray-900": string;

    // slate
    "--theme-slate-100": string;
    "--theme-slate-400": string;

    // white
    "--theme-white-600": string;
    "--theme-white-700": string;
    "--theme-white-800": string;

    // yellow
    "--theme-yellow-600": string;
    "--theme-yellow-900": string;

    // sidebar
    "--theme-sidebar": string;

    // green
    "--theme-green-900": string;

    // text colors
    "--theme-dark-text": string;
    "--theme-light-text": string;
    "--theme-hint-text": string;
    "--theme-mid-text": string;

    // border colors
    "--theme-dark-border": string;
    "--theme-light-border": string;
    "--theme-hint-border": string;

    // tag colors
    "--theme-tag-800": string;

    // progress bar colors
    "--theme-progress": string;

    "--theme-activeTab": string;

    // button colors
    "--theme-button-primary": string;
    "--theme-button-secondary": string;
    "--theme-button-notification": string;
    "--theme-button-tag": string;

    // sidebar colors
    "--theme-sidebar-background": string;
    "--theme-sidebar-borrowerBackground": string;
    "--theme-sidebar-bankerLogoBackground": string;
    "--theme-sidebar-text": string;
    "--theme-sidebar-nonCompleted": string;
    "--theme-sidebar-active": string;
    "--theme-sidebar-nonActive": string;
    "--theme-sidebar-userAbbreviation": string;

    // input
    "--theme-input-checkbox": string;
    "--theme-input-radio": string;

    // theme text
    "--theme-theme-text-notification": string;
    "--theme-theme-text-investmentHeader": string;
    "--theme-theme-text-warning": string;
    "--theme-theme-text-warning-highlight": string;
    "--theme-theme-text-checked": string;

    // ag-grid
    "--ag-header-height": string;
  };
};

export const isValidTheme = (theme?: string | undefined | null): theme is keyof ThemeObject => {
  return ["1", "2", "3", "4", "5", "6", "7"].includes(theme ?? "");
};

export const theme: ThemeObject = {
  // Base theme
  "1": {
    // primary
    "--theme-primary-400": "#727272",
    "--theme-primary-600": "#616F81",
    "--theme-primary-800": "#434F5D",
    "--theme-primary-900": "#3C4554",

    // secondary
    "--theme-secondary-600": "#BCEBFF",
    "--theme-secondary-800": "#E0E0E0",

    // blue
    "--theme-blue-400": "#2186BE",
    "--theme-blue-800": "#014368",

    // orange
    "--theme-orange-900": "#BF7300",

    // gray
    "--theme-gray-400": "#373737",
    "--theme-gray-700": "#4F4F4F",
    "--theme-gray-800": "#828282",
    "--theme-gray-900": "#212121",

    // slate
    "--theme-slate-100": "#FBFCFD",
    "--theme-slate-400": "#E7EDF4",

    // white
    "--theme-white-600": "#F2F2F2",
    "--theme-white-700": "#FFFFFF",
    "--theme-white-800": "#F6F7FB",

    // yellow
    "--theme-yellow-600": "#DFC648",
    "--theme-yellow-900": "#998000",

    // sidebar
    "--theme-sidebar": "#F1F1F1",

    // green
    "--theme-green-900": "#006736",

    // text colors
    "--theme-dark-text": "#13304B",
    "--theme-light-text": "#0000DE",
    "--theme-hint-text": "#00008A",
    "--theme-mid-text": "#333333",

    // border colors
    "--theme-dark-border": "#13304B",
    "--theme-light-border": "#2186BE",
    "--theme-hint-border": "#0000006B",

    // tag colors
    "--theme-tag-800": "#6F7E9A",

    // progress bar colors
    "--theme-progress": "#998000",

    // active tab color
    "--theme-activeTab": "#2186BE",

    // button colors
    "--theme-button-primary": "#2186BE",
    "--theme-button-secondary": "#828282",
    "--theme-button-notification": "#014368",
    "--theme-button-tag": "#6F7E9A",

    // sidebar colors
    "--theme-sidebar-background": "#F1F1F1",
    "--theme-sidebar-borrowerBackground": "#434F5D",
    "--theme-sidebar-bankerLogoBackground": "#434F5D",
    "--theme-sidebar-nonCompleted": "#FFFFFF",
    "--theme-sidebar-text": "#F6F7FB",
    "--theme-sidebar-active": "#DFC648",
    "--theme-sidebar-nonActive": "#998000",
    "--theme-sidebar-userAbbreviation": "#DFC648",

    // input
    "--theme-input-checkbox": "#014368",
    "--theme-input-radio": "#473366",

    // theme text
    "--theme-theme-text-notification": "#BF7300",
    "--theme-theme-text-investmentHeader": "#014368",
    "--theme-theme-text-warning": "#998000",
    "--theme-theme-text-warning-highlight": "#F5F2E5",
    "--theme-theme-text-checked": "#014368",
    "--ag-header-height": "2.5rem",
  },
  // Centrust Bank
  "2": {
    // primary
    "--theme-primary-400": "#727272",
    "--theme-primary-600": "#616F81",
    "--theme-primary-800": "#434F5D",
    "--theme-primary-900": "#3C4554",

    // secondary
    "--theme-secondary-600": "#BCEBFF",
    "--theme-secondary-800": "#E0E0E0",

    // blue
    "--theme-blue-400": "#2186BE",
    "--theme-blue-800": "#014368",

    // orange
    "--theme-orange-900": "#BF7300",

    // gray
    "--theme-gray-400": "#373737",
    "--theme-gray-700": "#4F4F4F",
    "--theme-gray-800": "#828282",
    "--theme-gray-900": "#212121",

    // slate
    "--theme-slate-100": "#FBFCFD",
    "--theme-slate-400": "#E7EDF4",

    // white
    "--theme-white-600": "#F2F2F2",
    "--theme-white-700": "#FFFFFF",
    "--theme-white-800": "#F6F7FB",

    // yellow
    "--theme-yellow-600": "#DFC648",
    "--theme-yellow-900": "#998000",

    // sidebar
    "--theme-sidebar": "#F1F1F1",

    // green
    "--theme-green-900": "#006736",

    // text colors
    "--theme-dark-text": "#13304B",
    "--theme-light-text": "#0000DE",
    "--theme-hint-text": "#00008A",
    "--theme-mid-text": "#333333",

    // border colors
    "--theme-dark-border": "#13304B",
    "--theme-light-border": "#007BFF",
    "--theme-hint-border": "#0000006B",

    // tag colors
    "--theme-tag-800": "#6F7E9A",

    // progress bar colors
    "--theme-progress": "#3DAE2B",

    // active tab color
    "--theme-activeTab": "#007BFF",

    // button colors
    "--theme-button-primary": "#007BFF",
    "--theme-button-secondary": "#828282",
    "--theme-button-notification": "#042D76",
    "--theme-button-tag": "#6F7E9A",

    // sidebar colors
    "--theme-sidebar-background": "#F1F1F1",
    "--theme-sidebar-borrowerBackground": "#024B94",
    "--theme-sidebar-bankerLogoBackground": "#024B94",
    "--theme-sidebar-nonCompleted": "#FFFFFF",
    "--theme-sidebar-text": "#F6F7FB",
    "--theme-sidebar-active": "#2E8FDC",
    "--theme-sidebar-nonActive": "#2E8FDC",
    "--theme-sidebar-userAbbreviation": "#DFC648",

    // input
    "--theme-input-checkbox": "#8B8B8B",
    "--theme-input-radio": "#8B8B8B",

    // theme text
    "--theme-theme-text-notification": "#BF7300",
    "--theme-theme-text-investmentHeader": "#007BFF",
    "--theme-theme-text-warning": "#998000",
    "--theme-theme-text-warning-highlight": "#F5F2E5",
    "--theme-theme-text-checked": "#65B2FF",
    "--ag-header-height": "2.5rem",
  },
  // FCN Bank
  "3": {
    // primary
    "--theme-primary-400": "#727272",
    "--theme-primary-600": "#616F81",
    "--theme-primary-800": "#434F5D",
    "--theme-primary-900": "#3C4554",

    // secondary
    "--theme-secondary-600": "#BCEBFF",
    "--theme-secondary-800": "#E0E0E0",

    // blue
    "--theme-blue-400": "#2186BE",
    "--theme-blue-800": "#014368",

    // orange
    "--theme-orange-900": "#BF7300",

    // gray
    "--theme-gray-400": "#373737",
    "--theme-gray-700": "#4F4F4F",
    "--theme-gray-800": "#828282",
    "--theme-gray-900": "#212121",

    // slate
    "--theme-slate-100": "#FBFCFD",
    "--theme-slate-400": "#E7EDF4",

    // white
    "--theme-white-600": "#F2F2F2",
    "--theme-white-700": "#FFFFFF",
    "--theme-white-800": "#F6F7FB",

    // yellow
    "--theme-yellow-600": "#DFC648",
    "--theme-yellow-900": "#998000",

    // sidebar
    "--theme-sidebar": "#F1F1F1",

    // green
    "--theme-green-900": "#006736",

    // text colors
    "--theme-dark-text": "#13304B",
    "--theme-light-text": "#0000DE",
    "--theme-hint-text": "#00008A",
    "--theme-mid-text": "#333333",

    // border colors
    "--theme-dark-border": "#13304B",
    "--theme-light-border": "#2EA3F2",
    "--theme-hint-border": "#0000006B",

    // tag colors
    "--theme-tag-800": "#6F7E9A",

    // progress bar colors
    "--theme-progress": "#359024",

    // active tab color
    "--theme-activeTab": "#28701D",

    // button colors
    "--theme-button-primary": "#195CA3",
    "--theme-button-secondary": "#2EA3F2",
    "--theme-button-notification": "#195CA3",
    "--theme-button-tag": "#6F7E9A",

    // sidebar colors
    "--theme-sidebar-background": "#F1F1F1",
    "--theme-sidebar-borrowerBackground": "#F2F2F2",
    "--theme-sidebar-bankerLogoBackground": "#1B5016",
    "--theme-sidebar-nonCompleted": "#D7D7D7",
    "--theme-sidebar-text": "#5B5B66",
    "--theme-sidebar-active": "#43B02A",
    "--theme-sidebar-nonActive": "#1B5016",
    "--theme-sidebar-userAbbreviation": "#DCF0CB",

    // input
    "--theme-input-checkbox": "#014368",
    "--theme-input-radio": "#473366",

    // theme text
    "--theme-theme-text-notification": "#BF7300",
    "--theme-theme-text-investmentHeader": "#014368",
    "--theme-theme-text-warning": "#998000",
    "--theme-theme-text-warning-highlight": "#F5F2E5",
    "--theme-theme-text-checked": "#014368",
    "--ag-header-height": "2.5rem",
  },
  // Prime Alliance Bank
  "4": {
    // primary
    "--theme-primary-400": "#727272",
    "--theme-primary-600": "#616F81",
    "--theme-primary-800": "#434F5D",
    "--theme-primary-900": "#3C4554",

    // secondary
    "--theme-secondary-600": "#BCEBFF",
    "--theme-secondary-800": "#E0E0E0",

    // blue
    "--theme-blue-400": "#2186BE",
    "--theme-blue-800": "#014368",

    // orange
    "--theme-orange-900": "#BF7300",

    // gray
    "--theme-gray-400": "#373737",
    "--theme-gray-700": "#4F4F4F",
    "--theme-gray-800": "#828282",
    "--theme-gray-900": "#212121",

    // slate
    "--theme-slate-100": "#FBFCFD",
    "--theme-slate-400": "#E7EDF4",

    // white
    "--theme-white-600": "#F2F2F2",
    "--theme-white-700": "#FFFFFF",
    "--theme-white-800": "#F6F7FB",

    // yellow
    "--theme-yellow-600": "#DFC648",
    "--theme-yellow-900": "#998000",

    // sidebar
    "--theme-sidebar": "#F1F1F1",

    // green
    "--theme-green-900": "#006736",

    // text colors
    "--theme-dark-text": "#13304B",
    "--theme-light-text": "#0000DE",
    "--theme-hint-text": "#00008A",
    "--theme-mid-text": "#333333",

    // border colors
    "--theme-dark-border": "#13304B",
    "--theme-light-border": "#2186BE",
    "--theme-hint-border": "#0000006B",

    // tag colors
    "--theme-tag-800": "#6F7E9A",

    // progress bar colors
    "--theme-progress": "#7C9FB0",

    // active tab color
    "--theme-activeTab": "#7C9FB0",

    // button colors
    "--theme-button-primary": "#2186BE",
    "--theme-button-secondary": "#5B5B66",
    "--theme-button-notification": "#771220",
    "--theme-button-tag": "#6F7E9A",

    // sidebar colors
    "--theme-sidebar-background": "#F1F1F1",
    "--theme-sidebar-borrowerBackground": "#E0E0E0",
    "--theme-sidebar-bankerLogoBackground": "#032638",
    "--theme-sidebar-nonCompleted": "#5B5B66",
    "--theme-sidebar-text": "#5B5B66",
    "--theme-sidebar-active": "#CF3438",
    "--theme-sidebar-nonActive": "#0F3C60",
    "--theme-sidebar-userAbbreviation": "#007BFF1A",

    // input
    "--theme-input-checkbox": "#014368",
    "--theme-input-radio": "#473366",

    // theme text
    "--theme-theme-text-notification": "#BF7300",
    "--theme-theme-text-investmentHeader": "#014368",
    "--theme-theme-text-warning": "#998000",
    "--theme-theme-text-warning-highlight": "#F5F2E5",
    "--theme-theme-text-checked": "#014368",
    "--ag-header-height": "2.5rem",
  },
  // Lamar National Bank
  "5": {
    // primary
    "--theme-primary-400": "#727272",
    "--theme-primary-600": "#616F81",
    "--theme-primary-800": "#434F5D",
    "--theme-primary-900": "#3C4554",

    // secondary
    "--theme-secondary-600": "#BCEBFF",
    "--theme-secondary-800": "#E0E0E0",

    // blue
    "--theme-blue-400": "#2186BE",
    "--theme-blue-800": "#014368",

    // orange
    "--theme-orange-900": "#BF7300",

    // gray
    "--theme-gray-400": "#373737",
    "--theme-gray-700": "#4F4F4F",
    "--theme-gray-800": "#828282",
    "--theme-gray-900": "#212121",

    // slate
    "--theme-slate-100": "#FBFCFD",
    "--theme-slate-400": "#E7EDF4",

    // white
    "--theme-white-600": "#F2F2F2",
    "--theme-white-700": "#FFFFFF",
    "--theme-white-800": "#F6F7FB",

    // yellow
    "--theme-yellow-600": "#DFC648",
    "--theme-yellow-900": "#998000",

    // sidebar
    "--theme-sidebar": "#F1F1F1",

    // green
    "--theme-green-900": "#006736",

    // text colors
    "--theme-dark-text": "#13304B",
    "--theme-light-text": "#0000DE",
    "--theme-hint-text": "#00008A",
    "--theme-mid-text": "#333333",

    // border colors
    "--theme-dark-border": "#13304B",
    "--theme-light-border": "#2186BE",
    "--theme-hint-border": "#0000006B",

    // tag colors
    "--theme-tag-800": "#6F7E9A",

    // progress bar colors
    "--theme-progress": "#7C9FB0",

    // active tab color
    "--theme-activeTab": "#7C9FB0",

    // button colors
    "--theme-button-primary": "#2186BE",
    "--theme-button-secondary": "#5B5B66",
    "--theme-button-notification": "#771220",
    "--theme-button-tag": "#6F7E9A",

    // sidebar colors
    "--theme-sidebar-background": "#F1F1F1",
    "--theme-sidebar-borrowerBackground": "#032638",
    "--theme-sidebar-bankerLogoBackground": "#032638",
    "--theme-sidebar-nonCompleted": "#5B5B66",
    "--theme-sidebar-text": "#5B5B66",
    "--theme-sidebar-active": "#CF3438",
    "--theme-sidebar-nonActive": "#0F3C60",
    "--theme-sidebar-userAbbreviation": "#007BFF1A",

    // input
    "--theme-input-checkbox": "#014368",
    "--theme-input-radio": "#473366",

    // theme text
    "--theme-theme-text-notification": "#BF7300",
    "--theme-theme-text-investmentHeader": "#014368",
    "--theme-theme-text-warning": "#998000",
    "--theme-theme-text-warning-highlight": "#F5F2E5",
    "--theme-theme-text-checked": "#014368",
    "--ag-header-height": "2.5rem",
  },
  // Capital Bank Texas
  "6": {
    // primary
    "--theme-primary-400": "#727272",
    "--theme-primary-600": "#616F81",
    "--theme-primary-800": "#434F5D",
    "--theme-primary-900": "#3C4554",

    // secondary
    "--theme-secondary-600": "#BCEBFF",
    "--theme-secondary-800": "#E0E0E0",

    // blue
    "--theme-blue-400": "#2186BE",
    "--theme-blue-800": "#014368",

    // orange
    "--theme-orange-900": "#BF7300",

    // gray
    "--theme-gray-400": "#373737",
    "--theme-gray-700": "#4F4F4F",
    "--theme-gray-800": "#828282",
    "--theme-gray-900": "#212121",

    // slate
    "--theme-slate-100": "#FBFCFD",
    "--theme-slate-400": "#E7EDF4",

    // white
    "--theme-white-600": "#F2F2F2",
    "--theme-white-700": "#FFFFFF",
    "--theme-white-800": "#F6F7FB",

    // yellow
    "--theme-yellow-600": "#DFC648",
    "--theme-yellow-900": "#998000",

    // sidebar
    "--theme-sidebar": "#F1F1F1",

    // green
    "--theme-green-900": "#006736",

    // text colors
    "--theme-dark-text": "#13304B",
    "--theme-light-text": "#0000DE",
    "--theme-hint-text": "#00008A",
    "--theme-mid-text": "#333333",

    // border colors
    "--theme-dark-border": "#13304B",
    "--theme-light-border": "#2EA3F2",
    "--theme-hint-border": "#0000006B",

    // tag colors
    "--theme-tag-800": "#6F7E9A",

    // progress bar colors
    "--theme-progress": "#359024",

    // active tab color
    "--theme-activeTab": "#28701D",

    // button colors
    "--theme-button-primary": "#195CA3",
    "--theme-button-secondary": "#2EA3F2",
    "--theme-button-notification": "#195CA3",
    "--theme-button-tag": "#6F7E9A",

    // sidebar colors
    "--theme-sidebar-background": "#F1F1F1",
    "--theme-sidebar-borrowerBackground": "#ECECDB",
    "--theme-sidebar-bankerLogoBackground": "#ECECDB",
    "--theme-sidebar-nonCompleted": "#D7D7D7",
    "--theme-sidebar-text": "#5B5B66",
    "--theme-sidebar-active": "#43B02A",
    "--theme-sidebar-nonActive": "#1B5016",
    "--theme-sidebar-userAbbreviation": "#DCF0CB",

    // input
    "--theme-input-checkbox": "#014368",
    "--theme-input-radio": "#473366",

    // theme text
    "--theme-theme-text-notification": "#BF7300",
    "--theme-theme-text-investmentHeader": "#014368",
    "--theme-theme-text-warning": "#998000",
    "--theme-theme-text-warning-highlight": "#F5F2E5",
    "--theme-theme-text-checked": "#014368",
    "--ag-header-height": "2.5rem",
  },
  // American Commercial Bank & Trust
  "7": {
    // primary
    "--theme-primary-400": "#727272",
    "--theme-primary-600": "#616F81",
    "--theme-primary-800": "#434F5D",
    "--theme-primary-900": "#3C4554",

    // secondary
    "--theme-secondary-600": "#BCEBFF",
    "--theme-secondary-800": "#E0E0E0",

    // blue
    "--theme-blue-400": "#2186BE",
    "--theme-blue-800": "#014368",

    // orange
    "--theme-orange-900": "#BF7300",

    // gray
    "--theme-gray-400": "#373737",
    "--theme-gray-700": "#4F4F4F",
    "--theme-gray-800": "#828282",
    "--theme-gray-900": "#212121",

    // slate
    "--theme-slate-100": "#FBFCFD",
    "--theme-slate-400": "#E7EDF4",

    // white
    "--theme-white-600": "#F2F2F2",
    "--theme-white-700": "#FFFFFF",
    "--theme-white-800": "#F6F7FB",

    // yellow
    "--theme-yellow-600": "#DFC648",
    "--theme-yellow-900": "#998000",

    // sidebar
    "--theme-sidebar": "#F1F1F1",

    // green
    "--theme-green-900": "#006736",

    // text colors
    "--theme-dark-text": "#13304B",
    "--theme-light-text": "#0000DE",
    "--theme-hint-text": "#00008A",
    "--theme-mid-text": "#333333",

    // border colors
    "--theme-dark-border": "#13304B",
    "--theme-light-border": "#2186BE",
    "--theme-hint-border": "#0000006B",

    // tag colors
    "--theme-tag-800": "#6F7E9A",

    // progress bar colors
    "--theme-progress": "#7C9FB0",

    // active tab color
    "--theme-activeTab": "#7C9FB0",

    // button colors
    "--theme-button-primary": "#2186BE",
    "--theme-button-secondary": "#5B5B66",
    "--theme-button-notification": "#771220",
    "--theme-button-tag": "#6F7E9A",

    // sidebar colors
    "--theme-sidebar-background": "#F1F1F1",
    "--theme-sidebar-borrowerBackground": "#E0E0E0",
    "--theme-sidebar-bankerLogoBackground": "#211973",
    "--theme-sidebar-nonCompleted": "#5B5B66",
    "--theme-sidebar-text": "#5B5B66",
    "--theme-sidebar-active": "#CF3438",
    "--theme-sidebar-nonActive": "#0F3C60",
    "--theme-sidebar-userAbbreviation": "#007BFF1A",

    // input
    "--theme-input-checkbox": "#014368",
    "--theme-input-radio": "#473366",

    // theme text
    "--theme-theme-text-notification": "#BF7300",
    "--theme-theme-text-investmentHeader": "#014368",
    "--theme-theme-text-warning": "#998000",
    "--theme-theme-text-warning-highlight": "#F5F2E5",
    "--theme-theme-text-checked": "#014368",
    "--ag-header-height": "2.5rem",
  },
};
