import "../styles/globals.css";
import "@mantine/core/styles.css";
import "@mantine/code-highlight/styles.css";
import "@mantine/core/styles/Modal.css";

import { Chivo, Prata } from "next/font/google";
import type { AppProps } from "next/app";
import { DEFAULT_THEME, MantineProvider, createTheme } from "@mantine/core";
import { ReactElement, ReactNode, useEffect, useState } from "react";
import type { NextPage } from "next";
import { Provider } from "react-redux";
import { AppLoader } from "src/components/AppLoader/AppLoader";
import wrapper from "src/redux";
import { datadogRum } from "@datadog/browser-rum";
import { appShouldUseRUM, datadogRUMConfiguration } from "src/utils/environment";
import { applyTheme } from "src/themes/utils";
import { theme } from "src/themes/base";
import { UserProvider } from "@auth0/nextjs-auth0/client";
import _FeatureFlags from "src/services/feature-flags/FeatureFlags";

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const MOBILE_WARNING_PATH = "/mobile-warning";

// See: https://nextjs.org/docs/basic-features/font-optimization#with-tailwind-css
const chivo = Chivo({
  weight: ["200", "300", "400", "500", "600", "700"],
  variable: "--font-chivo",
  preload: false,
});

const prata = Prata({
  weight: ["400"],
  variable: "--font-prata",
  preload: false,
});

if (appShouldUseRUM) {
  datadogRum.init(datadogRUMConfiguration);
  datadogRum.startSessionReplayRecording();
}

export default function App({ Component, ...rest }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);
  const { store, props } = wrapper.useWrappedStore(rest);
  const [loading, setLoading] = useState(true);

  const colors = theme["1"];
  /*
    "--theme-button-primary": "#2186BE",
    "--theme-button-secondary": "#828282",
    "--theme-button-notification": "#014368",
    */
  type mantineColor = [
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
  ];
  const primary = new Array(10).fill(colors["--theme-button-primary"]) as mantineColor;
  const secondary = new Array(10).fill(colors["--theme-button-secondary"]) as mantineColor;
  const notification = new Array(10).fill(colors["--theme-button-notification"]) as mantineColor;
  const tag = new Array(10).fill(colors["--theme-button-tag"]) as mantineColor;
  const unstyled = new Array(10).fill("#6f7e9a") as mantineColor;

  const mantineTheme = createTheme({
    fontFamily: chivo.style.fontFamily,
    colors: {
      primary,
      secondary,
      notification,
      tag,
      unstyled,
    },
    headings: {
      fontFamily: `${chivo.style.fontFamily}, ${DEFAULT_THEME.fontFamily}`,
    },
    primaryColor: "primary",
  });

  useEffect(() => {
    setTimeout(() => setLoading(false), 500);
    applyTheme(theme["1"]);
    const width =
      window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (width < 800 && window.location.pathname !== MOBILE_WARNING_PATH) {
      window.location.assign("/mobile-warning");
    }
  }, []);

  return (
    <UserProvider>
      <>
        {!loading ? (
          <div className={`${prata.variable} ${chivo.variable} font-sans`}>
            <Provider store={store}>
              <MantineProvider theme={mantineTheme}>
                <_FeatureFlags />
                {/*<AppThemeSelector />*/}
                {getLayout(<Component {...props.pageProps} />)}
              </MantineProvider>
            </Provider>
          </div>
        ) : (
          <div className="w-screen h-screen flex items-center justify-center">
            <AppLoader size={15} variant="grid" dark />
          </div>
        )}
      </>
    </UserProvider>
  );
}
