import { EntityHydrated } from "src/models/entity";
import { Loan } from "src/models/Loan";
import _ from "lodash";
import entityToDocumentMapping, { DocumentItem } from "src/data/EntityToDocumentMapping";
import { DocumentRequestCadence } from "src/models/DocumentRequest";

type EntityPath = `${"borrower" | "guarantor"}.${"individual" | "company"}`;

const generateDocumentRequestByEntityType = (entityType: EntityPath, loan: Loan) => {
  const documentsToRequest: DocumentItem[] = _.get(entityToDocumentMapping, entityType, []);
  return documentsToRequest.map((document) => {
    return {
      id: -1,
      cadence: {} as unknown as DocumentRequestCadence,
      reportingSequence: -1,
      type: {
        id: document.id,
        name: document.name,
        year: document.year,
      },
      documentUpload: [
        {
          id: -1,
          status: "REQUESTED",
          rawUploadUrl: null,
          requestedOn: loan.originationDate,
          collectedOn: null,
          collectionFailedOn: null,
        },
      ],
      metadata: {},
    };
  });
};

export const defaultEntityObject = (loan: Loan): EntityHydrated[] => {
  return [
    {
      id: -1,
      role: "BORROWER",
      entityType: "COMPANY",
      asIndividual: undefined,
      asCompany: {
        id: -1,
        name: loan.accountName,
        email: "placeholder@email.com",
      },
      customerUserContacts: [],
      documentRequests: generateDocumentRequestByEntityType("borrower.company", loan),
      reminders: [],
    },
    {
      id: -1,
      role: "GUARANTOR",
      entityType: "COMPANY",
      asIndividual: undefined,
      asCompany: {
        id: -1,
        name: "To Be Provided",
        email: "placeholder@email.com",
      },
      customerUserContacts: [],
      documentRequests: generateDocumentRequestByEntityType("guarantor.company", loan),
      reminders: [],
    },
    {
      id: -1,
      role: "GUARANTOR",
      entityType: "INDIVIDUAL",
      asIndividual: {
        id: -1,
        name: "To Be Provided",
        email: "placeholder@email.com",
      },
      customerUserContacts: [],
      asCompany: undefined,
      documentRequests: generateDocumentRequestByEntityType("guarantor.individual", loan),
      reminders: [],
    },
  ];
};
