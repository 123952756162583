import { MAX_TAB_CHARACTERS } from "src/utils/constants";
import { Form1040 } from "src/interfaces/TaxFormData/Form1040";
import { Form1065 } from "src/interfaces/TaxFormData/Form1065";
import { Form1120S } from "src/interfaces/TaxFormData/Form1120s";
import { Form1120 } from "src/interfaces/TaxFormData/Form1120";
import { TaxFormData, TaxFormYear } from "src/interfaces/TaxFormData/TaxFormData";
import { RenderedWorkflow } from "src/classes/RenderedDocuments/RenderedWorkflow";
import type { ColDef } from "ag-grid-community";
import { GridState } from "src/classes/GridState";
import { ExtractableDocumentType } from "src/Enums/ExtractableDocumentType";
import { PersonalCashFlowData } from "src/interfaces/FinancialFormData/PersonalCashFlow";
import { Form1120sIncomeStatementGrouped } from "src/classes/RenderedDocuments/Form1120s/Form1120sIncomeStatementGrouped";
import { ScheduleK1 } from "src/interfaces/TaxFormData/schedules/ScheduleK1";
import { ScheduleK1Rendered } from "src/classes/RenderedDocuments/ScheduleK1Rendered";
import { TabName } from "src/redux/reducers/types";
import { Corporation, Person } from "src/interfaces/TaxFormData/common";
import { HFHeaderComponent } from "src/components/spreads/HyperFormulaHeaderComponent";
import { HFCellEditor } from "src/components/spreads/HyperFormulaCellEditor";
import { HFCellRenderer } from "src/components/spreads/HyperFormulaCellRenderer";
import { ScheduleCBreakoutRendered } from "src/classes/RenderedDocuments/ScheduleCBreakoutRendered";
import { ScheduleEBreakoutRendered } from "src/classes/RenderedDocuments/ScheduleEBreakoutRendered";
import { capitalizeFirstLetterOfEachWord } from "src/utils/helpers";
import { Form1120sBalanceSheetGrouped } from "src/classes/RenderedDocuments/Form1120s/Form1120sBalanceSheetGrouped";
import { Form1120sCashFlowGrouped } from "src/classes/RenderedDocuments/Form1120s/Form1120sCashFlowGrouped";
import { Form1120sRatiosGrouped } from "../Form1120s/Form1120sRatiosGrouped";
import { Form1065BalanceSheetGrouped } from "src/classes/RenderedDocuments/Form1065/Form1065BalanceSheetGrouped";
import { Form1065CashFlowGrouped } from "src/classes/RenderedDocuments/Form1065/Form1065CashFlowGrouped";
import { Form1065IncomeStatementGrouped } from "src/classes/RenderedDocuments/Form1065/Form1065IncomeStatementGrouped";
import { Form1065RatiosGrouped } from "../Form1065/Form1065RatiosGrouped";
import { HoverInfo } from "src/classes/RenderedDoc";
import { Form8825 } from "src/interfaces/TaxFormData/attachments/Form8825";
import { Form8825BreakoutRendered } from "src/classes/RenderedDocuments/Form8825BreakoutRendered";
import { Form1040Grouped } from "src/classes/RenderedDocuments/Form1040/Form1040Grouped";
import {
  buildGlobalCashFlow,
  Form1040RollUpAndTabName,
  Form1065CashFlowAndTabName,
  Form1120sCashFlowAndTabName,
} from "src/classes/RenderedDocuments/GlobalCashFlow/GlobalCashFlow";

function sanitizeName(name: string): string {
  return name.slice(0, Math.min(MAX_TAB_CHARACTERS, name.length));
}

export type GeneralSpreadsData = {
  [key: string]: {
    output: TaxFormData;
    source: ExtractableDocumentType;
  };
};

export type TabGroupName = "1040" | "1065" | "1120S" | "1120" | "K1" | "Legacy" | "Global";
export type TabGroup = {
  tabName: TabName;
  group: TabGroupName;
  tabType: TabType;
};

type TabType =
  | "PCF for Year"
  | "K-1s for Year"
  | "PCF All Years"
  | "Schedule C Breakout"
  | "Schedule E Breakout"
  | "Business IS All Years"
  | "Business BS All Years"
  | "Business Cash Flow All Years"
  | "Business Ratios All Years"
  | "Form 8825 Breakout"
  | "Global Cash Flow";

export class RenderedGeneralSpreads extends RenderedWorkflow {
  colDefs: Map<TabName, ColDef<any, any>[]> = new Map();
  gridStates: Map<TabName, GridState> = new Map();
  tabGroups: TabGroup[] = [];
  hoverInfoData: Map<TabName, HoverInfo[][]> = new Map();

  form1040s: Form1040[] = [];
  form1065s: Form1065[] = [];
  form1120Ss: Form1120S[] = [];
  form1120: Form1120[] = [];
  formk1: ScheduleK1[] = [];
  legacyPersonalData: PersonalCashFlowData[] = [];

  get tabs(): TabName[] {
    return Array.from(this.gridStates.keys());
  }

  constructor(public generalSpreads: GeneralSpreadsData) {
    super();
    console.log("generalSpreads", generalSpreads);
    Object.values(generalSpreads).forEach((extract) =>
      this.placeForm(extract.output, extract.source),
    );
    const form1040Rollups = this.process1040();
    const form1065CashFlows = this.process1065();
    const form1120sCashFlows = this.process1120s();
    this.processGlobalCashFlow(form1040Rollups, form1065CashFlows, form1120sCashFlows);
    this.processScheduleK1();
    this.process8825();
  }

  private placeForm(taxFormData: TaxFormData, extractorType: ExtractableDocumentType) {
    if (extractorType === ExtractableDocumentType.SCHEDULE_K1 && Array.isArray(taxFormData)) {
      const k1s = taxFormData;
      this.formk1.push(...k1s);
    }

    switch (taxFormData.form) {
      case "1040":
        const form1040 = taxFormData as Form1040;
        this.form1040s.push(form1040);
        if (form1040.relatedK1s) {
          this.formk1.push(...form1040.relatedK1s);
        }
        return;
      case "1065":
        const form1065 = taxFormData as Form1065;
        this.form1065s.push(form1065);
        if (form1065.schedules?.scheduleK1) {
          this.formk1.push(...form1065.schedules.scheduleK1);
        }
        return;
      case "1120S":
        const form1120s = taxFormData as Form1120S;
        this.form1120Ss.push(form1120s);
        if (form1120s.schedules?.scheduleK1) {
          this.formk1.push(...form1120s.schedules.scheduleK1);
        }
        return;
      case "1120":
        this.form1120.push(taxFormData as Form1120);
        return;
    }
  }

  private addTab(
    tabName: TabName,
    gridState: GridState,
    colDefs: ColDef[],
    hoverInfos: HoverInfo[][],
    group: TabGroupName,
    tabType: TabType,
  ) {
    this.gridStates.set(tabName, gridState);
    this.colDefs.set(tabName, colDefs);
    this.tabGroups.push({ tabName, group, tabType });
    this.hoverInfoData.set(tabName, hoverInfos);
  }

  private process1040() {
    const taxpayerForms: Map<string, Form1040[]> = new Map();
    this.form1040s.forEach((form1040) => {
      const name = normalizeTaxpayerName(form1040.taxpayer, form1040.entityName);
      const forms = taxpayerForms.get(name) || [];
      taxpayerForms.set(name, [...forms, form1040]);
    });

    const form1040Rollups: Form1040RollUpAndTabName[] = [];
    taxpayerForms.forEach((forms, taxpayer) => {
      const form1040Rollup = this.create1040Rollup(forms, taxpayer);
      this.createScheduleCBreakout(forms, taxpayer);
      this.createScheduleEBreakout(forms, taxpayer);
      form1040Rollups.push(form1040Rollup);
    });
    return form1040Rollups;
  }

  private createScheduleCBreakout(forms: Form1040[], taxpayer: string) {
    forms.forEach((form) => {
      const scheduleCs = form.schedules?.scheduleC;
      if (!scheduleCs?.length) {
        return;
      }
      const scheduleCBreakout = new ScheduleCBreakoutRendered(scheduleCs);
      const tabName = this.formatTabName("Schedule C Breakout", taxpayer, form.year);
      this.addTab(
        tabName,
        scheduleCBreakout.initialGridState,
        scheduleCBreakout.columnDefs,
        scheduleCBreakout.hoverInfos,
        "1040",
        "Schedule C Breakout",
      );
    });
  }

  private createScheduleEBreakout(forms: Form1040[], taxpayer: string) {
    forms.forEach((form) => {
      if (
        form.schedules?.scheduleE?.properties === undefined ||
        form.schedules?.scheduleE.properties.length === 0
      ) {
        return;
      }
      const scheduleEBreakout = new ScheduleEBreakoutRendered(form.schedules?.scheduleE);
      const tabName = this.formatTabName("Schedule E Breakout", taxpayer, form.year);
      this.addTab(
        tabName,
        scheduleEBreakout.initialGridState,
        scheduleEBreakout.columnDefs,
        scheduleEBreakout.hoverInfos,
        "1040",
        "Schedule E Breakout",
      );
    });
  }

  private k1TotalFor1040(form: Form1040) {
    // total schedule k1 by year
    const scheduleK1Totals: Map<TaxFormYear, number> = new Map();
    this.formk1.forEach((k1) => {
      const formTaxpayer = normalizeTaxpayerName(form.taxpayer, "");
      const k1Taxpayer = normalizeTaxpayerName(k1.taxpayer, k1.entityName);
      if (k1Taxpayer.toLowerCase() === formTaxpayer.toLowerCase()) {
        const total = scheduleK1Totals.get(k1.year) || 0;
        scheduleK1Totals.set(k1.year, total + k1.distributions - k1.contributions);
      }
    });

    return scheduleK1Totals.get(form.year) || 0;
  }

  private create1040Rollup(forms: Form1040[], taxpayer: string) {
    const formsWithBreakoutTabNames = forms
      .sort((a, b) => parseInt(a.year) - parseInt(b.year))
      .map((form) => {
        const scheduleETabName = form.schedules?.scheduleE
          ? this.formatTabName("Schedule E Breakout", taxpayer, form.year)
          : null;
        const scheduleCTabName = form.schedules?.scheduleC?.length
          ? this.formatTabName("Schedule C Breakout", taxpayer, form.year)
          : null;
        const k1DistributionTotal = this.k1TotalFor1040(form);

        return {
          form,
          k1DistributionTotal,
          scheduleETabName,
          scheduleCTabName,
        };
      });

    const form1040Rollup = new Form1040Grouped(formsWithBreakoutTabNames);

    const tabName = this.formatTabName("PCF All Years", taxpayer);
    this.addTab(
      tabName,
      form1040Rollup.gridState,
      form1040Rollup.columnDefs,
      form1040Rollup.hoverInfos,
      "1040",
      "PCF All Years",
    );
    return {
      tabName,
      cashFlow: form1040Rollup,
      entityName: taxpayer,
    };
  }

  private groupFormsByNormalizedName<TForm>(
    forms: TForm[],
    getName: (form: TForm) => Corporation | undefined,
  ): Map<string, TForm[]> {
    const groupedForms: Map<string, TForm[]> = new Map();
    forms.forEach((form) => {
      let name = normalizeCorporationName(getName(form));
      name = name
        .replace(/[^a-zA-Z0-9\s]/g, "") // Remove non-alphanumeric characters
        .replace(/\b(LLC|INC|CORP|LTD|CO|COMPANY)\b/gi, "") // Remove common company designators
        .trim();
      const existingForms = groupedForms.get(name) || [];
      groupedForms.set(name, [...existingForms, form]);
    });
    return groupedForms;
  }

  private process1120s() {
    const sCorpForms = this.groupFormsByNormalizedName(this.form1120Ss, (form) => form.corporation);
    const form1120sCashFlows: Form1120sCashFlowAndTabName[] = [];
    sCorpForms.forEach((forms) => {
      const firstForm = forms[0];
      let corporationName = normalizeCorporationName(firstForm.corporation);

      const incomeStatement = this.create1120sIncomeStatement(forms, corporationName);
      const balanceSheet = this.create1120sBalanceSheet(forms, corporationName);
      const cashflow = this.create1120sCashFlow(forms, corporationName);

      const ratiosRendered = new Form1120sRatiosGrouped(
        forms,
        this.formatTabName("Business BS All Years", corporationName),
        this.formatTabName("Business IS All Years", corporationName),
        this.formatTabName("Business Cash Flow All Years", corporationName),
        balanceSheet,
        incomeStatement,
        cashflow.cashFlow,
      );
      const tabName = this.formatTabName("Business Ratios All Years", corporationName);
      this.addTab(
        tabName,
        ratiosRendered.initialGridState,
        ratiosRendered.columnDefs,
        ratiosRendered.hoverInfos,
        "1120S",
        "Business Ratios All Years",
      );
      form1120sCashFlows.push(cashflow);
    });
    return form1120sCashFlows;
  }

  private process1065() {
    const partnershipForms = this.groupFormsByNormalizedName(
      this.form1065s,
      (form) => form.partnership,
    );
    const form1065CashFlows: Form1065CashFlowAndTabName[] = [];
    partnershipForms.forEach((forms) => {
      const firstForm = forms[0];
      let corporationName = normalizeCorporationName(firstForm.partnership);

      const incomeStatement = this.create1065IncomeStatement(forms, corporationName);
      const balanceSheet = this.create1065BalanceSheet(forms, corporationName);
      const cashFlow = this.create1065CashFlow(forms, corporationName);
      form1065CashFlows.push(cashFlow);

      const ratiosRendered = new Form1065RatiosGrouped(
        forms,
        this.formatTabName("Business BS All Years", corporationName),
        this.formatTabName("Business IS All Years", corporationName),
        this.formatTabName("Business Cash Flow All Years", corporationName),
        balanceSheet,
        incomeStatement,
        cashFlow.cashFlow,
      );
      const tabName = this.formatTabName("Business Ratios All Years", corporationName);
      this.addTab(
        tabName,
        ratiosRendered.initialGridState,
        ratiosRendered.columnDefs,
        ratiosRendered.hoverInfos,
        "1065",
        "Business Ratios All Years",
      );
    });
    return form1065CashFlows;
  }

  processGlobalCashFlow(
    form1040Rollups: Form1040RollUpAndTabName[],
    form1065CashFlows: Form1065CashFlowAndTabName[],
    form1120sCashFlows: Form1120sCashFlowAndTabName[],
  ) {
    const globalCashFlow = buildGlobalCashFlow(
      form1120sCashFlows,
      form1065CashFlows,
      form1040Rollups,
    );
    const tabName = "Global Cash Flow" as TabName;
    this.addTab(
      tabName,
      globalCashFlow.initialGridState,
      globalCashFlow.columnDefs,
      globalCashFlow.hoverInfos,
      "Global",
      "Global Cash Flow",
    );
  }

  private create1065IncomeStatement(forms: Form1065[], corporation: string) {
    const rendered = new Form1065IncomeStatementGrouped(
      forms.sort((a, b) => parseInt(a.year) - parseInt(b.year)),
    );
    const tabName = this.formatTabName("Business IS All Years", corporation);
    this.addTab(
      tabName,
      rendered.initialGridState,
      rendered.columnDefs,
      rendered.hoverInfos,
      "1065",
      "Business IS All Years",
    );
    return rendered;
  }

  private create1065BalanceSheet(forms: Form1065[], corporation: string) {
    const rendered = new Form1065BalanceSheetGrouped(
      forms.sort((a, b) => parseInt(a.year) - parseInt(b.year)),
    );
    const tabName = this.formatTabName("Business BS All Years", corporation);
    this.addTab(
      tabName,
      rendered.initialGridState,
      rendered.columnDefs,
      rendered.hoverInfos,
      "1065",
      "Business BS All Years",
    );
    return rendered;
  }

  private create1065CashFlow(forms: Form1065[], corporation: string) {
    const rendered = new Form1065CashFlowGrouped(
      forms.sort((a, b) => parseInt(a.year) - parseInt(b.year)),
    );
    const tabName = this.formatTabName("Business Cash Flow All Years", corporation);
    this.addTab(
      tabName,
      rendered.initialGridState,
      rendered.columnDefs,
      rendered.hoverInfos,
      "1065",
      "Business Cash Flow All Years",
    );
    return {
      tabName,
      cashFlow: rendered,
      entityName: corporation,
    };
  }

  private create1120sIncomeStatement(forms: Form1120S[], corporation: string) {
    const rendered = new Form1120sIncomeStatementGrouped(
      forms.sort((a, b) => parseInt(a.year) - parseInt(b.year)),
    );
    const tabName = this.formatTabName("Business IS All Years", corporation);
    this.addTab(
      tabName,
      rendered.initialGridState,
      rendered.columnDefs,
      rendered.hoverInfos,
      "1120S",
      "Business IS All Years",
    );
    return rendered;
  }

  private create1120sBalanceSheet(
    forms: Form1120S[],
    corporation: string,
  ): Form1120sBalanceSheetGrouped {
    const rendered = new Form1120sBalanceSheetGrouped(
      forms.sort((a, b) => parseInt(a.year) - parseInt(b.year)),
    );
    const tabName = this.formatTabName("Business BS All Years", corporation);
    this.addTab(
      tabName,
      rendered.initialGridState,
      rendered.columnDefs,
      rendered.hoverInfos,
      "1120S",
      "Business BS All Years",
    );
    return rendered;
  }

  private create1120sCashFlow(forms: Form1120S[], corporation: string) {
    const rendered = new Form1120sCashFlowGrouped(
      forms.sort((a, b) => parseInt(a.year) - parseInt(b.year)),
    );
    const tabName = this.formatTabName("Business Cash Flow All Years", corporation);
    this.addTab(
      tabName,
      rendered.initialGridState,
      rendered.columnDefs,
      rendered.hoverInfos,
      "1120S",
      "Business Cash Flow All Years",
    );
    return {
      tabName,
      cashFlow: rendered,
      entityName: corporation,
    };
  }

  private process8825() {
    this.form1065s.forEach((form, index) => {
      if (form.form8825) {
        const name = form?.partnership?.name ?? `Partnership ${index + 1}`;
        const rendered = new Form8825BreakoutRendered(form.form8825);
        const tabName = this.formatTabName("Form 8825 Breakout", name, form.form8825.year);
        this.addTab(
          tabName,
          rendered.initialGridState,
          rendered.columnDefs,
          rendered.hoverInfos,
          "1065",
          "Form 8825 Breakout",
        );
      }
    });
    this.form1120Ss.forEach((form, index) => {
      if (form.form8825) {
        const name = form?.corporation?.name ?? `Corporation ${index + 1}`;
        const rendered = new Form8825BreakoutRendered(form.form8825);
        const tabName = this.formatTabName("Form 8825 Breakout", name, form.form8825.year);
        this.addTab(
          tabName,
          rendered.initialGridState,
          rendered.columnDefs,
          rendered.hoverInfos,
          "1120S",
          "Form 8825 Breakout",
        );
      }
    });
  }

  private processScheduleK1() {
    const taxpayerYears: String[] = [];
    this.formk1.forEach((form) => {
      if (!taxpayerYears.includes(form.year)) {
        const taxpayer = normalizeTaxpayerName(form.taxpayer, form.entityName);
        taxpayerYears.push(`${form.year}-${taxpayer}`);
      }
    });

    taxpayerYears.sort((a, b) => (a > b ? 1 : -1));
    for (const taxpayerYear of taxpayerYears) {
      const taxPayersK1ThisYear = this.formk1.filter((k1) => {
        const taxpayer = normalizeTaxpayerName(k1.taxpayer, k1.entityName);
        return taxpayerYear === `${k1.year}-${taxpayer}`;
      });
      const [taxformyear, entityName, ..._parts] = taxpayerYear.split("-");
      const tabName = this.formatTabName("K-1s for Year", entityName, taxformyear as TaxFormYear);
      const rendered = new ScheduleK1Rendered(taxPayersK1ThisYear, taxpayerYear as TaxFormYear);
      this.addTab(
        tabName,
        rendered.initialGridState,
        rendered.columnDefs,
        rendered.hoverInfos,
        "K1",
        "K-1s for Year",
      );
    }
  }

  public formatTabName(tabType: TabType, entityName?: string, year?: TaxFormYear): TabName {
    switch (tabType) {
      case "PCF for Year": {
        if (!year) {
          throw new Error("Year is required for PCF");
        }
        return sanitizeName(`${entityName} - ${year}`) as TabName;
      }
      case "K-1s for Year": {
        if (!year) {
          throw new Error("Year is required for K-1s");
        }
        return sanitizeName(`K-1 - ${year} - ${entityName}`) as TabName;
      }
      case "PCF All Years": {
        return sanitizeName(`${entityName}`) as TabName;
      }
      case "Schedule C Breakout": {
        return sanitizeName(`Schedule C - ${year} - ${entityName}`) as TabName;
      }
      case "Schedule E Breakout": {
        if (!year) {
          throw new Error("Year is required for Schedule E");
        }
        return sanitizeName(`Schedule E - ${year} - ${entityName}`) as TabName;
      }
      case "Business IS All Years": {
        return sanitizeName(`Bus IS - ${entityName}`) as TabName;
      }
      case "Business BS All Years": {
        return sanitizeName(`Bus BS - ${entityName}`) as TabName;
      }
      case "Business Cash Flow All Years": {
        return sanitizeName(`Bus CF - ${entityName}`) as TabName;
      }
      case "Business Ratios All Years": {
        return sanitizeName(`Bus Ratios - ${entityName}`) as TabName;
      }
      case "Form 8825 Breakout": {
        if (!year) {
          throw new Error(`Year is required for ${tabType}`);
        }
        return sanitizeName(`Form 8825 - ${year} - ${entityName}`) as TabName;
      }
      default: {
        return sanitizeName(tabType) as TabName;
      }
    }
  }
}

function columnDefs(columnHeaders: string[]): ColDef[] {
  const columnDefs = columnHeaders.map((columnHeader, columnIndex) => {
    return {
      headerName: columnHeader,
      colId: columnHeader,
      field: columnHeader,
      headerComponent: HFHeaderComponent,
      cellEditor: HFCellEditor,
      cellRenderer: HFCellRenderer,
      sortable: false,
      minWidth: columnIndex === 0 ? 350 : 150,
      flex: columnIndex === 0 ? 2 : 1,
      editable: columnIndex !== 0,
    };
  });

  return columnDefs;
}

export function normalizeTaxpayerName(input: Person | undefined, entityName: string): string {
  if (entityName || !input || !input?.firstName || !input?.lastName) {
    return entityName;
  }
  const taxpayer = input as Person;
  const onlyFirst = taxpayer.firstName?.split(" ").shift();
  const last = taxpayer.lastName?.split(" ").pop();
  return capitalizeFirstLetterOfEachWord(`${onlyFirst} ${last}`);
}

export function normalizeCorporationName(input: Corporation | undefined): string {
  if (!input || !input.name) {
    return "";
  }

  return capitalizeFirstLetterOfEachWord(input.name.trim());
}
